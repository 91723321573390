import { call, put, throttle } from "redux-saga/effects";

import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";
import { ShopApi } from "./api";

import { createSelector } from "reselect";

const initialState = fromJS({});

const GET_ORDER = "app/App/GET_ORDER";
const GET_ORDER_SUCCESS = "app/App/GET_ORDER_SUCCESS";
const GET_ORDER_ERROR = "app/App/GET_ORDER_ERROR";

const CLEAR_ORDER = "app/App/CLEAR_ORDER";


// actions
export function onGetOrder(payload: {cartId: number, userId: number}) {
    return {
        type: GET_ORDER,
        payload
    };
}

export function onGetOrderSuccess(order) {
    return {
        type: GET_ORDER_SUCCESS,
        order
    };
}

export function onGetOrderError(error) {
    return {
        type: GET_ORDER_ERROR,
        error
    };
}

export function onClearOrder() {
    return {
        type: CLEAR_ORDER,
    };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case GET_ORDER:
            return state;
        case GET_ORDER_SUCCESS:
            return action.order;
        case GET_ORDER_ERROR:
            return {...state, ...action.error};
        case CLEAR_ORDER:
            return {};
        default:
            return state;
    }
}

// SAGA
export function* saga() {
    yield throttle(2000, GET_ORDER, getOrder);
}

export function* getOrder(args: ReturnType<typeof onGetOrder>) {

    EventEmitter.dispatch("DATA_getOrders");

    try {
        const data = yield call(
            ShopApi.getOrder,
            args.payload.userId,
            args.payload.cartId
        );

        if(data && data.order){
            yield put(onGetOrderSuccess(data.order));
            EventEmitter.dispatch("DATA_SUCCESS_getOrder");
        } else {
            yield put(onGetOrderError({}));
            EventEmitter.dispatch("DATA_ERROR_getOrder");
        }
    } catch (err) {
        if(err && err.responseBody){
            yield put(onGetOrderError({error:err.responseBody.message}));
        }
        EventEmitter.dispatch("DATA_ERROR_getOrder");
    }
}

// SELECTORS
const getThis = state => state.order;
export const makeSelectOrder = createSelector(
    [getThis],
    order => {
        return JSON.parse(JSON.stringify(order));
    }
);
