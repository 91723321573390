import { pageType } from './pageTypes';
import { listProductImpression, productImpression, trackingCartItemsInterface } from './interfaces';
import { brandType } from './brandTypes';
import { environment } from "../config/environment";
import { IProductItemView } from 'src/interfaces/iProduct';

export const getPageType = () => {
  const { pathname } = window.location;

  let pageTemplate = 'page';
  if(pathname.indexOf('/categories/') > -1){
    pageTemplate = pageType.PRODUCTS_LIST;
  }
  if(pathname.indexOf('/product/') > -1){
    pageTemplate = pageType.SINGLE_PRODUCT;
  }
  if(pathname.indexOf('/subscribe/') > -1){
    pageTemplate = pageType.SUBSCRIPTION_PAGE;
  }
  if(pathname.indexOf('/checkout/') > -1){
    pageTemplate = pageType.CHECKOUT_PAGE;
  }
  if(pathname.indexOf('/home') > -1){
    pageTemplate = pageType.HOME_PAGE;
  }
  if(pathname.indexOf('/search') > -1){
    pageTemplate = pageType.SEARCH_PAGE;
  }

  return pageTemplate;
}

export const cartProductImpressions = (cartItems:trackingCartItemsInterface[]):productImpression[] => {
  const { APP_FLAVOR } = environment;

  return Array.isArray(cartItems) && cartItems.map((cartItem: trackingCartItemsInterface) => {

    const taxonCode = findTaxonAtDeepestLevel(cartItem.taxonCodes)

    return {
      name: `${cartItem.name}`,
      id: `${cartItem.id}`,
      price: `${(cartItem.price / 100).toFixed(2)}`,
      brand: brandType[APP_FLAVOR] ?? '',
      category: taxonCode.slug.replace('categories/', ''),
      item_list_id: taxonCode.code,
      variant: `${cartItem.name}`,
      quantity: cartItem.quantity
    }
  });
}

interface taxonCodeInterface {
  slug:string;
  code:string;
}

export const findTaxonAtDeepestLevel = (taxons:taxonCodeInterface[]):taxonCodeInterface => {
  let foundTaxon:taxonCodeInterface = {slug:'',code:''};
  let maxTaxonLength = 0;
  taxons.forEach((taxon:taxonCodeInterface) => {
    const taxonArray = taxon.slug.split('/');
    taxonArray.shift();
    if(taxonArray.length > maxTaxonLength){
      foundTaxon = taxon;
      maxTaxonLength = taxonArray.length;
    }
  });

  return foundTaxon;
}

export const findTaxonBasedOnPageLocation = (taxons:taxonCodeInterface[]):taxonCodeInterface => {
  const { pathname } = window.location;
  let foundTaxon:taxonCodeInterface = {slug:'',code:''};
  let maxTaxonLength = 0;

  taxons.forEach((taxon:taxonCodeInterface) => {
    const taxonArray = taxon.slug.split('/');
    taxonArray.shift();
    if(pathname.indexOf(taxon.slug) > -1){
      if(taxonArray.length > maxTaxonLength){
        foundTaxon = taxon;
        maxTaxonLength = taxonArray.length;
      }
    }
  });

  return {
    slug:foundTaxon.slug.toLocaleLowerCase(),
    code:foundTaxon.code.toLocaleLowerCase()
  };
}

export const listProductImpressions = (products:IProductItemView[], list?:string, listItemId?: string):listProductImpression[] => {
  const { APP_FLAVOR } = environment;




  return Array.isArray(products) && products.map((product: IProductItemView, index: number) => {
    const { pathname } = window.location;
    const taxonCode = pathname.indexOf('categories') > -1 ? findTaxonBasedOnPageLocation(product.taxonCodes) : findTaxonAtDeepestLevel(product.taxonCodes);

    return {
      name: `${product.name}`,
      id: `${product.id}`,
      price: `${(product.price / 100).toFixed(2)}`,
      brand: brandType[APP_FLAVOR] ?? '',
      category: taxonCode.slug.replace('categories/', ''),
      item_list_id: listItemId ? listItemId : taxonCode.code,
      variant: `${product.name}`,
      list: list ? list : getPageType(),
      position: index + 1
    }
  });
}

