import { createSelector } from "reselect";

// REDUCERS
export function reducer(state = {}, action = {}) {
    return action.type;
}


// SELECTORS
const getThisSite = state => state.updated;
export const makeSelectUpdated = createSelector(
    [getThisSite],
    updated => {
        return updated;
    }
);
