import { environment } from "src/config/environment";

const ls = require("localstorage-ttl");

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem("state");
        const dayState = ls.get("state-day");
        const hourState = ls.get("state-hour");
        const hourStateProducts = ls.get("state-hour-products");
        const minuteState = ls.get("state-minute");

        const stateObjPerm =
            serializedState !== null ? JSON.parse(serializedState) : {};
        const stateObjDay = dayState !== null ? JSON.parse(dayState) : {};
        const stateObjHour = hourState !== null ? JSON.parse(hourState) : {};
        const stateObjHourProducts = hourStateProducts !== null ? JSON.parse(hourStateProducts) : {};
        const stateObjMinute = minuteState !== null ? JSON.parse(minuteState) : {};

        const stateObj = Object.assign(
            {},
            stateObjPerm,
            stateObjDay,
            stateObjHour,
            stateObjHourProducts,
            stateObjMinute
        );
        return stateObj;
        //const allRules = Object.assign({}, obj1, obj2, obj3, etc);
    } catch (err) {
        console.error(err);
        return undefined;
    }
};

export const saveState = state => {
    /*
      add only data that we want to save in local storage
      exclude data that we do not want cached... like FEED
      because we do not want to cache everything
      we could even do a short term and long term storage implementation...
      */

    // until LOGOUT ==================
    let save_state = {
        user: state.user,
        cart: state.cart, /////
        site: state.site,
        search: state.search,
        addresses: state.addresses,
        channels: state.channels,
        countries: state.countries,
        subscriptions: state.subscriptions,
        subscription: state.subscription,
        deliveryArea: state.deliveryArea
        //content: state.content
    };

    if (state.user && state.user.email && state.user.email.length > 0) {
        //
    } else {
        save_state.cart = state.cart;
    }

    try {
        const serializedState = JSON.stringify(save_state);
        localStorage.setItem("state", serializedState);
        sessionStorage.setItem("state", serializedState);
    } catch (err) {
        console.error(err);
    }

    // for a DAY =====================
    const day_state = {
        product: state.product,
        payments: state.payments,
        shipments: state.shipments,
        locale: state.locale,
        orders: state.orders,
        creditCards: state.creditCards,
        order: state.order
    };

    try {
        const serializedState = JSON.stringify(day_state);
        ls.set("state-day", serializedState, 1000 * 60 * 60 * 24);
    } catch (err) {
        console.error(err);
    }

    // for an HOUR =====================
    const hour_state = {
        auth: state.auth,
        //products: state.products,
        collections: state.collections
    };

    try {
        const serializedState = JSON.stringify(hour_state);
        ls.set("state-hour", serializedState, 1000 * 60 * 60);
        sessionStorage.setItem("state-hour", serializedState);
    } catch (err) {
        console.error(err);
    }

    // for an HOUR PRODUCTS =====================
    const hour_state_products = {
        //products: state.products,
    };
    if (
        (window.location.href).indexOf("wine-club/campaign") > -1 ||
        (window.location.href).indexOf("subscriptions/babylonstoren-seasons/signup") > -1 ||
        (window.location.href).indexOf("checkout/") > -1
    ) {
        /*
        only save products if not on payment page, else clear "products"
        */
        localStorage.removeItem("state-hour-products");
    } else {
        try {
            const serializedState = JSON.stringify(hour_state_products);
            ls.set("state-hour-products", serializedState, 1000 * 60 * 60);
            sessionStorage.setItem("state-hour-products", serializedState);
        } catch (err) {
            console.error(err);
        }
    }

    // for 60 seconds =====================
    const minute_state = {
        payment: state.payment
    };

    try {
        const serializedState = JSON.stringify(minute_state);
        ls.set("state-minute", serializedState, 1000 * 60);
        sessionStorage.setItem("state-minute", serializedState);
    } catch (err) {
        console.error(err);
    }
};

export const lsx = {
    get: (key) => {
        const storage = ls.get("storage") ? ls.get("storage") : JSON.parse(sessionStorage.getItem("storage"));

        if (storage && storage[`${key}`] && storage[`${key}`].value) {

            if (storage[`${key}`].timeout === 0) {
                return storage[`${key}`].value;
            } else if (storage[`${key}`].timeout > Date.now()) {
                return storage[`${key}`].value;
            } else {
                return null;
            }

        } else {
            return null;
        }

        //return storage && storage[`${key}`] && storage[`${key}`].value ? storage[`${key}`].value : null;
        //return ls.get(key);
    },
    set: (key, value, timeout = 0) => {
        let storage = ls.get("storage");
        const time = timeout > 0 ? Date.now() + timeout : 0;

        if (storage === null) {
            storage = {};
        }
        storage[`${key}`] = { value, timeout: time };
        ls.set("storage", storage);
        sessionStorage.setItem("storage", JSON.stringify(storage));
        //ls.set(key, value, timeout);
    }
}

function refreshStorageForUpdatedVersion(){

    const last = localStorage.getItem('build-version');
    const current = environment.BUILD_VERSION;

    console.info(`Current version: ${current}, Previous version: ${last}`);

    if(current && last !== current){
        console.log('Clear storage for updated version');
        if(window.localStorage) window.localStorage.clear();
        if(window.sessionStorage) window.sessionStorage.clear();
    }

    localStorage.setItem('build-version', environment.BUILD_VERSION);

}

refreshStorageForUpdatedVersion();
