import { fork } from "redux-saga/effects";

import { saga as authSaga } from "./Shop/Auth";
import { saga as siteSaga } from "./Shop/Site";
import { saga as collectionsSaga } from "./Shop/Collections";
import { saga as userSaga } from "./Shop/User";
import { saga as cartSaga } from "./Shop/Cart";
import { saga as productsSaga } from "./Shop/Products";
import { saga as allProductsSaga } from "./Shop/AllProducts";
import { saga as productSaga } from "./Shop/Product";
import { saga as credtCardsSaga } from "./Shop/CreditCards";
import { saga as paymentSaga } from "./Shop/Payment";
import { saga as shipmentsSaga } from "./Shop/Shipments";
import { saga as paymentsSaga } from "./Shop/Payments";
import { saga as addressesSaga } from "./Shop/Addresses";
import { saga as allAddressesSaga } from "./Shop/AllAddresses";
import { saga as ordersSaga } from "./Shop/Orders";
import { saga as payuStatusSaga } from "./Shop/PayuStatus";
import { saga as paypalStatusSaga } from "./Shop/PayPalStatus";
import { saga as checkoutSaga } from "./Shop/Checkout";
import { saga as subscriptionSaga } from "./Shop/Subscription";
import { saga as subscriptionsSaga } from "./Shop/Subscriptions";
import { saga as orderSaga } from "./Shop/Order";
import { saga as eggCardSaga } from "./Shop/EggCard";
import { saga as hubspotSaga } from "./Shop/Hubspot";
import { saga as searchSaga } from "./Shop/Search";
import { saga as peachPaymentSaga } from "./Shop/PeachPayment";
import { saga as stripeSaga } from "./Shop/Stripe";
import { saga as contentSaga } from "./Shop/Content";
import { saga as candideMemberSaga } from "./Shop/CandideMember";
import { saga as deliveryAreaSaga } from "./Shop/DeliveryArea";
import { saga as productSearchSaga } from "./Shop/ProductSearch";
import { saga as routeSaga } from "./Shop/Route";

export default function* rootSaga() {
  yield fork(authSaga);
  yield fork(paymentsSaga);
  //yield fork(channelsSaga);
  yield fork(siteSaga);
  //yield fork(countriesSaga);
  yield fork(collectionsSaga);
  yield fork(userSaga);
  yield fork(cartSaga);
  yield fork(productsSaga);
  yield fork(allProductsSaga);
  yield fork(productSaga);
  yield fork(credtCardsSaga);
  yield fork(paymentSaga);
  yield fork(shipmentsSaga);
  yield fork(addressesSaga);
  yield fork(allAddressesSaga);
  yield fork(ordersSaga);
  //yield fork(localeSaga);
  yield fork(payuStatusSaga);
  yield fork(paypalStatusSaga);
  yield fork(checkoutSaga);
  yield fork(subscriptionSaga);
  yield fork(subscriptionsSaga);
  yield fork(orderSaga);
  yield fork(eggCardSaga);
  yield fork(hubspotSaga);
  yield fork(searchSaga);
  yield fork(peachPaymentSaga);
  yield fork(stripeSaga);
  yield fork(contentSaga);
  yield fork(candideMemberSaga);
  yield fork(deliveryAreaSaga);
  yield fork(productSearchSaga);
  yield fork(routeSaga);
}
