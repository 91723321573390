/* eslint-disable */

export const stripTrailingSlash = (str) => {
  if(str.substr(-1) === '/') {
      return str.substr(0, str.length - 1);
  }
  return str;
}

export const validateUsPostalCode = (code) => {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(code);
}

export const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const removeDuplicates = (myArr, prop) => {

  return myArr.filter((obj, pos, arr) => {
    return arr.map(mapObj => {
      if (mapObj && mapObj[prop] && mapObj[prop] !== null) {
        return mapObj[prop]
      } else {
        return null;
      }
    }).filter(item => item).indexOf(obj[prop]) === pos;
  });

};

export const readCookie = (name) => {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

export const getUnique = (arr, comp) => {

  const unique = arr
    .map(e => e[comp])

    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)

    // eliminate the dead keys & store unique objects
    .filter(e => arr[e]).map(e => arr[e]);

  return unique;
}

export const mergeById = (old_array, new_array) => {
  if (!Array.isArray(new_array)) return [...old_array];
  if (!new_array.length) {
    return [...old_array];
  }

  new_array = new_array.filter(item => item !== undefined); //removes empty items

  let return_array = [...old_array, ...new_array];
  // update data in old array
  if (old_array && Array.isArray(old_array) && old_array.length > 0) {
    const updated_old_array = old_array.map(item => {
      const found_item_array = new_array.filter(found => found && found.id && item && item.id && found.id === item.id);
      return found_item_array && found_item_array.length > 0
        ? found_item_array[0]
        : item;
    });
    return_array = [...updated_old_array, ...new_array];
  }

  return removeDuplicates(return_array, "id");
};


export const findInArray = (myArr, str, prop) => {
  return myArr.find(
    o => (o[prop].toUpperCase().search(str.toUpperCase()) > -1 ? true : false)
  );
};

export const findIdInArray = (myArr, id) => {
  return myArr.find(o => (o.id && o.id == id ? true : false));
};

export const updateById = (old_array, new_item) => {
  if (Array.isArray(old_array)) {
    let found = false;
    let items = old_array.map(item => {
      if (item && item.id && new_item && new_item.id && item.id == new_item.id) {
        found = true;
        return new_item;
      } else {
        return item;
      }
    });

    if (found === false) {
      items.unshift(new_item);
    }

    return items;
  } else {
    return [new_item];
  }
};

export const removeById = (old_array, new_item) => {
  if (Array.isArray(old_array)) {
    let found = false;

    let items = old_array.filter(item => {
      return item && item.id && new_item && new_item.id && item.id !== new_item.id;
    });

    return items;
  } else {
    return [];
  }
};

export const reduceToIds = array_to_reduce => {
  return array_to_reduce.map(item => {
    return { id: item && item.id ? item.id : 0 };
  });
};

export const reduceToElements = (array_to_reduce, props) => {
  return array_to_reduce.map(item => {
    let new_item = {};
    props.map(pr => {
      new_item[pr] = item[pr];
    });
    return new_item;
  });
};
/* eslint-enable */
export const updateObj = (obj, src) => {
  let nObj = JSON.parse(JSON.stringify(obj));
  Object.keys(src).forEach(function (key) {
    nObj[key] = src[key];
  });
  return nObj;
};

export const MapToObj = map => {
  const obj = Array.from(map).reduce(
    (obj, [key, value]) =>
      Object.assign(obj, {
        [key]: (value => JSON.parse(JSON.stringify(value)))(value)
      }),
    {}
  );
  return obj;
};

export const map_to_object = map => {
  const out = Object.create(null);
  map.forEach((value, key) => {
    if (value) {
      out[key] = map_to_object(value);
    } else {
      // out[key] = map_to_object_1(value);
      out[key] = value;
    }
  });
  return out;
};

const easeInOutQuad = (t, b, c, d) => {
  if ((t /= d / 2) < 1) return (c / 2) * t * t + b;

  return (-c / 2) * (--t * (t - 2) - 1) + b;
};

// requestAnimationFrame for Smart Animating http://goo.gl/sx5sts
const requestAnimFrame = (() =>
  window.requestAnimationFrame ||
  window.webkitRequestAnimationFrame ||
  window.mozRequestAnimationFrame ||
  (callback => window.setTimeout(callback, 1000 / 60)))();

export const scrollTo = (to, duration = 0, callback = null) => {
  const move = amount => {
    document.documentElement.scrollTop = amount;
    document.body.parentNode.scrollTop = amount;
    document.body.scrollTop = amount;
  };
  const start =
    document.documentElement.scrollTop ||
    document.body.parentNode.scrollTop ||
    document.body.scrollTop;
  const change = to - start;
  const increment = 20;
  let currentTime = 0;

  const animateScroll = () => {
    currentTime += increment;
    const val = easeInOutQuad(currentTime, start, change, duration);
    move(val);
    if (currentTime < duration) {
      requestAnimFrame(animateScroll);
    } else if (callback && typeof callback === "function") {
      callback();
    }
  };
  animateScroll();
};

export const scrollToElement = (toSelector, duration = 0) => {
  const element = document.querySelector(toSelector);
  if (!element) return;

  scrollTo(element.getBoundingClientRect().top, duration);
};

export const uuid = () => {
  let d = new Date().getTime();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); // use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

export const nl2br = (str) => {
  return str.replace(new RegExp('\r?\n', 'g'), '<br />');
};

export const countries = () => {
  const countrylist = [
    {
      name: "Afghanistan",
      code: "AF",
      region: "Asia",
      numericCode: "004",
      currencyCode: "AFN",
      callingCodes: "93"
    },
    {
      name: "Åland Islands",
      code: "AX",
      region: "Europe",
      numericCode: "248",
      currencyCode: "EUR",
      callingCodes: "358"
    },
    {
      name: "Albania",
      code: "AL",
      region: "Europe",
      numericCode: "008",
      currencyCode: "ALL",
      callingCodes: "355"
    },
    {
      name: "Algeria",
      code: "DZ",
      region: "Africa",
      numericCode: "012",
      currencyCode: "DZD",
      callingCodes: "213"
    },
    {
      name: "American Samoa",
      code: "AS",
      region: "Oceania",
      numericCode: "016",
      currencyCode: "USD",
      callingCodes: "1684"
    },
    {
      name: "Andorra",
      code: "AD",
      region: "Europe",
      numericCode: "020",
      currencyCode: "EUR",
      callingCodes: "376"
    },
    {
      name: "Angola",
      code: "AO",
      region: "Africa",
      numericCode: "024",
      currencyCode: "AOA",
      callingCodes: "244"
    },
    {
      name: "Anguilla",
      code: "AI",
      region: "Americas",
      numericCode: "660",
      currencyCode: "XCD",
      callingCodes: "1264"
    },
    {
      name: "Antarctica",
      code: "AQ",
      region: "Polar",
      numericCode: "010",
      currencyCode: "AUD",
      callingCodes: "672"
    },
    {
      name: "Antigua and Barbuda",
      code: "AG",
      region: "Americas",
      numericCode: "028",
      currencyCode: "XCD",
      callingCodes: "1268"
    },
    {
      name: "Argentina",
      code: "AR",
      region: "Americas",
      numericCode: "032",
      currencyCode: "ARS",
      callingCodes: "54"
    },
    {
      name: "Armenia",
      code: "AM",
      region: "Asia",
      numericCode: "051",
      currencyCode: "AMD",
      callingCodes: "374"
    },
    {
      name: "Aruba",
      code: "AW",
      region: "Americas",
      numericCode: "533",
      currencyCode: "AWG",
      callingCodes: "297"
    },
    {
      name: "Australia",
      code: "AU",
      region: "Oceania",
      numericCode: "036",
      currencyCode: "AUD",
      callingCodes: "61"
    },
    {
      name: "Austria",
      code: "AT",
      region: "EU",
      numericCode: "040",
      currencyCode: "EUR",
      callingCodes: "43"
    },
    {
      name: "Azerbaijan",
      code: "AZ",
      region: "Asia",
      numericCode: "031",
      currencyCode: "AZN",
      callingCodes: "994"
    },
    {
      name: "Bahamas",
      code: "BS",
      region: "Americas",
      numericCode: "044",
      currencyCode: "BSD",
      callingCodes: "1242"
    },
    {
      name: "Bahrain",
      code: "BH",
      region: "Asia",
      numericCode: "048",
      currencyCode: "BHD",
      callingCodes: "973"
    },
    {
      name: "Bangladesh",
      code: "BD",
      region: "Asia",
      numericCode: "050",
      currencyCode: "BDT",
      callingCodes: "880"
    },
    {
      name: "Barbados",
      code: "BB",
      region: "Americas",
      numericCode: "052",
      currencyCode: "BBD",
      callingCodes: "1246"
    },
    {
      name: "Belarus",
      code: "BY",
      region: "Europe",
      numericCode: "112",
      currencyCode: "BYN",
      callingCodes: "375"
    },
    {
      name: "Belgium",
      code: "BE",
      region: "EU",
      numericCode: "056",
      currencyCode: "EUR",
      callingCodes: "32"
    },
    {
      name: "Belize",
      code: "BZ",
      region: "Americas",
      numericCode: "084",
      currencyCode: "BZD",
      callingCodes: "501"
    },
    {
      name: "Benin",
      code: "BJ",
      region: "Africa",
      numericCode: "204",
      currencyCode: "XOF",
      callingCodes: "229"
    },
    {
      name: "Bermuda",
      code: "BM",
      region: "Americas",
      numericCode: "060",
      currencyCode: "BMD",
      callingCodes: "1441"
    },
    {
      name: "Bhutan",
      code: "BT",
      region: "Asia",
      numericCode: "064",
      currencyCode: "BTN",
      callingCodes: "975"
    },
    {
      name: "Bolivia (Plurinational State of)",
      code: "BO",
      region: "Americas",
      numericCode: "068",
      currencyCode: "BOB",
      callingCodes: "591"
    },
    {
      name: "Bonaire, Sint Eustatius and Saba",
      code: "BQ",
      region: "Americas",
      numericCode: "535",
      currencyCode: "USD",
      callingCodes: "5997"
    },
    {
      name: "Bosnia and Herzegovina",
      code: "BA",
      region: "Europe",
      numericCode: "070",
      currencyCode: "BAM",
      callingCodes: "387"
    },
    {
      name: "Botswana",
      code: "BW",
      region: "Africa",
      numericCode: "072",
      currencyCode: "BWP",
      callingCodes: "267"
    },
    {
      name: "Bouvet Island",
      code: "BV",
      region: "",
      numericCode: "074",
      currencyCode: "NOK",
      callingCodes: ""
    },
    {
      name: "Brazil",
      code: "BR",
      region: "Americas",
      numericCode: "076",
      currencyCode: "BRL",
      callingCodes: "55"
    },
    {
      name: "British Indian Ocean Territory",
      code: "IO",
      region: "Africa",
      numericCode: "086",
      currencyCode: "USD",
      callingCodes: "246"
    },
    {
      name: "United States Minor Outlying Islands",
      code: "UM",
      region: "Americas",
      numericCode: "581",
      currencyCode: "USD",
      callingCodes: ""
    },
    {
      name: "Virgin Islands (British)",
      code: "VG",
      region: "Americas",
      numericCode: "092",
      currencyCode: null,
      callingCodes: "1284"
    },
    {
      name: "Virgin Islands (U.S.)",
      code: "VI",
      region: "Americas",
      numericCode: "850",
      currencyCode: "USD",
      callingCodes: "1 340"
    },
    {
      name: "Brunei Darussalam",
      code: "BN",
      region: "Asia",
      numericCode: "096",
      currencyCode: "BND",
      callingCodes: "673"
    },
    {
      name: "Bulgaria",
      code: "BG",
      region: "Europe",
      numericCode: "100",
      currencyCode: "BGN",
      callingCodes: "359"
    },
    {
      name: "Burkina Faso",
      code: "BF",
      region: "Africa",
      numericCode: "854",
      currencyCode: "XOF",
      callingCodes: "226"
    },
    {
      name: "Burundi",
      code: "BI",
      region: "Africa",
      numericCode: "108",
      currencyCode: "BIF",
      callingCodes: "257"
    },
    {
      name: "Cambodia",
      code: "KH",
      region: "Asia",
      numericCode: "116",
      currencyCode: "KHR",
      callingCodes: "855"
    },
    {
      name: "Cameroon",
      code: "CM",
      region: "Africa",
      numericCode: "120",
      currencyCode: "XAF",
      callingCodes: "237"
    },
    {
      name: "Canada",
      code: "CA",
      region: "Americas",
      numericCode: "124",
      currencyCode: "CAD",
      callingCodes: "1"
    },
    {
      name: "Cabo Verde",
      code: "CV",
      region: "Africa",
      numericCode: "132",
      currencyCode: "CVE",
      callingCodes: "238"
    },
    {
      name: "Cayman Islands",
      code: "KY",
      region: "Americas",
      numericCode: "136",
      currencyCode: "KYD",
      callingCodes: "1345"
    },
    {
      name: "Central African Republic",
      code: "CF",
      region: "Africa",
      numericCode: "140",
      currencyCode: "XAF",
      callingCodes: "236"
    },
    {
      name: "Chad",
      code: "TD",
      region: "Africa",
      numericCode: "148",
      currencyCode: "XAF",
      callingCodes: "235"
    },
    {
      name: "Chile",
      code: "CL",
      region: "Americas",
      numericCode: "152",
      currencyCode: "CLP",
      callingCodes: "56"
    },
    {
      name: "China",
      code: "CN",
      region: "Asia",
      numericCode: "156",
      currencyCode: "CNY",
      callingCodes: "86"
    },
    {
      name: "Christmas Island",
      code: "CX",
      region: "Oceania",
      numericCode: "162",
      currencyCode: "AUD",
      callingCodes: "61"
    },
    {
      name: "Cocos (Keeling) Islands",
      code: "CC",
      region: "Oceania",
      numericCode: "166",
      currencyCode: "AUD",
      callingCodes: "61"
    },
    {
      name: "Colombia",
      code: "CO",
      region: "Americas",
      numericCode: "170",
      currencyCode: "COP",
      callingCodes: "57"
    },
    {
      name: "Comoros",
      code: "KM",
      region: "Africa",
      numericCode: "174",
      currencyCode: "KMF",
      callingCodes: "269"
    },
    {
      name: "Congo",
      code: "CG",
      region: "Africa",
      numericCode: "178",
      currencyCode: "XAF",
      callingCodes: "242"
    },
    {
      name: "Congo (Democratic Republic of the)",
      code: "CD",
      region: "Africa",
      numericCode: "180",
      currencyCode: "CDF",
      callingCodes: "243"
    },
    {
      name: "Cook Islands",
      code: "CK",
      region: "Oceania",
      numericCode: "184",
      currencyCode: "NZD",
      callingCodes: "682"
    },
    {
      name: "Costa Rica",
      code: "CR",
      region: "Americas",
      numericCode: "188",
      currencyCode: "CRC",
      callingCodes: "506"
    },
    {
      name: "Croatia",
      code: "HR",
      region: "Europe",
      numericCode: "191",
      currencyCode: "HRK",
      callingCodes: "385"
    },
    {
      name: "Cuba",
      code: "CU",
      region: "Americas",
      numericCode: "192",
      currencyCode: "CUC",
      callingCodes: "53"
    },
    {
      name: "Curaçao",
      code: "CW",
      region: "Americas",
      numericCode: "531",
      currencyCode: "ANG",
      callingCodes: "599"
    },
    {
      name: "Cyprus",
      code: "CY",
      region: "Europe",
      numericCode: "196",
      currencyCode: "EUR",
      callingCodes: "357"
    },
    {
      name: "Czech Republic",
      code: "CZ",
      region: "EU",
      numericCode: "203",
      currencyCode: "CZK",
      callingCodes: "420"
    },
    {
      name: "Denmark",
      code: "DK",
      region: "EU",
      numericCode: "208",
      currencyCode: "DKK",
      callingCodes: "45"
    },
    {
      name: "Djibouti",
      code: "DJ",
      region: "Africa",
      numericCode: "262",
      currencyCode: "DJF",
      callingCodes: "253"
    },
    {
      name: "Dominica",
      code: "DM",
      region: "Americas",
      numericCode: "212",
      currencyCode: "XCD",
      callingCodes: "1767"
    },
    {
      name: "Dominican Republic",
      code: "DO",
      region: "Americas",
      numericCode: "214",
      currencyCode: "DOP",
      callingCodes: "1809"
    },
    {
      name: "Ecuador",
      code: "EC",
      region: "Americas",
      numericCode: "218",
      currencyCode: "USD",
      callingCodes: "593"
    },
    {
      name: "Egypt",
      code: "EG",
      region: "Africa",
      numericCode: "818",
      currencyCode: "EGP",
      callingCodes: "20"
    },
    {
      name: "El Salvador",
      code: "SV",
      region: "Americas",
      numericCode: "222",
      currencyCode: "USD",
      callingCodes: "503"
    },
    {
      name: "Equatorial Guinea",
      code: "GQ",
      region: "Africa",
      numericCode: "226",
      currencyCode: "XAF",
      callingCodes: "240"
    },
    {
      name: "Eritrea",
      code: "ER",
      region: "Africa",
      numericCode: "232",
      currencyCode: "ERN",
      callingCodes: "291"
    },
    {
      name: "Estonia",
      code: "EE",
      region: "Europe",
      numericCode: "233",
      currencyCode: "EUR",
      callingCodes: "372"
    },
    {
      name: "Ethiopia",
      code: "ET",
      region: "Africa",
      numericCode: "231",
      currencyCode: "ETB",
      callingCodes: "251"
    },
    {
      name: "Falkland Islands (Malvinas)",
      code: "FK",
      region: "Americas",
      numericCode: "238",
      currencyCode: "FKP",
      callingCodes: "500"
    },
    {
      name: "Faroe Islands",
      code: "FO",
      region: "Europe",
      numericCode: "234",
      currencyCode: "DKK",
      callingCodes: "298"
    },
    {
      name: "Fiji",
      code: "FJ",
      region: "Oceania",
      numericCode: "242",
      currencyCode: "FJD",
      callingCodes: "679"
    },
    {
      name: "Finland",
      code: "FI",
      region: "EU",
      numericCode: "246",
      currencyCode: "EUR",
      callingCodes: "358"
    },
    {
      name: "France",
      code: "FR",
      region: "EU",
      numericCode: "250",
      currencyCode: "EUR",
      callingCodes: "33"
    },
    {
      name: "French Guiana",
      code: "GF",
      region: "Americas",
      numericCode: "254",
      currencyCode: "EUR",
      callingCodes: "594"
    },
    {
      name: "French Polynesia",
      code: "PF",
      region: "Oceania",
      numericCode: "258",
      currencyCode: "XPF",
      callingCodes: "689"
    },
    {
      name: "French Southern Territories",
      code: "TF",
      region: "Africa",
      numericCode: "260",
      currencyCode: "EUR",
      callingCodes: ""
    },
    {
      name: "Gabon",
      code: "GA",
      region: "Africa",
      numericCode: "266",
      currencyCode: "XAF",
      callingCodes: "241"
    },
    {
      name: "Gambia",
      code: "GM",
      region: "Africa",
      numericCode: "270",
      currencyCode: "GMD",
      callingCodes: "220"
    },
    {
      name: "Georgia",
      code: "GE",
      region: "Asia",
      numericCode: "268",
      currencyCode: "GEL",
      callingCodes: "995"
    },
    {
      name: "Germany",
      code: "DE",
      region: "EU",
      numericCode: "276",
      currencyCode: "EUR",
      callingCodes: "49"
    },
    {
      name: "Ghana",
      code: "GH",
      region: "Africa",
      numericCode: "288",
      currencyCode: "GHS",
      callingCodes: "233"
    },
    {
      name: "Gibraltar",
      code: "GI",
      region: "Europe",
      numericCode: "292",
      currencyCode: "GIP",
      callingCodes: "350"
    },
    {
      name: "Greece",
      code: "GR",
      region: "Europe",
      numericCode: "300",
      currencyCode: "EUR",
      callingCodes: "30"
    },
    {
      name: "Greenland",
      code: "GL",
      region: "Americas",
      numericCode: "304",
      currencyCode: "DKK",
      callingCodes: "299"
    },
    {
      name: "Grenada",
      code: "GD",
      region: "Americas",
      numericCode: "308",
      currencyCode: "XCD",
      callingCodes: "1473"
    },
    {
      name: "Guadeloupe",
      code: "GP",
      region: "Americas",
      numericCode: "312",
      currencyCode: "EUR",
      callingCodes: "590"
    },
    {
      name: "Guam",
      code: "GU",
      region: "Oceania",
      numericCode: "316",
      currencyCode: "USD",
      callingCodes: "1671"
    },
    {
      name: "Guatemala",
      code: "GT",
      region: "Americas",
      numericCode: "320",
      currencyCode: "GTQ",
      callingCodes: "502"
    },
    {
      name: "Guernsey",
      code: "GG",
      region: "Europe",
      numericCode: "831",
      currencyCode: "GBP",
      callingCodes: "44"
    },
    {
      name: "Guinea",
      code: "GN",
      region: "Africa",
      numericCode: "324",
      currencyCode: "GNF",
      callingCodes: "224"
    },
    {
      name: "Guinea-Bissau",
      code: "GW",
      region: "Africa",
      numericCode: "624",
      currencyCode: "XOF",
      callingCodes: "245"
    },
    {
      name: "Guyana",
      code: "GY",
      region: "Americas",
      numericCode: "328",
      currencyCode: "GYD",
      callingCodes: "592"
    },
    {
      name: "Haiti",
      code: "HT",
      region: "Americas",
      numericCode: "332",
      currencyCode: "HTG",
      callingCodes: "509"
    },
    {
      name: "Heard Island and McDonald Islands",
      code: "HM",
      region: "",
      numericCode: "334",
      currencyCode: "AUD",
      callingCodes: ""
    },
    {
      name: "Holy See",
      code: "VA",
      region: "Europe",
      numericCode: "336",
      currencyCode: "EUR",
      callingCodes: "379"
    },
    {
      name: "Honduras",
      code: "HN",
      region: "Americas",
      numericCode: "340",
      currencyCode: "HNL",
      callingCodes: "504"
    },
    {
      name: "Hong Kong",
      code: "HK",
      region: "Asia",
      numericCode: "344",
      currencyCode: "HKD",
      callingCodes: "852"
    },
    {
      name: "Hungary",
      code: "HU",
      region: "EU",
      numericCode: "348",
      currencyCode: "HUF",
      callingCodes: "36"
    },
    {
      name: "Iceland",
      code: "IS",
      region: "Europe",
      numericCode: "352",
      currencyCode: "ISK",
      callingCodes: "354"
    },
    {
      name: "India",
      code: "IN",
      region: "Asia",
      numericCode: "356",
      currencyCode: "INR",
      callingCodes: "91"
    },
    {
      name: "Indonesia",
      code: "ID",
      region: "Asia",
      numericCode: "360",
      currencyCode: "IDR",
      callingCodes: "62"
    },
    {
      name: "Côte d'Ivoire",
      code: "CI",
      region: "Africa",
      numericCode: "384",
      currencyCode: "XOF",
      callingCodes: "225"
    },
    {
      name: "Iran (Islamic Republic of)",
      code: "IR",
      region: "Asia",
      numericCode: "364",
      currencyCode: "IRR",
      callingCodes: "98"
    },
    {
      name: "Iraq",
      code: "IQ",
      region: "Asia",
      numericCode: "368",
      currencyCode: "IQD",
      callingCodes: "964"
    },
    {
      name: "Ireland",
      code: "IE",
      region: "EU",
      numericCode: "372",
      currencyCode: "EUR",
      callingCodes: "353"
    },
    {
      name: "Isle of Man",
      code: "IM",
      region: "Europe",
      numericCode: "833",
      currencyCode: "GBP",
      callingCodes: "44"
    },
    {
      name: "Israel",
      code: "IL",
      region: "Asia",
      numericCode: "376",
      currencyCode: "ILS",
      callingCodes: "972"
    },
    {
      name: "Italy",
      code: "IT",
      region: "EU",
      numericCode: "380",
      currencyCode: "EUR",
      callingCodes: "39"
    },
    {
      name: "Jamaica",
      code: "JM",
      region: "Americas",
      numericCode: "388",
      currencyCode: "JMD",
      callingCodes: "1876"
    },
    {
      name: "Japan",
      code: "JP",
      region: "Asia",
      numericCode: "392",
      currencyCode: "JPY",
      callingCodes: "81"
    },
    {
      name: "Jersey",
      code: "JE",
      region: "Europe",
      numericCode: "832",
      currencyCode: "GBP",
      callingCodes: "44"
    },
    {
      name: "Jordan",
      code: "JO",
      region: "Asia",
      numericCode: "400",
      currencyCode: "JOD",
      callingCodes: "962"
    },
    {
      name: "Kazakhstan",
      code: "KZ",
      region: "Asia",
      numericCode: "398",
      currencyCode: "KZT",
      callingCodes: "76"
    },
    {
      name: "Kenya",
      code: "KE",
      region: "Africa",
      numericCode: "404",
      currencyCode: "KES",
      callingCodes: "254"
    },
    {
      name: "Kiribati",
      code: "KI",
      region: "Oceania",
      numericCode: "296",
      currencyCode: "AUD",
      callingCodes: "686"
    },
    {
      name: "Kuwait",
      code: "KW",
      region: "Asia",
      numericCode: "414",
      currencyCode: "KWD",
      callingCodes: "965"
    },
    {
      name: "Kyrgyzstan",
      code: "KG",
      region: "Asia",
      numericCode: "417",
      currencyCode: "KGS",
      callingCodes: "996"
    },
    {
      name: "Lao People's Democratic Republic",
      code: "LA",
      region: "Asia",
      numericCode: "418",
      currencyCode: "LAK",
      callingCodes: "856"
    },
    {
      name: "Latvia",
      code: "LV",
      region: "Europe",
      numericCode: "428",
      currencyCode: "EUR",
      callingCodes: "371"
    },
    {
      name: "Lebanon",
      code: "LB",
      region: "Asia",
      numericCode: "422",
      currencyCode: "LBP",
      callingCodes: "961"
    },
    {
      name: "Lesotho",
      code: "LS",
      region: "Africa",
      numericCode: "426",
      currencyCode: "LSL",
      callingCodes: "266"
    },
    {
      name: "Liberia",
      code: "LR",
      region: "Africa",
      numericCode: "430",
      currencyCode: "LRD",
      callingCodes: "231"
    },
    {
      name: "Libya",
      code: "LY",
      region: "Africa",
      numericCode: "434",
      currencyCode: "LYD",
      callingCodes: "218"
    },
    {
      name: "Liechtenstein",
      code: "LI",
      region: "Europe",
      numericCode: "438",
      currencyCode: "CHF",
      callingCodes: "423"
    },
    {
      name: "Lithuania",
      code: "LT",
      region: "Europe",
      numericCode: "440",
      currencyCode: "EUR",
      callingCodes: "370"
    },
    {
      name: "Luxembourg",
      code: "LU",
      region: "EU",
      numericCode: "442",
      currencyCode: "EUR",
      callingCodes: "352"
    },
    {
      name: "Macao",
      code: "MO",
      region: "Asia",
      numericCode: "446",
      currencyCode: "MOP",
      callingCodes: "853"
    },
    {
      name: "Macedonia (the former Yugoslav Republic of)",
      code: "MK",
      region: "Europe",
      numericCode: "807",
      currencyCode: "MKD",
      callingCodes: "389"
    },
    {
      name: "Madagascar",
      code: "MG",
      region: "Africa",
      numericCode: "450",
      currencyCode: "MGA",
      callingCodes: "261"
    },
    {
      name: "Malawi",
      code: "MW",
      region: "Africa",
      numericCode: "454",
      currencyCode: "MWK",
      callingCodes: "265"
    },
    {
      name: "Malaysia",
      code: "MY",
      region: "Asia",
      numericCode: "458",
      currencyCode: "MYR",
      callingCodes: "60"
    },
    {
      name: "Maldives",
      code: "MV",
      region: "Asia",
      numericCode: "462",
      currencyCode: "MVR",
      callingCodes: "960"
    },
    {
      name: "Mali",
      code: "ML",
      region: "Africa",
      numericCode: "466",
      currencyCode: "XOF",
      callingCodes: "223"
    },
    {
      name: "Malta",
      code: "MT",
      region: "Europe",
      numericCode: "470",
      currencyCode: "EUR",
      callingCodes: "356"
    },
    {
      name: "Marshall Islands",
      code: "MH",
      region: "Oceania",
      numericCode: "584",
      currencyCode: "USD",
      callingCodes: "692"
    },
    {
      name: "Martinique",
      code: "MQ",
      region: "Americas",
      numericCode: "474",
      currencyCode: "EUR",
      callingCodes: "596"
    },
    {
      name: "Mauritania",
      code: "MR",
      region: "Africa",
      numericCode: "478",
      currencyCode: "MRO",
      callingCodes: "222"
    },
    {
      name: "Mauritius",
      code: "MU",
      region: "Africa",
      numericCode: "480",
      currencyCode: "MUR",
      callingCodes: "230"
    },
    {
      name: "Mayotte",
      code: "YT",
      region: "Africa",
      numericCode: "175",
      currencyCode: "EUR",
      callingCodes: "262"
    },
    {
      name: "Mexico",
      code: "MX",
      region: "Americas",
      numericCode: "484",
      currencyCode: "MXN",
      callingCodes: "52"
    },
    {
      name: "Micronesia (Federated States of)",
      code: "FM",
      region: "Oceania",
      numericCode: "583",
      currencyCode: null,
      callingCodes: "691"
    },
    {
      name: "Moldova (Republic of)",
      code: "MD",
      region: "Europe",
      numericCode: "498",
      currencyCode: "MDL",
      callingCodes: "373"
    },
    {
      name: "Monaco",
      code: "MC",
      region: "Europe",
      numericCode: "492",
      currencyCode: "EUR",
      callingCodes: "377"
    },
    {
      name: "Mongolia",
      code: "MN",
      region: "Asia",
      numericCode: "496",
      currencyCode: "MNT",
      callingCodes: "976"
    },
    {
      name: "Montenegro",
      code: "ME",
      region: "Europe",
      numericCode: "499",
      currencyCode: "EUR",
      callingCodes: "382"
    },
    {
      name: "Montserrat",
      code: "MS",
      region: "Americas",
      numericCode: "500",
      currencyCode: "XCD",
      callingCodes: "1664"
    },
    {
      name: "Morocco",
      code: "MA",
      region: "Africa",
      numericCode: "504",
      currencyCode: "MAD",
      callingCodes: "212"
    },
    {
      name: "Mozambique",
      code: "MZ",
      region: "Africa",
      numericCode: "508",
      currencyCode: "MZN",
      callingCodes: "258"
    },
    {
      name: "Myanmar",
      code: "MM",
      region: "Asia",
      numericCode: "104",
      currencyCode: "MMK",
      callingCodes: "95"
    },
    {
      name: "Namibia",
      code: "NA",
      region: "Africa",
      numericCode: "516",
      currencyCode: "NAD",
      callingCodes: "264"
    },
    {
      name: "Nauru",
      code: "NR",
      region: "Oceania",
      numericCode: "520",
      currencyCode: "AUD",
      callingCodes: "674"
    },
    {
      name: "Nepal",
      code: "NP",
      region: "Asia",
      numericCode: "524",
      currencyCode: "NPR",
      callingCodes: "977"
    },
    {
      name: "Netherlands",
      code: "NL",
      region: "EU",
      numericCode: "528",
      currencyCode: "EUR",
      callingCodes: "31"
    },
    {
      name: "New Caledonia",
      code: "NC",
      region: "Oceania",
      numericCode: "540",
      currencyCode: "XPF",
      callingCodes: "687"
    },
    {
      name: "New Zealand",
      code: "NZ",
      region: "Oceania",
      numericCode: "554",
      currencyCode: "NZD",
      callingCodes: "64"
    },
    {
      name: "Nicaragua",
      code: "NI",
      region: "Americas",
      numericCode: "558",
      currencyCode: "NIO",
      callingCodes: "505"
    },
    {
      name: "Niger",
      code: "NE",
      region: "Africa",
      numericCode: "562",
      currencyCode: "XOF",
      callingCodes: "227"
    },
    {
      name: "Nigeria",
      code: "NG",
      region: "Africa",
      numericCode: "566",
      currencyCode: "NGN",
      callingCodes: "234"
    },
    {
      name: "Niue",
      code: "NU",
      region: "Oceania",
      numericCode: "570",
      currencyCode: "NZD",
      callingCodes: "683"
    },
    {
      name: "Norfolk Island",
      code: "NF",
      region: "Oceania",
      numericCode: "574",
      currencyCode: "AUD",
      callingCodes: "672"
    },
    {
      name: "Korea (Democratic People's Republic of)",
      code: "KP",
      region: "Asia",
      numericCode: "408",
      currencyCode: "KPW",
      callingCodes: "850"
    },
    {
      name: "Northern Mariana Islands",
      code: "MP",
      region: "Oceania",
      numericCode: "580",
      currencyCode: "USD",
      callingCodes: "1670"
    },
    {
      name: "Norway",
      code: "NO",
      region: "Europe",
      numericCode: "578",
      currencyCode: "NOK",
      callingCodes: "47"
    },
    {
      name: "Oman",
      code: "OM",
      region: "Asia",
      numericCode: "512",
      currencyCode: "OMR",
      callingCodes: "968"
    },
    {
      name: "Pakistan",
      code: "PK",
      region: "Asia",
      numericCode: "586",
      currencyCode: "PKR",
      callingCodes: "92"
    },
    {
      name: "Palau",
      code: "PW",
      region: "Oceania",
      numericCode: "585",
      currencyCode: "(none)",
      callingCodes: "680"
    },
    {
      name: "Palestine, State of",
      code: "PS",
      region: "Asia",
      numericCode: "275",
      currencyCode: "ILS",
      callingCodes: "970"
    },
    {
      name: "Panama",
      code: "PA",
      region: "Americas",
      numericCode: "591",
      currencyCode: "PAB",
      callingCodes: "507"
    },
    {
      name: "Papua New Guinea",
      code: "PG",
      region: "Oceania",
      numericCode: "598",
      currencyCode: "PGK",
      callingCodes: "675"
    },
    {
      name: "Paraguay",
      code: "PY",
      region: "Americas",
      numericCode: "600",
      currencyCode: "PYG",
      callingCodes: "595"
    },
    {
      name: "Peru",
      code: "PE",
      region: "Americas",
      numericCode: "604",
      currencyCode: "PEN",
      callingCodes: "51"
    },
    {
      name: "Philippines",
      code: "PH",
      region: "Asia",
      numericCode: "608",
      currencyCode: "PHP",
      callingCodes: "63"
    },
    {
      name: "Pitcairn",
      code: "PN",
      region: "Oceania",
      numericCode: "612",
      currencyCode: "NZD",
      callingCodes: "64"
    },
    {
      name: "Poland",
      code: "PL",
      region: "EU",
      numericCode: "616",
      currencyCode: "PLN",
      callingCodes: "48"
    },
    {
      name: "Portugal",
      code: "PT",
      region: "EU",
      numericCode: "620",
      currencyCode: "EUR",
      callingCodes: "351"
    },
    {
      name: "Puerto Rico",
      code: "PR",
      region: "Americas",
      numericCode: "630",
      currencyCode: "USD",
      callingCodes: "1787"
    },
    {
      name: "Qatar",
      code: "QA",
      region: "Asia",
      numericCode: "634",
      currencyCode: "QAR",
      callingCodes: "974"
    },
    {
      name: "Republic of Kosovo",
      code: "XK",
      region: "Europe",
      numericCode: null,
      currencyCode: "EUR",
      callingCodes: "383"
    },
    {
      name: "Réunion",
      code: "RE",
      region: "Africa",
      numericCode: "638",
      currencyCode: "EUR",
      callingCodes: "262"
    },
    {
      name: "Romania",
      code: "RO",
      region: "Europe",
      numericCode: "642",
      currencyCode: "RON",
      callingCodes: "40"
    },
    {
      name: "Russian Federation",
      code: "RU",
      region: "Europe",
      numericCode: "643",
      currencyCode: "RUB",
      callingCodes: "7"
    },
    {
      name: "Rwanda",
      code: "RW",
      region: "Africa",
      numericCode: "646",
      currencyCode: "RWF",
      callingCodes: "250"
    },
    {
      name: "Saint Barthélemy",
      code: "BL",
      region: "Americas",
      numericCode: "652",
      currencyCode: "EUR",
      callingCodes: "590"
    },
    {
      name: "Saint Helena, Ascension and Tristan da Cunha",
      code: "SH",
      region: "Africa",
      numericCode: "654",
      currencyCode: "SHP",
      callingCodes: "290"
    },
    {
      name: "Saint Kitts and Nevis",
      code: "KN",
      region: "Americas",
      numericCode: "659",
      currencyCode: "XCD",
      callingCodes: "1869"
    },
    {
      name: "Saint Lucia",
      code: "LC",
      region: "Americas",
      numericCode: "662",
      currencyCode: "XCD",
      callingCodes: "1758"
    },
    {
      name: "Saint Martin (French part)",
      code: "MF",
      region: "Americas",
      numericCode: "663",
      currencyCode: "EUR",
      callingCodes: "590"
    },
    {
      name: "Saint Pierre and Miquelon",
      code: "PM",
      region: "Americas",
      numericCode: "666",
      currencyCode: "EUR",
      callingCodes: "508"
    },
    {
      name: "Saint Vincent and the Grenadines",
      code: "VC",
      region: "Americas",
      numericCode: "670",
      currencyCode: "XCD",
      callingCodes: "1784"
    },
    {
      name: "Samoa",
      code: "WS",
      region: "Oceania",
      numericCode: "882",
      currencyCode: "WST",
      callingCodes: "685"
    },
    {
      name: "San Marino",
      code: "SM",
      region: "Europe",
      numericCode: "674",
      currencyCode: "EUR",
      callingCodes: "378"
    },
    {
      name: "Sao Tome and Principe",
      code: "ST",
      region: "Africa",
      numericCode: "678",
      currencyCode: "STD",
      callingCodes: "239"
    },
    {
      name: "Saudi Arabia",
      code: "SA",
      region: "Asia",
      numericCode: "682",
      currencyCode: "SAR",
      callingCodes: "966"
    },
    {
      name: "Senegal",
      code: "SN",
      region: "Africa",
      numericCode: "686",
      currencyCode: "XOF",
      callingCodes: "221"
    },
    {
      name: "Serbia",
      code: "RS",
      region: "Europe",
      numericCode: "688",
      currencyCode: "RSD",
      callingCodes: "381"
    },
    {
      name: "Seychelles",
      code: "SC",
      region: "Africa",
      numericCode: "690",
      currencyCode: "SCR",
      callingCodes: "248"
    },
    {
      name: "Sierra Leone",
      code: "SL",
      region: "Africa",
      numericCode: "694",
      currencyCode: "SLL",
      callingCodes: "232"
    },
    {
      name: "Singapore",
      code: "SG",
      region: "Asia",
      numericCode: "702",
      currencyCode: "BND",
      callingCodes: "65"
    },
    {
      name: "Sint Maarten (Dutch part)",
      code: "SX",
      region: "Americas",
      numericCode: "534",
      currencyCode: "ANG",
      callingCodes: "1721"
    },
    {
      name: "Slovakia",
      code: "SK",
      region: "Europe",
      numericCode: "703",
      currencyCode: "EUR",
      callingCodes: "421"
    },
    {
      name: "Slovenia",
      code: "SI",
      region: "Europe",
      numericCode: "705",
      currencyCode: "EUR",
      callingCodes: "386"
    },
    {
      name: "Solomon Islands",
      code: "SB",
      region: "Oceania",
      numericCode: "090",
      currencyCode: "SBD",
      callingCodes: "677"
    },
    {
      name: "Somalia",
      code: "SO",
      region: "Africa",
      numericCode: "706",
      currencyCode: "SOS",
      callingCodes: "252"
    },
    {
      name: "South Africa",
      code: "ZA",
      region: "Africa",
      numericCode: "710",
      currencyCode: "ZAR",
      callingCodes: "27"
    },
    {
      name: "South Georgia and the South Sandwich Islands",
      code: "GS",
      region: "Americas",
      numericCode: "239",
      currencyCode: "GBP",
      callingCodes: "500"
    },
    {
      name: "Korea (Republic of)",
      code: "KR",
      region: "Asia",
      numericCode: "410",
      currencyCode: "KRW",
      callingCodes: "82"
    },
    {
      name: "South Sudan",
      code: "SS",
      region: "Africa",
      numericCode: "728",
      currencyCode: "SSP",
      callingCodes: "211"
    },
    {
      name: "Spain",
      code: "ES",
      region: "EU",
      numericCode: "724",
      currencyCode: "EUR",
      callingCodes: "34"
    },
    {
      name: "Sri Lanka",
      code: "LK",
      region: "Asia",
      numericCode: "144",
      currencyCode: "LKR",
      callingCodes: "94"
    },
    {
      name: "Sudan",
      code: "SD",
      region: "Africa",
      numericCode: "729",
      currencyCode: "SDG",
      callingCodes: "249"
    },
    {
      name: "Suriname",
      code: "SR",
      region: "Americas",
      numericCode: "740",
      currencyCode: "SRD",
      callingCodes: "597"
    },
    {
      name: "Svalbard and Jan Mayen",
      code: "SJ",
      region: "Europe",
      numericCode: "744",
      currencyCode: "NOK",
      callingCodes: "4779"
    },
    {
      name: "Swaziland",
      code: "SZ",
      region: "Africa",
      numericCode: "748",
      currencyCode: "SZL",
      callingCodes: "268"
    },
    {
      name: "Sweden",
      code: "SE",
      region: "Europe",
      numericCode: "752",
      currencyCode: "SEK",
      callingCodes: "46"
    },
    {
      name: "Switzerland",
      code: "CH",
      region: "EU",
      numericCode: "756",
      currencyCode: "CHF",
      callingCodes: "41"
    },
    {
      name: "Syrian Arab Republic",
      code: "SY",
      region: "Asia",
      numericCode: "760",
      currencyCode: "SYP",
      callingCodes: "963"
    },
    {
      name: "Taiwan",
      code: "TW",
      region: "Asia",
      numericCode: "158",
      currencyCode: "TWD",
      callingCodes: "886"
    },
    {
      name: "Tajikistan",
      code: "TJ",
      region: "Asia",
      numericCode: "762",
      currencyCode: "TJS",
      callingCodes: "992"
    },
    {
      name: "Tanzania, United Republic of",
      code: "TZ",
      region: "Africa",
      numericCode: "834",
      currencyCode: "TZS",
      callingCodes: "255"
    },
    {
      name: "Thailand",
      code: "TH",
      region: "Asia",
      numericCode: "764",
      currencyCode: "THB",
      callingCodes: "66"
    },
    {
      name: "Timor-Leste",
      code: "TL",
      region: "Asia",
      numericCode: "626",
      currencyCode: "USD",
      callingCodes: "670"
    },
    {
      name: "Togo",
      code: "TG",
      region: "Africa",
      numericCode: "768",
      currencyCode: "XOF",
      callingCodes: "228"
    },
    {
      name: "Tokelau",
      code: "TK",
      region: "Oceania",
      numericCode: "772",
      currencyCode: "NZD",
      callingCodes: "690"
    },
    {
      name: "Tonga",
      code: "TO",
      region: "Oceania",
      numericCode: "776",
      currencyCode: "TOP",
      callingCodes: "676"
    },
    {
      name: "Trinidad and Tobago",
      code: "TT",
      region: "Americas",
      numericCode: "780",
      currencyCode: "TTD",
      callingCodes: "1868"
    },
    {
      name: "Tunisia",
      code: "TN",
      region: "Africa",
      numericCode: "788",
      currencyCode: "TND",
      callingCodes: "216"
    },
    {
      name: "Turkey",
      code: "TR",
      region: "Asia",
      numericCode: "792",
      currencyCode: "TRY",
      callingCodes: "90"
    },
    {
      name: "Turkmenistan",
      code: "TM",
      region: "Asia",
      numericCode: "795",
      currencyCode: "TMT",
      callingCodes: "993"
    },
    {
      name: "Turks and Caicos Islands",
      code: "TC",
      region: "Americas",
      numericCode: "796",
      currencyCode: "USD",
      callingCodes: "1649"
    },
    {
      name: "Tuvalu",
      code: "TV",
      region: "Oceania",
      numericCode: "798",
      currencyCode: "AUD",
      callingCodes: "688"
    },
    {
      name: "Uganda",
      code: "UG",
      region: "Africa",
      numericCode: "800",
      currencyCode: "UGX",
      callingCodes: "256"
    },
    {
      name: "Ukraine",
      code: "UA",
      region: "Europe",
      numericCode: "804",
      currencyCode: "UAH",
      callingCodes: "380"
    },
    {
      name: "United Arab Emirates",
      code: "AE",
      region: "Asia",
      numericCode: "784",
      currencyCode: "AED",
      callingCodes: "971"
    },
    {
      name: "United Kingdom",
      code: "GB",
      region: "Europe",
      numericCode: "826",
      currencyCode: "GBP",
      callingCodes: "44"
    },
    {
      name: "United States of America",
      code: "US",
      region: "Americas",
      numericCode: "840",
      currencyCode: "USD",
      callingCodes: "1"
    },
    {
      name: "Uruguay",
      code: "UY",
      region: "Americas",
      numericCode: "858",
      currencyCode: "UYU",
      callingCodes: "598"
    },
    {
      name: "Uzbekistan",
      code: "UZ",
      region: "Asia",
      numericCode: "860",
      currencyCode: "UZS",
      callingCodes: "998"
    },
    {
      name: "Vanuatu",
      code: "VU",
      region: "Oceania",
      numericCode: "548",
      currencyCode: "VUV",
      callingCodes: "678"
    },
    {
      name: "Venezuela (Bolivarian Republic of)",
      code: "VE",
      region: "Americas",
      numericCode: "862",
      currencyCode: "VEF",
      callingCodes: "58"
    },
    {
      name: "Viet Nam",
      code: "VN",
      region: "Asia",
      numericCode: "704",
      currencyCode: "VND",
      callingCodes: "84"
    },
    {
      name: "Wallis and Futuna",
      code: "WF",
      region: "Oceania",
      numericCode: "876",
      currencyCode: "XPF",
      callingCodes: "681"
    },
    {
      name: "Western Sahara",
      code: "EH",
      region: "Africa",
      numericCode: "732",
      currencyCode: "MAD",
      callingCodes: "212"
    },
    {
      name: "Yemen",
      code: "YE",
      region: "Asia",
      numericCode: "887",
      currencyCode: "YER",
      callingCodes: "967"
    },
    {
      name: "Zambia",
      code: "ZM",
      region: "Africa",
      numericCode: "894",
      currencyCode: "ZMW",
      callingCodes: "260"
    },
    {
      name: "Zimbabwe",
      code: "ZW",
      region: "Africa",
      numericCode: "716",
      currencyCode: "BWP",
      callingCodes: "263"
    }
  ];

  return countrylist;
};

export const UsStates = () => {
  const us_states = [
    { name: "Alabama", code: "AL" },
    { name: "Alaska", code: "AK" },
    { name: "Arizona", code: "AZ" },
    { name: "Arkansas", code: "AR" },
    { name: "California", code: "CA" },
    { name: "Colorado", code: "CO" },
    { name: "Connecticut", code: "CT" },
    { name: "Delaware", code: "DE" },
    { name: "District of Columbia", code: "DC" },
    { name: "Florida", code: "FL" },
    { name: "Georgia", code: "GA" },
    { name: "Hawaii", code: "HI" },
    { name: "Idaho", code: "ID" },
    { name: "Illinois", code: "IL" },
    { name: "Indiana", code: "IN" },
    { name: "Iowa", code: "IA" },
    { name: "Kansas", code: "KS" },
    { name: "Kentucky", code: "KY" },
    { name: "Louisiana", code: "LA" },
    { name: "Maine", code: "ME" },
    { name: "Montana", code: "MT" },
    { name: "Nebraska", code: "NE" },
    { name: "Nevada", code: "NV" },
    { name: "New Hampshire", code: "NH" },
    { name: "New Jersey", code: "NJ" },
    { name: "New Mexico", code: "NM" },
    { name: "New York", code: "NY" },
    { name: "North Carolina", code: "NC" },
    { name: "North Dakota", code: "ND" },
    { name: "Ohio", code: "OH" },
    { name: "Oklahoma", code: "OK" },
    { name: "Oregon", code: "OR" },
    { name: "Maryland", code: "MD" },
    { name: "Massachusetts", code: "MA" },
    { name: "Michigan", code: "MI" },
    { name: "Minnesota", code: "MN" },
    { name: "Mississippi", code: "MS" },
    { name: "Missouri", code: "MO" },
    { name: "Pennsylvania", code: "PA" },
    { name: "Rhode Island", code: "RI" },
    { name: "South Carolina", code: "SC" },
    { name: "South Dakota", code: "SD" },
    { name: "Tennessee", code: "TN" },
    { name: "Texas", code: "TX" },
    { name: "Utah", code: "UT" },
    { name: "Vermont", code: "VT" },
    { name: "Virginia", code: "VA" },
    { name: "Washington", code: "WA" },
    { name: "West Virginia", code: "WV" },
    { name: "Wisconsin", code: "WI" },
    { name: "Wyoming", code: "WY" }
  ];
  return us_states;
};

export const dialingCodes = () => {
  const dialingCodeList = [
    { code: "93", name: "Afghanistan" },
    { code: "355", name: "Albania" },
    { code: "213", name: "Algeria" },
    { code: "684", name: "American Samoa" },
    { code: "376", name: "Andorra" },
    { code: "244", name: "Angola" },
    { code: "809", name: "Anguilla" },
    { code: "268", name: "Antigua" },
    { code: "54", name: "Argentina" },
    { code: "374", name: "Armenia" },
    { code: "297", name: "Aruba" },
    { code: "247", name: "Ascension Island" },
    { code: "61", name: "Australia" },
    { code: "672", name: "Australian External Territories" },
    { code: "43", name: "Austria" },
    { code: "994", name: "Azerbaijan" },
    { code: "242", name: "Bahamas" },
    { code: "246", name: "Barbados" },
    { code: "973", name: "Bahrain" },
    { code: "880", name: "Bangladesh" },
    { code: "375", name: "Belarus" },
    { code: "32", name: "Belgium" },
    { code: "501", name: "Belize" },
    { code: "229", name: "Benin" },
    { code: "809", name: "Bermuda" },
    { code: "975", name: "Bhutan" },
    { code: "284", name: "British Virgin Islands" },
    { code: "591", name: "Bolivia" },
    { code: "387", name: "Bosnia and Hercegovina" },
    { code: "267", name: "Botswana" },
    { code: "55", name: "Brazil" },
    { code: "284", name: "British V.I." },
    { code: "673", name: "Brunei Darussalm" },
    { code: "359", name: "Bulgaria" },
    { code: "226", name: "Burkina Faso" },
    { code: "257", name: "Burundi" },
    { code: "855", name: "Cambodia" },
    { code: "237", name: "Cameroon" },
    { code: "1", name: "Canada" },
    { code: "238", name: "CapeVerde Islands" },
    { code: "1", name: "Caribbean Nations" },
    { code: "345", name: "Cayman Islands" },
    { code: "238", name: "Cape Verdi" },
    { code: "236", name: "Central African Republic" },
    { code: "235", name: "Chad" },
    { code: "56", name: "Chile" },
    { code: "86", name: "China (People's Republic)" },
    { code: "886", name: "China-Taiwan" },
    { code: "57", name: "Colombia" },
    { code: "269", name: "Comoros and Mayotte" },
    { code: "242", name: "Congo" },
    { code: "682", name: "Cook Islands" },
    { code: "506", name: "Costa Rica" },
    { code: "385", name: "Croatia" },
    { code: "53", name: "Cuba" },
    { code: "357", name: "Cyprus" },
    { code: "420", name: "Czech Republic" },
    { code: "45", name: "Denmark" },
    { code: "246", name: "Diego Garcia" },
    { code: "767", name: "Dominca" },
    { code: "809", name: "Dominican Republic" },
    { code: "253", name: "Djibouti" },
    { code: "593", name: "Ecuador" },
    { code: "20", name: "Egypt" },
    { code: "503", name: "El Salvador" },
    { code: "240", name: "Equatorial Guinea" },
    { code: "291", name: "Eritrea" },
    { code: "372", name: "Estonia" },
    { code: "251", name: "Ethiopia" },
    { code: "500", name: "Falkland Islands" },
    { code: "298", name: "Faroe (Faeroe) Islands (Denmark)" },
    { code: "679", name: "Fiji" },
    { code: "358", name: "Finland" },
    { code: "33", name: "France" },
    { code: "596", name: "French Antilles" },
    { code: "594", name: "French Guiana" },
    { code: "241", name: "Gabon (Gabonese Republic)" },
    { code: "220", name: "Gambia" },
    { code: "995", name: "Georgia" },
    { code: "49", name: "Germany" },
    { code: "233", name: "Ghana" },
    { code: "350", name: "Gibraltar" },
    { code: "30", name: "Greece" },
    { code: "299", name: "Greenland" },
    { code: "473", name: "Grenada/Carricou" },
    { code: "671", name: "Guam" },
    { code: "502", name: "Guatemala" },
    { code: "224", name: "Guinea" },
    { code: "245", name: "Guinea-Bissau" },
    { code: "592", name: "Guyana" },
    { code: "509", name: "Haiti" },
    { code: "504", name: "Honduras" },
    { code: "852", name: "Hong Kong" },
    { code: "36", name: "Hungary" },
    { code: "354", name: "Iceland" },
    { code: "91", name: "India" },
    { code: "62", name: "Indonesia" },
    { code: "98", name: "Iran" },
    { code: "964", name: "Iraq" },
    { code: "353", name: "Ireland (Irish Republic; Eire)" },
    { code: "972", name: "Israel" },
    { code: "39", name: "Italy" },
    { code: "225", name: "Ivory Coast (La Cote d'Ivoire)" },
    { code: "876", name: "Jamaica" },
    { code: "81", name: "Japan" },
    { code: "962", name: "Jordan" },
    { code: "7", name: "Kazakhstan" },
    { code: "254", name: "Kenya" },
    { code: "855", name: "Khmer Republic (Cambodia/Kampuchea)" },
    { code: "686", name: "Kiribati Republic (Gilbert Islands)" },
    { code: "82", name: "Korea, Republic of (South Korea)" },
    { code: "850", name: "Korea, People's Republic of (North Korea)" },
    { code: "965", name: "Kuwait" },
    { code: "996", name: "Kyrgyz Republic" },
    { code: "371", name: "Latvia" },
    { code: "856", name: "Laos" },
    { code: "961", name: "Lebanon" },
    { code: "266", name: "Lesotho" },
    { code: "231", name: "Liberia" },
    { code: "370", name: "Lithuania" },
    { code: "218", name: "Libya" },
    { code: "423", name: "Liechtenstein" },
    { code: "352", name: "Luxembourg" },
    { code: "853", name: "Macao" },
    { code: "389", name: "Macedonia" },
    { code: "261", name: "Madagascar" },
    { code: "265", name: "Malawi" },
    { code: "60", name: "Malaysia" },
    { code: "960", name: "Maldives" },
    { code: "223", name: "Mali" },
    { code: "356", name: "Malta" },
    { code: "692", name: "Marshall Islands" },
    { code: "596", name: "Martinique (French Antilles)" },
    { code: "222", name: "Mauritania" },
    { code: "230", name: "Mauritius" },
    { code: "269", name: "Mayolte" },
    { code: "52", name: "Mexico" },
    { code: "691", name: "Micronesia (F.S. of Polynesia)" },
    { code: "373", name: "Moldova" },
    { code: "33", name: "Monaco" },
    { code: "976", name: "Mongolia" },
    { code: "473", name: "Montserrat" },
    { code: "212", name: "Morocco" },
    { code: "258", name: "Mozambique" },
    { code: "95", name: "Myanmar (former Burma)" },
    { code: "264", name: "Namibia (former South-West Africa)" },
    { code: "674", name: "Nauru" },
    { code: "977", name: "Nepal" },
    { code: "31", name: "Netherlands" },
    { code: "599", name: "Netherlands Antilles" },
    { code: "869", name: "Nevis" },
    { code: "687", name: "New Caledonia" },
    { code: "64", name: "New Zealand" },
    { code: "505", name: "Nicaragua" },
    { code: "227", name: "Niger" },
    { code: "234", name: "Nigeria" },
    { code: "683", name: "Niue" },
    { code: "850", name: "North Korea" },
    { code: "1 670", name: "North Mariana Islands (Saipan)" },
    { code: "47", name: "Norway" },
    { code: "968", name: "Oman" },
    { code: "92", name: "Pakistan" },
    { code: "680", name: "Palau" },
    { code: "507", name: "Panama" },
    { code: "675", name: "Papua New Guinea" },
    { code: "595", name: "Paraguay" },
    { code: "51", name: "Peru" },
    { code: "63", name: "Philippines" },
    { code: "48", name: "Poland" },
    { code: "351", name: "Portugal (includes Azores)" },
    { code: "1 787", name: "Puerto Rico" },
    { code: "974", name: "Qatar" },
    { code: "262", name: "Reunion (France)" },
    { code: "40", name: "Romania" },
    { code: "7", name: "Russia" },
    { code: "250", name: "Rwanda (Rwandese Republic)" },
    { code: "670", name: "Saipan" },
    { code: "378", name: "San Marino" },
    { code: "239", name: "Sao Tome and Principe" },
    { code: "966", name: "Saudi Arabia" },
    { code: "221", name: "Senegal" },
    { code: "381", name: "Serbia and Montenegro" },
    { code: "248", name: "Seychelles" },
    { code: "232", name: "Sierra Leone" },
    { code: "65", name: "Singapore" },
    { code: "421", name: "Slovakia" },
    { code: "386", name: "Slovenia" },
    { code: "677", name: "Solomon Islands" },
    { code: "252", name: "Somalia" },
    { code: "27", name: "South Africa" },
    { code: "34", name: "Spain" },
    { code: "94", name: "Sri Lanka" },
    { code: "290", name: "St. Helena" },
    { code: "869", name: "St. Kitts/Nevis" },
    { code: "508", name: "St. Pierre &(et) Miquelon (France)" },
    { code: "249", name: "Sudan" },
    { code: "597", name: "Suriname" },
    { code: "268", name: "Swaziland" },
    { code: "46", name: "Sweden" },
    { code: "41", name: "Switzerland" },
    { code: "963", name: "Syrian Arab Republic (Syria)" },
    { code: "689", name: "Tahiti (French Polynesia)" },
    { code: "886", name: "Taiwan" },
    { code: "7", name: "Tajikistan" },
    { code: "255", name: "Tanzania (includes Zanzibar)" },
    { code: "66", name: "Thailand" },
    { code: "228", name: "Togo (Togolese Republic)" },
    { code: "690", name: "Tokelau" },
    { code: "676", name: "Tonga" },
    { code: "1 868", name: "Trinidad and Tobago" },
    { code: "216", name: "Tunisia" },
    { code: "90", name: "Turkey" },
    { code: "993", name: "Turkmenistan" },
    { code: "688", name: "Tuvalu (Ellice Islands)" },
    { code: "256", name: "Uganda" },
    { code: "380", name: "Ukraine" },
    { code: "971", name: "United Arab Emirates" },
    { code: "44", name: "United Kingdom" },
    { code: "598", name: "Uruguay" },
    { code: "1", name: "USA" },
    { code: "7", name: "Uzbekistan" },
    { code: "678", name: "Vanuatu (New Hebrides)" },
    { code: "39", name: "Vatican City" },
    { code: "58", name: "Venezuela" },
    { code: "84", name: "Viet Nam" },
    { code: "1 340", name: "Virgin Islands" },
    { code: "681", name: "Wallis and Futuna" },
    { code: "685", name: "Western Samoa" },
    { code: "381", name: "Yemen (People's Democratic Republic of)" },
    { code: "967", name: "Yemen Arab Republic (North Yemen)" },
    { code: "381", name: "Yugoslavia (discontinued)" },
    { code: "243", name: "Zaire" },
    { code: "260", name: "Zambia" },
    { code: "263", name: "Zimbabwe" }
  ];

  return dialingCodeList;
};
