import { IProduct } from "../interfaces/iProduct";
import i18next from "i18next";
import { ISingleProduct, IProductItemView, IProductView, IAvailabilityItem } from "../interfaces/iProduct";
import { ITaxon } from "src/interfaces/iTaxon";
import { getSelectedLanguage } from "./languageHelper";
import moment from 'moment';

export const formatPrice = (price:number, dec:number = 0, currencySymbol:string = 'R') => {

    if (currencySymbol !== "R") {
      dec = 2;
    }

    const price_val = (price < 0 ? price * -1 : price) / 100;
    const price_str = `${price_val
      .toFixed(dec)
      .replace(/./g, (c, i, a) =>
        i && c !== "." && (a.length - i) % 3 === 0 ? `,${c}` : c
      )}`;
    if (price < 0) {
      return `${currencySymbol} -${price_str}`;
    }
    return `${currencySymbol} ${price_str}`;

  };


export const productHasTaxon = (product: IProduct, slug: string): boolean => {
    if (
        product &&
        product.taxons &&
        product.taxons.main &&
        product.taxons.main.slug &&
        (product.taxons.main.slug).indexOf(slug) > -1
    ) {
        return true;
    }

    if (
        product &&
        product.taxons &&
        product.taxons.others &&
        Array.isArray(product.taxons.others) &&
        product.taxons.others.length > 0
    ) {
        const inTaxon = product.taxons.others.filter(taxon => {
            return (taxon.slug).indexOf(slug) > -1
        })
        return inTaxon && Array.isArray(inTaxon) && inTaxon.length > 0 ? true : false;
    }

    return false;
};

export const getSelectedLanguage_ = (props: any): string => {
    const { site, user } = props;
    const lang = i18next && i18next.language;
    const locales = site && site.data && site.data.locales && Array.isArray(site.data.locales) && site.data.locales.filter((item: any) => {
        return lang && (item.code).indexOf(`${lang}`) > -1 ? true : false;
    });
    const selectedLocale = locales && Array.isArray(locales) && locales.length > 0 && locales[0];

    if (selectedLocale && selectedLocale.code && selectedLocale.code.length > 0) {
        return selectedLocale.code;
    } else {
        return selectedLocale;
    }
}

export const productQtyInCart = (item:any, cart:any):number => {
    if (item && cart) {
      return cart && cart.items && Array.isArray(cart.items) && cart.items.length > 0
        ? cart.items.filter(
          (i:any) => i.product && i.product.code == item.code
        ).length
          ? cart.items.filter(
            (i:any) => i.product && i.product.code == item.code
          )[0].quantity
          : 0
        : 0;
    }
    return 0;
  }

  export const extractTaxons = (product:IProduct):string[] => {
    let taxonExport = [];
    if(product && product.taxons && product.taxons.main && product.taxons.main.isNavEntry === true){
      taxonExport.push(product.taxons.main.slug);
    }
    if(product && product.taxons && product.taxons.others && Array.isArray(product.taxons.others)){
      product.taxons.others.forEach((item:ITaxon) => {
        if(item.isNavEntry === true){
          taxonExport.push(item.slug);
        }
      });
    }
    return taxonExport;
  }

  export const extractTaxonsAndCodes = (product:IProduct):string[] => {
    let taxonExport = [];
    if(product && product.taxons && product.taxons.main && product.taxons.main.isNavEntry === true){
      taxonExport.push({slug:product.taxons.main.slug, code:product.taxons.main.slug });
    }
    if(product && product.taxons && product.taxons.others && Array.isArray(product.taxons.others)){
      product.taxons.others.forEach((item:ITaxon) => {
        if(item.isNavEntry === true){
          taxonExport.push({slug:item.slug, code:item.code});
        }
      });
    }
    return taxonExport;
  }

  export const isProductOutOfStock = (product:ISingleProduct | any, deliveryAreaCode:string):boolean => {
    if(deliveryAreaCode){
      const warehouses = deliveryAreaCode && product && product.availability && Array.isArray(product.availability) && product.availability.filter((item:any) => {
        return item && item.deliveryAreaCodes && Array.isArray(item.deliveryAreaCodes) && item.deliveryAreaCodes.indexOf(deliveryAreaCode) > -1 ? true : false;
      });

      if(warehouses && Array.isArray(warehouses) && warehouses[0]){

        let outOfStockCount = 0;
        warehouses.forEach((item:any) => {
          if(item.outOfStock && item.outOfStock === true){
            outOfStockCount++;
          }
        });

        if(outOfStockCount === warehouses.length){
          return true;
        }

        let hasAvailabilityCount = 0;
        warehouses.forEach((item:any) => {
          if(!item.hasAvailability){
            hasAvailabilityCount++;
          }
        });

        if(hasAvailabilityCount === warehouses.length){
          return true;
        }

      }
    }

    if(product && product.outOfStock && product.outOfStock === true){
      return true;
    }

    return false;
  }

  // View Factories

  export const makeProductItemView = (productItem:any, site:any, cart:any):IProductItemView | null => {
    const locale = getSelectedLanguage({ site });
    if(productItem && productItem.id && productItem.id > 0){
      return {
        id:productItem.id,
        name:productItem.translations[locale].name,
        slug:productItem.slug,
        code:productItem.code,
        price:productItem.price,
        formattedPrice:formatPrice(productItem.price, 0, site.data.currencySymbol),
        litrePrice:site.data.code === 'eu' && formatPrice(((productItem.price / 750) * 1000), 0, site.data.currencySymbol),
        description:productItem.translations[locale].description,
        shortDescription:productItem.translations[locale].shortDescription,
        outOfStock:productItem.outOfStock,
        isComingSoon:productItem.isComingSoon,
        images:productItem.images,
        videos:productItem.videos,
        productQtyInCart:productQtyInCart(productItem,cart),
        isAreaFlagged:productItem.isAreaFlagged,
        newBadge:productItem.newBadge,
        isGiftCard:productItem.isGiftCard,
        isPromotionalProduct:productItem.isPromotionalProduct,
        createdDate:productItem.createdDate,
        taxons:extractTaxons(productItem),
        taxonCodes:extractTaxonsAndCodes(productItem),
        availability:productItem.availability,
        customerRequirements: productItem.customerRequirements ? productItem.customerRequirements : null
      }
    } else {
      return null;
    }
  }

  export const makeSingleProductView = (product:ISingleProduct, site:any, cart:any ):IProductView | null => {
    const locale = getSelectedLanguage({ site });
    if(product && product.id && product.id > 0){
      return {
        id:product.id,
        slug:product.slug,
        code:product.code,
        name:product.translations[locale].name,
        description:product.translations[locale].description,
        shortDescription:product.translations[locale].shortDescription,
        price:product.price,
        formattedPrice:formatPrice(product.price,0,site.data.currencySymbol),
        outOfStock:product.outOfStock,
        isComingSoon:product.isComingSoon,
        images:product.images,
        videos:product.videos,
        isGiftCard:product.isGiftCard,
        isPromotionalProduct:product.isPromotionalProduct,
        isAreaFlagged:product.isAreaFlagged,
        newBadge:product.newBadge,
        associations:product.associations[0]?.products?.map((item:any) => makeProductItemView(item,site,cart)).filter((item:any) => item),
        attributes:product.attributes[locale],
        productQtyInCart:productQtyInCart(product,cart),
        createdDate:product.createdDate,
        taxons:extractTaxons(product),
        taxonCodes:extractTaxonsAndCodes(product),
        availability:product.availability,
        customerRequirements: product.customerRequirements ? product.customerRequirements : null
      };

    } else {
      return null;
    }
  };

  export const customerRestricted = (product:IProductItemView | IProductView, user:any):boolean => {
    let isRestricted = true;
    if(product.customerRequirements){
      if(user && user.id && user.id > 0){
        if(product.customerRequirements.subscriptions && Array.isArray(product.customerRequirements.subscriptions) && product.customerRequirements.subscriptions.length > 0){
          //is subscription restricted
          //find all user subscriptions
          if(user && user.subscriptions && Array.isArray(user.subscriptions)){
            user.subscriptions.forEach((item:any) => {
              product.customerRequirements.subscriptions.forEach((subItem:any) => {
                if(item.code === subItem.slug){
                  isRestricted = false;
                }
              })
            })
          }
        }
        if(product.customerRequirements.memberships && Array.isArray(product.customerRequirements.memberships) && product.customerRequirements.memberships.length > 0){
          //is membership restricted
          if(user && user.memberships && Array.isArray(user.memberships)){
            user.memberships.forEach((item:any) => {
              product.customerRequirements.memberships.forEach((subItem:any) => {
                if(item.code === subItem.code){
                  isRestricted = false;
                }
              })
            })
          }
        }
      }
    } else {
      isRestricted = false;
    }
    return isRestricted;
  }

  export const isExclusive = (product:IProductItemView | IProductView):boolean => {
    let isExclusive = false;
    if(product.customerRequirements){
      if(product.customerRequirements.subscriptions && Array.isArray(product.customerRequirements.subscriptions) && product.customerRequirements.subscriptions.length > 0){
        isExclusive = true;
      }
      if(product.customerRequirements.memberships && Array.isArray(product.customerRequirements.memberships) && product.customerRequirements.memberships.length > 0){
        isExclusive = true;
      }
    }
    return isExclusive;
  }

  export const customerRestrictedLink = (product:IProductItemView | IProductView):string | null=>{
    let link = null;
    if(product.customerRequirements){
        if(product.customerRequirements.subscriptions && Array.isArray(product.customerRequirements.subscriptions) && product.customerRequirements.subscriptions.length > 0){
          //is subscription restricted
          //find all user subscriptions

          product.customerRequirements.subscriptions.forEach((subItem:any) => {
            if(subItem.slug === 'wine-club-subscription'){
              link = '/za/wine-club/about';
            }
            if(subItem.slug === 'cyder-club'){
              link = '/uk/subscribe/cyder-club/signup';
            }
            if(subItem.slug === 'candide'){
              link = '/uk/product/garden-membership';
            }
          })

        }
        if(product.customerRequirements.memberships && Array.isArray(product.customerRequirements.memberships) && product.customerRequirements.memberships.length > 0){
          //is membership restricted

          product.customerRequirements.memberships.forEach((subItem:any) => {
            if(subItem.code === 'wine-club-subscription'){
              link = '/za/wine-club/about';
            }
            if(subItem.code === 'cyder-club'){
              link = '/uk/subscribe/cyder-club/signup';
            }
            if(subItem.code === 'candide'){
              link = '/uk/product/garden-membership';
            }
          })

        }

    }
    return link;
  }

  export const customerRestrictedType = (product:IProductItemView | IProductView):string | null=>{
    let type = null;
    if(product.customerRequirements){
        if(product.customerRequirements.subscriptions && Array.isArray(product.customerRequirements.subscriptions) && product.customerRequirements.subscriptions.length > 0){
          //is subscription restricted
          //find all user subscriptions

          product.customerRequirements.subscriptions.forEach((subItem:any) => {
            if(subItem.slug.indexOf('wine-club') > -1){
              type = 'WINE CLUB';
            }
            if(subItem.slug.indexOf('cyder') > -1){
              type = 'CYDER CLUB';
            }
            if(subItem.slug.indexOf('candide') > -1){
              type = 'MEMBERS';
            }
          })

        }
        if(product.customerRequirements.memberships && Array.isArray(product.customerRequirements.memberships) && product.customerRequirements.memberships.length > 0){
          //is membership restricted

          product.customerRequirements.memberships.forEach((subItem:any) => {
            if(subItem.code.indexOf('wine-club') > -1){
              type = 'WINE CLUB';
            }
            if(subItem.code.indexOf('cyder') > -1){
              type = 'CYDER CLUB';
            }
            if(subItem.code.indexOf('candide') > -1){
              type = 'MEMBERS';
            }
          })

        }

    }
    return type;
  }

  export const isPrimaryMedia = (code: string = ''): boolean => {
    return code.trim().toLowerCase() === 'primary' || code.trim().toLowerCase() === 'original';
  }

  export const isSecondaryMedia = (code: string = ''): boolean => {
    return code.trim().toLowerCase() === 'secondary';
  }

  export const isIE = (): boolean => {
    const ua = navigator.userAgent;
    /* MSIE used to detect old browsers and Trident used to newer ones*/
    const is_ie = ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;

    return is_ie;
  }