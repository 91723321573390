export class ApiErrorResponse {
  constructor(public responseBody: any) {}

  getError(): string | null {
    return this.responseBody?.error || null;
  }

  getMessage(): string | null {
    return this.responseBody?.message || null;
  }

  isError(type: string) {
    return this.getError() === type;
  }
}

export async function doApiCall<R = any>(
  url: string,
  options: RequestInit = { method: "GET" }
): Promise<R> {
  // console.info("Api Call", url, options);

  return fetch(url, options)
    .then(async (response) => {
      if (response.ok) {

        if(response.headers.has('content-type') && !response.headers.get('content-type').includes('application/json')){
          return await response.text();
        }

        return await response.json();
      }

      throw new ApiErrorResponse(await response.json());
    })
    .catch(function (error) {
      // error
      console.error("Api call error caught", error);
      throw error;
    });
}