import i18next from "i18next";
import { useTranslation } from 'react-i18next';


export const getSelectedLanguage = (props: any): string => {
    const { site } = props;
    const lang = i18next && i18next.language ? i18next.language : 'en';
    const locales = site && site.data && site.data.locales && Array.isArray(site.data.locales) && site.data.locales.filter((item: any) => {
        return lang && (item.code).indexOf(`${lang}`) > -1 ? true : false;
    });
    const selectedLocale = locales && Array.isArray(locales) && locales.length > 0 && locales[0];

    if (selectedLocale && selectedLocale.code && selectedLocale.code.length > 0) {
        return selectedLocale.code;
    } else {
        return selectedLocale ? selectedLocale : 'en_US';
    }

}

export const getLanguageCode = () => {
    const lang = i18next && i18next.language;

    if (lang.indexOf("en") === 0) {
        return "en";
    } else if (lang.indexOf("it") === 0) {
        return "it";
    } else {
        return "en";
    }
}
