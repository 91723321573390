import React from "react";
import { IFlavorConfigFactory } from "../flavorConfig";
import {
  BTBio,
  FacebookIcn,
  InstagramIcn,
  notFoundNewt,
  SiteLogo,
  TwitterIcn,
  ukHomeBanner,
  ukHomeHighlights,
  videoSplash,
  VignamaggioBio,
} from "./newt.assets";

export const NewtUKConfig: IFlavorConfigFactory = () => {
  const siteCode = "uk";

  return {
    siteClass: "THENEWT",
    fonts: [],
    imgNotFound: notFoundNewt,
    features:{
      deliveryAreas:true
    },
    emails: {
      deliveries: `shop@thenewtinsomerset.com`,
      orders: "shop@thenewtinsomerset.com",
      orders2: "",
    },
    personalShopper: {
      name: "US",
      email: "shop@thenewtinsomerset.com",
    },
    hubspot: {
      newsletterSignup: {
        id: "d50a242b-6820-420b-b57d-55415b0c3378",
      },
    },
    Header: {
      siteName: [`The Newt in Somerset - Shop`],
      siteTitle: "The Newt in Somerset - Shop",
      phoneNumber: "01963 577727",
      homeLinkLabel: "VISIT THE NEWT",
      homeLinkUrl: "https://thenewtinsomerset.com/",
      title: "The Newt in Somerset - Shop",
      bannerLinkColor: "#FFF",
    },
    Footer: {
      copyright: "The Newt in Somerset",
      logo: SiteLogo,
      socialMedia: [
        {
          network: "facebook",
          url: "https://www.facebook.com/TheNewtinSomerset/",
          icn: FacebookIcn,
        },
        {
          network: "twitter",
          url: "https://twitter.com/thenewtsomerset",
          icn: TwitterIcn,
        },
        {
          network: "instagram",
          url: "https://www.instagram.com/thenewtinsomerset/",
          icn: InstagramIcn,
        },
      ],
      footerLinks: [
        {
          label: "Contact Us",
          url: "/customer-care",
          sites: [],
        },
        {
          label: "Exchange and Returns",
          url: "https://shop.thenewtinsomerset.com/uk/landing/exchange-and-returns",
          sites: [],
        },
        {
          label: "Delivery",
          url: "https://shop.thenewtinsomerset.com/uk/landing/delivery",
          sites: [],
        },
        {
          label: "About The Newt in Somerset",
          url: "https://thenewtinsomerset.com/",
          sites: [],
        },
        {
          label: "Terms and Conditions",
          url: "/terms-and-conditions",
          sites: [],
        },
        {
          label: "Privacy Policy",
          url: "https://thenewtinsomerset.com/privacy-policy",
          sites: [],
        },
      ],
    },
    NewsletterSignup: {
      ctaTitle: "Looking for something special?",
      ctaText:
        "Let us help you find that one-of-a-kind gift. Email us for assistance with selecting the right product or placing your order.",
      ctaLink: "mailto:shop@thenewtinsomerset.com?subject=Personal Shopping",
      ctaLinkLabel: "EMAIL US",
    },
    HomePage: {
      videoSplash: videoSplash,
      homeHighlights: ukHomeHighlights,
      banner: {
        img: ukHomeBanner,
        overlay: true,
        text: (
          <div className="newt_bottomLeftZa">
            <h1>
              <span>SAVOUR THE TASTE OF</span>
              <span>SUMMER</span>
              <span></span>
              <span className="btn">SHOP NOW</span>
            </h1>
          </div>
        ),
        link: `/uk/categories/summer`,
        eventName: "taste-summer-uk",
      },
      video_: {
        splash:
          "https://res.cloudinary.com/babylon/image/upload/f_auto,w_1000,q_auto:good,c_lfill/v1594047629/newt-shop/video/videosplash_v7c0cl.jpg",
        id: "H7-Yyvs8dnc",
        title: "",
        subTitle: "",
      },
      sectionFeatured: {
        title: "Somerset Cyder",
        subTitle: "Taste our premium",
        img: ukHomeHighlights,
        productsSlug: "__promotions/featured",
        link: `/subscribe/cyder-club/signup`,
      },
      sectionBestSellers: {
        title: "Bestsellers",
        subTitle: "Shop this Season's",
        productsSlug: "___promotions/best-sellers",
      },
      sectionWhileYouAreHere: {
        title: "MORE FROM OUR FRIENDS ABROAD",
        subTitle: "STAY A LITTLE LONGER AND DISCOVER",
        sections: [
          {
            title: "Vignamaggio",
            link: "https://www.vignamaggio.com/",
            img: VignamaggioBio,
            desc: "Vignamaggio is one of Tuscany’s oldest farming estates, a Renaissance treasure trove in the heart of the Chianti region. The villas sit in Italian gardens and vine-clad hills, overlooking a serene landscape.",
            linkTitle: "Vignamaggio",
          },
          {
            title: "Babylonstoren",
            link: "https://www.babylonstoren.com/",
            img: BTBio,
            desc: "Babylonstoren is one of the oldest Cape Dutch farms in the Franschhoek valley of South Africa. It has a beautiful and diverse fruit and vegetable garden, unique accommodation, fine food and wine, and evokes a sense of wellbeing.",
            linkTitle: "Babylonstoren",
          },
        ],
      },
    },
    Checkout: {
      needAssistance: (
        <li>
          <p>Free shipping on all orders.</p>
          <p>Shipping takes 3 – 5 days, depending on the destination.</p>
        </li>
      ),
    },
  };
};
