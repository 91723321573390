import { call, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { IProductsSearchStore } from "src/interfaces/iProducts";
import { DataStatus } from "src/interfaces/iRedux";
import { ShopApi } from "./api";



export const PRODUCT_SEARCH = "app/App/PRODUCT_SEARCH";
export const PRODUCT_SEARCH_SUCCESS = "app/App/PRODUCT_SEARCH_SUCCESS";
export const PRODUCT_SEARCH_ERROR = "app/App/PRODUCT_SEARCH_ERROR";

// actions
// GET USER ADDRESSES
export function onProductSearch(payload) {
  return {
    type: PRODUCT_SEARCH,
    payload,
  };
}

export function onProductSearchSuccess(payload) {
  return {
    type: PRODUCT_SEARCH_SUCCESS,
    payload,
  };
}

export function onProductSearchError(error) {
  return {
    type: PRODUCT_SEARCH_ERROR,
    error,
  };
}

const initProductsSearch:any = {
  status:DataStatus.UNINIT,
  query:{},
  message:'',
  data:[]
}

// REDUCERS
export function reducer(state:IProductsSearchStore = initProductsSearch, action: any = {}) {
  switch (action.type) {
    // get user addresses
    case PRODUCT_SEARCH:
      return {...state,...{
        status:DataStatus.LOADING,
        query:action.payload,
        message:`Searching for ${action.payload?.searchText}`
      }};
    case PRODUCT_SEARCH_SUCCESS:
      return {...state,...{
        status:DataStatus.OK,
        data:action.payload,
        message:''
      }};
    case PRODUCT_SEARCH_ERROR:
      return {...state,...{
        status:DataStatus.ERROR,
        data:state.data,
        message:'An error occurred'
      }};
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(PRODUCT_SEARCH, productSearch);
}

// GET USER ADDRESSES
export function* productSearch(args) {

  const { searchText, channelCode, deliveryAreaCode } = args?.payload;

  try {
    const data = yield call(ShopApi.productSearch, searchText, channelCode, deliveryAreaCode );
    if(data && data.products && Array.isArray(data.products)){
      yield put(onProductSearchSuccess(data.products));
    } else {
      yield put(onProductSearchSuccess([]));
    }
  } catch (err) {
    // error
    yield put(onProductSearchError(err));
  }
}

// SELECTORS
const getThis = (state) => state.productSearch;
export const makeSelectProductSearch = createSelector([getThis], (productSearch) => {
  return productSearch;
});
