export const EventEmitter = {
  _events: {},
  dispatch: function(event, data) {
    // dispatch {event}#{id} events
    Object.keys(this._events)
      .forEach(s => {
        if (s.indexOf("#") > -1) {
          if (s.substr(0, s.indexOf("#")) === event && this._events[s].length > 0) {
            this._events[s][0](data);
          }
        }
      });

    // no one is listening to this event
    if (!this._events.hasOwnProperty(event)) return;

    for (var i = 0; i < this._events[event].length; i++){
      if(this._events[event][i]){
        this._events[event][i](data);
      }
      // incase the subscribe function caused an unsubscribe
      // check if subscriber still exists
      if(!this._events.hasOwnProperty(event)) break;
    }
  },
  subscribe: function(event, callback, unique = false) {
    if (!this._events[event]) this._events[event] = []; // new event
    if(unique === false){
      if(this._events[event]){
        this._events[event] = [callback];
      } else {
        this._events[event].push(callback);
      }
    } else {
      this._events[event][0] = callback;
    }
  },
  unsubscribe: function(event) {
    delete this._events[event];
  }
  // note that we do not handle unsubscribe here
};
