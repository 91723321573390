import { fromJS } from "immutable";
import { call, delay, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { EventEmitter } from "../../helpers/eventEmitter";
import { updateObj } from "../../helpers/gen";
import { ShopApi } from "./api";
import { CandideMemberActionType } from "./CandideMember";
import { IPayloadAction } from "./types/Action";
import {
  onChangeLanguageError,
  onChangeLanguageSuccess,
  onHasSubscriptionCartError,
  onHasSubscriptionCartSuccess,
  onNewsletterSignupError,
  onNewsletterSignupSuccess,
  onPasswordForgotError,
  onPasswordForgotSuccess,
  onPasswordResetError,
  onPasswordResetSuccess,
  onSubscriptionPauseError,
  onSubscriptionPauseSuccess,
  onSubscriptionRemoveError,
  onSubscriptionRemoveSuccess,
  onSubscriptionRemoveStateError,
  onSubscriptionRemoveStateSuccess,
  onSubscriptionSignupError,
  onSubscriptionSignupSuccess,
  onUpdatePasswordError,
  onUpdatePasswordSuccess,
  onUpdatePreferredLanguageError,
  onUpdatePreferredLanguageSuccess,
  onUpdateReferralError,
  onUpdateReferralSuccess,
  onUserLoginError,
  onUserLoginSuccess,
  onUserLogoutError,
  onUserLogoutSuccess,
  onUserRegisterError,
  onUserRegisterSuccess,
  onUserUpdateError,
  onUserUpdateSuccess,
  onUserVerifyError,
  onUserVerifySuccess,
  UserActionType,
  onUserHydrateSuccess,
  onSetSaveCardsSuccess,
  onSetSaveCardsError,
} from "./UserActions";
import { IUser, IUserLoginPayload } from "./types";
import { ShopApiTokens } from "./api/ShopApiTokens";

export type IUserState = IUser | {};


const initialState: IUserState = fromJS({});

export function isUser(state: IUserState): state is IUser {
  return 'email' in state;
}

// REDUCERS
export function reducer(
  state: IUserState = initialState,
  action: any = { type: UserActionType.NOOP }
): IUserState {

  switch (action.type) {
    // user login
    case UserActionType.USER_LOGIN:
      const user = JSON.parse(JSON.stringify(state));
      if(user && user.responseBody){
        delete user.responseBody
      }
      if(user && user.password_reset){
        delete user.password_reset
      }
      return user;
    case UserActionType.USER_LOGIN_SUCCESS:
      return action.user;
    case UserActionType.USER_LOGIN_ERROR:
      return {};
    case UserActionType.USER_HYDRATE_SUCCESS:
      return action.user;

    // user campaign login
    case UserActionType.USER_CAMPAIGN_LOGIN:
      return {};
    case UserActionType.USER_CAMPAIGN_LOGIN_SUCCESS:
      return action.user;
    case UserActionType.USER_CAMPAIGN_LOGIN_ERROR:
      return {};

    // user campaign verify
    case UserActionType.USER_CAMPAIGN_VERIFY:
      return state;
    case UserActionType.USER_CAMPAIGN_VERIFY_SUCCESS:
      return action.verify;
    case UserActionType.USER_CAMPAIGN_VERIFY_ERROR:
      return state; /////////////////////

    // user logout
    case UserActionType.USER_LOGOUT:
      return state;
    case UserActionType.USER_LOGOUT_SUCCESS:
      return {};
    case UserActionType.USER_LOGOUT_ERROR:
      return state;

    // user register
    case UserActionType.USER_REGISTER:
      const user_register = JSON.parse(JSON.stringify(state));
      if(user_register && user_register.responseBody){
        delete user_register.responseBody
      }
      return user_register;
    case UserActionType.USER_REGISTER_SUCCESS:
      return action.user;
    case UserActionType.USER_REGISTER_ERROR:
      return {...state, ...action.error};
    // user verify
    case UserActionType.USER_VERIFY:
      return state;
    case UserActionType.USER_VERIFY_SUCCESS:
      return {
        ...action.payload,
      };
    case UserActionType.USER_VERIFY_ERROR:
      return {};

    // user update
    case UserActionType.USER_UPDATE:
      return state;
    case UserActionType.USER_UPDATE_SUCCESS:
      return {...state, ...action.user};
    case UserActionType.USER_UPDATE_ERROR:
      return state;

    // user update
    case UserActionType.UPDATE_PASSWORD:
      return state;
    case UserActionType.UPDATE_PASSWORD_SUCCESS:
      return {...state, ...action.user};
    case UserActionType.UPDATE_PASSWORD_ERROR:
      return state;


    // password forgot
    case UserActionType.PASSWORD_FORGOT:
      const user_password_reset = JSON.parse(JSON.stringify(state));
      if(user_password_reset && user_password_reset.responseBody){
        delete user_password_reset.responseBody
      }
      if(user_password_reset && user_password_reset.password_reset){
        delete user_password_reset.password_reset
      }
      return user_password_reset;
    case UserActionType.PASSWORD_FORGOT_SUCCESS:
      //action.user
      const result = {password_reset:action.user}
      return {...state,...result};
    case UserActionType.PASSWORD_FORGOT_ERROR:
      return {...state,...action.error};
    // password reset
    case UserActionType.PASSWORD_RESET:
      return state;
    case UserActionType.PASSWORD_RESET_SUCCESS:
      return action.user;
    case UserActionType.PASSWORD_RESET_ERROR:
      return action.error;
      return state;

    // password reset
    case UserActionType.NEWSLETTER_SIGNUP:
      return state;
    case UserActionType.NEWSLETTER_SIGNUP_SUCCESS:
      return updateObj(state, {
        isSubscribedToNewsletter: action.user.isSubscribedToNewsletter,
      });
    case UserActionType.NEWSLETTER_SIGNUP_ERROR:
      return state;

    case UserActionType.CREATE_CART_SUCCESS:
      return updateObj(state, { cartId: action.cart.id });
    case UserActionType.UPDATE_CART_SUCCESS:
      return updateObj(state, { cartId: action.cart.id });

    case UserActionType.GET_WINECLUB_STATUS_SUCCESS:
      if (action && action.status.result && action.status.result === true) {
        return updateObj(state, {
          memberships: [{ id: 1, code: "wine-club", name: "Wine Club" }],
        });
      }

      return state;

    case UserActionType.CLEAR_CART:
      return updateObj(state, { cartId: 0 });

    case UserActionType.HAS_SUBSCRIPTION_CART:
      return updateObj(state, { wineclubCart: {} });
    case UserActionType.HAS_SUBSCRIPTION_CART_SUCCESS:
      return updateObj(state, { wineclubCart: action.cart });
    case UserActionType.HAS_SUBSCRIPTION_CART_ERROR:
      return updateObj(state, { wineclubCart: {} });

    case UserActionType.SUBSCRIPTION_SIGNUP:
      return updateObj(state, { subscriptionSignup: {} });
    case UserActionType.SUBSCRIPTION_SIGNUP_SUCCESS:
      return updateObj(state, { subscriptionSignup: action.cart });
    case UserActionType.SUBSCRIPTION_SIGNUP_ERROR:
      return updateObj(state, { subscriptionSignup: {} });

    case UserActionType.SUBSCRIPTION_REMOVE:
      return updateObj(state, { subscriptionRemove: {} });
    case UserActionType.SUBSCRIPTION_REMOVE_SUCCESS:
      return updateObj(state, { subscriptionRemove: action.payload });
    case UserActionType.SUBSCRIPTION_REMOVE_ERROR:
      return updateObj(state, { subscriptionRemove: {} });

    case UserActionType.SUBSCRIPTION_REMOVE_STATE:

      // @ts-ignore
      if(action?.payload?.switchTo !== '' && state?.subscriptions && Array.isArray(state.subscriptions)){
        // @ts-ignore
        const found_subs =  state.subscriptions.filter((item:any) => item.id !== action.payload.subId);
        return {...state, ...{subscriptions:found_subs, subscriptionRemoveState: {status:'SWITCHING', switchTo:`${action?.payload?.switchTo}`}}};
      }

      return updateObj(state, { subscriptionRemoveState: {} });

    case UserActionType.SUBSCRIPTION_REMOVE_STATE_SUCCESS:
      return state;

    case UserActionType.SUBSCRIPTION_REMOVE_STATE_ERROR:
      return updateObj(state, { subscriptionRemoveState: {} });

    case UserActionType.SUBSCRIPTION_PAUSE:
      return updateObj(state, { subscriptionPause: {} });
    case UserActionType.SUBSCRIPTION_PAUSE_SUCCESS:
      return updateObj(state, { subscriptionPause: action.payload });
    case UserActionType.SUBSCRIPTION_PAUSE_ERROR:
      return updateObj(state, { subscriptionPause: {} });

    case UserActionType.CHANGE_LANGUAGE:
      return updateObj(state, { language: "en" });
    case UserActionType.CHANGE_LANGUAGE_SUCCESS:
      return updateObj(state, { language: action.payload });
    case UserActionType.CHANGE_LANGUAGE_ERROR:
      return updateObj(state, { language: "en" });

    case UserActionType.UPDATE_PREFERRED_LANGUAGE:
      return state;
    case UserActionType.UPDATE_PREFERRED_LANGUAGE_SUCCESS:
      return action.payload;
    case UserActionType.UPDATE_PREFERRED_LANGUAGE_ERROR:
      return state;

    case UserActionType.UPDATE_REFERRAL:
      return updateObj(state, { referral: {} });
    case UserActionType.UPDATE_REFERRAL_SUCCESS:
      return updateObj(state, { referral: action.payload });
    case UserActionType.UPDATE_REFERRAL_ERROR:
      return updateObj(state, { referral: action.payload });
    
    case UserActionType.USER_SET_SAVE_CARDS:
      return state;
    case UserActionType.USER_SET_SAVE_CARDS_SUCCESS:
      console.log("action.payload",action.payload);
      return {...state, saveCardForFutureUse: action.payload.saveCardForFutureUse};
    case UserActionType.USER_SET_SAVE_CARDS_ERROR:
      return state;

    case CandideMemberActionType.VERIFY_CANDIDE_TICKET_SUCCESS:

      // replace membership in state
      const memberships =
        "memberships" in state
          ? state.memberships
              ?.filter((m) => m.code !== action.membership.code)
              .concat([action.membership])
          : [action.membership];

      return {
        ...state,
        memberships,
      };

    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(UserActionType.USER_LOGIN, userLogin);
  yield takeLatest(UserActionType.USER_REGISTER, userRegister);
  yield takeLatest(UserActionType.USER_VERIFY, userVerify);
  yield takeLatest(UserActionType.USER_UPDATE, userUpdate);
  yield takeLatest(UserActionType.USER_LOGOUT, userLogout);
  yield takeLatest(UserActionType.UPDATE_PASSWORD, updatePassword);
  yield takeLatest(UserActionType.PASSWORD_FORGOT, passwordForgot);
  yield takeLatest(UserActionType.PASSWORD_RESET, passwordReset);
  yield takeLatest(UserActionType.NEWSLETTER_SIGNUP, newsletterSignup);
  yield takeLatest(UserActionType.HAS_SUBSCRIPTION_CART, hasSubscriptionCart);
  yield takeLatest(UserActionType.SUBSCRIPTION_SIGNUP, subscriptionSignup);
  yield takeLatest(UserActionType.SUBSCRIPTION_REMOVE, subscriptionRemove);
  yield takeLatest(UserActionType.SUBSCRIPTION_REMOVE_STATE, subscriptionRemoveState);
  yield takeLatest(UserActionType.SUBSCRIPTION_PAUSE, subscriptionPause);
  yield takeLatest(UserActionType.CHANGE_LANGUAGE, changeLanguage);
  yield takeLatest(
    UserActionType.UPDATE_PREFERRED_LANGUAGE,
    updatePreferredLanguage
  );
  yield takeLatest(UserActionType.UPDATE_REFERRAL, updateReferral);

  yield takeLatest(UserActionType.USER_HYDRATE_INIT, hydrateUser);
  yield takeLatest(UserActionType.USER_SET_SAVE_CARDS, setUserSaveCards);
  // Hydrate user immediately when app is loaded
  yield put({ type: UserActionType.USER_HYDRATE_INIT });
}

export function* userLogin(args: IPayloadAction<IUserLoginPayload>) {

  const body = {
    email: args.payload.email,
    password: args.payload.password,
    channelCode: args.payload.channelCode,
  };

  EventEmitter.dispatch("DATA_userLogin", args.payload.email);

  try {
    const data = yield call(ShopApi.login, body);

    yield put(onUserLoginSuccess(data.user));
    EventEmitter.dispatch("DATA_SUCCESS_userLogin", data.user);

  } catch (err) {
    yield put(onUserLoginError(err));
    EventEmitter.dispatch("DATA_ERROR_userLogin", err);
  }
}

/**
 * If we only have the user auth token in localstorage, but not the user in state,
 * fetch the user to hydrate the user state
 */
function* hydrateUser() {
  const apiTokens = new ShopApiTokens();
  const userToken = yield call([apiTokens, "getUserToken"]);

  if (!userToken) {
    return;
  }

  try {
    const res = yield call(ShopApi.hydrateUser);
    yield put(onUserHydrateSuccess(res.user));
  } catch {}
}

export function* userLogout(args: IPayloadAction<undefined>) {
  try {
    yield call(ShopApi.logout);
    yield put(onUserLogoutSuccess());
  } catch (err) {
    yield put(onUserLogoutError(err));
  }
}

export function* userRegister(args: IPayloadAction) {

  const body = {
    email: args.payload.email,
    password: args.payload.password,
    firstName: args.payload.first_name,
    lastName: args.payload.last_name,
    gender: "u",
    phoneNumber: args.payload.mobile,
    newsletterSignup: args.payload.newsletterSignup,
    channelCode: args.payload.channelCode,
    newsletter: args.payload.newsletter ? args.payload.newsletter : "",
  };

  setTimeout(()=>{
    EventEmitter.dispatch("DATA_userRegister", args.payload.email);
  },100);


  try {
    const data = yield call(ShopApi.register, body);

    setTimeout(()=>{
      EventEmitter.dispatch("DATA_SUCCESS_userRegister", {
        id: data.user.id,
        email: args.payload.email,
        password: args.payload.password,
        firstName: args.payload.first_name,
        lastName: args.payload.last_name,
        gender: "u",
        phoneNumber: args.payload.phoneNumber,
      });
    },300);


    yield put(onUserRegisterSuccess(data.user));

  } catch (err) {
    // error
    yield put(onUserRegisterError(err));
    EventEmitter.dispatch("DATA_ERROR_userRegister", err);
  }
}

export function* userVerify(args: IPayloadAction) {

  const channelCode = args.payload.channelCode;
  const userId = args.payload.id;

  try {
    const data = yield call(ShopApi.getUser, userId, channelCode);

    yield put(onUserVerifySuccess(data.user));
    EventEmitter.dispatch("DATA_SUCCESS_userVerify", data.user);
  } catch (err) {
    // error
    yield put(onUserVerifyError(err));
  }
}

export function* setUserSaveCards(args: IPayloadAction) {

  const { userId, saveCardForFutureUse } = args.payload;

  try {
    const data = yield call(ShopApi.setUserSaveCards, userId, saveCardForFutureUse);

    yield put(onSetSaveCardsSuccess(data.user));
  } catch (err) {
    // error
    yield put(onSetSaveCardsError(err));
  }
}

export function* userUpdate(args: IPayloadAction) {

  const userId = args.payload.userId;

  const body = {
    firstName: args.payload.firstName,
    lastName: args.payload.lastName,
    email: args.payload.email,
    gender: args.payload.gender,
    birthDay: args.payload.birthDay,
    phoneNumber: args.payload.phoneNumber,
    subscribedToNewsletter: args.payload.subscribedToNewsletter,
  };

  EventEmitter.dispatch("DATA_userUpdate", args.payload);

  try {
    const data = yield call(ShopApi.updateUser, userId, body);

    yield put(onUserUpdateSuccess(data.user));
    EventEmitter.dispatch("DATA_SUCCESS_userUpdate", data.user);
  } catch (err) {
    // error
    yield put(onUserUpdateError(err));
    EventEmitter.dispatch("DATA_ERROR_userUpdate", err);
  }
}

export function* updatePassword(args: IPayloadAction) {
  const userId = args.payload.userId;
  const body = {
    password: args.payload.password,
    newPassword: args.payload.newPassword,
    channelCode: args.payload.channelCode,
  };

  EventEmitter.dispatch("DATA_updatePassword");
  try {
    const data = yield call(ShopApi.updateUserPassword, userId, body);
    yield put(onUpdatePasswordSuccess(data.user));
    EventEmitter.dispatch("DATA_SUCCESS_updatePassword");
  } catch (err) {
    // error
    yield put(onUpdatePasswordError(err));
    EventEmitter.dispatch("DATA_ERROR_updatePassword");
  }
}


export function* passwordForgot(args: IPayloadAction) {

  const body = {
    email: args.payload.email,
  };

  EventEmitter.dispatch("DATA_passwordForgot");
  try {
    const data = yield call(ShopApi.requestForgotPassword, body);
    yield put(onPasswordForgotSuccess(data));
    EventEmitter.dispatch("DATA_SUCCESS_passwordForgot", data);
  } catch (err) {
    // error
    yield put(onPasswordForgotError(err));
    EventEmitter.dispatch("DATA_ERROR_passwordForgot", err);
  }
}

export function* passwordReset(args: IPayloadAction) {

  const body = {
    email: args.payload.email,
    token: args.payload.token,
    password: args.payload.password,
  };


  EventEmitter.dispatch("DATA_passwordReset");
  try {
    const data = yield call(ShopApi.resetPassword, body);

    yield put(onPasswordResetSuccess(data.user));
    EventEmitter.dispatch("DATA_SUCCESS_passwordReset", data.user);

  } catch (err) {
    // error
    yield put(onPasswordResetError(err));
    EventEmitter.dispatch("DATA_ERROR_passwordReset");
  }
}

export function* newsletterSignup(args: IPayloadAction) {

  const userId = args.payload.userId;
  const status = args.payload.status;

  EventEmitter.dispatch("DATA_newsletterSignup", args.payload.userId);
  try {
    // Call our request helper (see 'utils/request')
    const data = yield call(ShopApi.updateUserNewsletterSubscription, userId, status);
    EventEmitter.dispatch("DATA_SUCCESS_newsletterSignup", data);
    yield put(onNewsletterSignupSuccess(data.user));
  } catch (err) {
    // error
    yield put(onNewsletterSignupError(err));
    EventEmitter.dispatch("DATA_ERROR_newsletterSignup", err);
  }
}

export function* hasSubscriptionCart(args: IPayloadAction) {

  try {
    const data = yield call(
      ShopApi.getSubscriptionCart,
      args.payload.custId,
      args.payload.subId
    );
    yield put(onHasSubscriptionCartSuccess(data.cart));
  } catch (err) {
    yield put(onHasSubscriptionCartError(err));
  }
}

export function* subscriptionSignup(args: IPayloadAction) {

  try {
    const data = yield call(
      ShopApi.createSubscriptionSignup,
      args.payload.custId,
      args.payload.subId,
      args.payload.typeSlug,
    );
    yield put(onSubscriptionSignupSuccess(data));
  } catch (err) {
    yield put(onSubscriptionSignupError(err));
  }
}

export function* subscriptionRemove(args: IPayloadAction) {

  try {
    const data = yield call(
      ShopApi.updateSubscriptionRemoveUser,
      args.payload.custId,
      args.payload.subId
    );
    yield put(onSubscriptionRemoveSuccess(data));
  } catch (err) {
    yield put(onSubscriptionRemoveError(err));
  }
}

export function* subscriptionRemoveState(args: IPayloadAction) {
  try {
    const data:any = {};
    yield put(onSubscriptionRemoveStateSuccess(data));
  } catch (err) {
    yield put(onSubscriptionRemoveStateError(err));
  }
}

export function* subscriptionPause(args: IPayloadAction) {

  try {
    const data = yield call(
      ShopApi.updateSubscriptionPauseUser,
      args.payload.custId,
      args.payload.subId
    );
    yield put(onSubscriptionPauseSuccess(data));
  } catch (err) {
    yield put(onSubscriptionPauseError(err));
  }
}

export function* changeLanguage(args: IPayloadAction) {
  try {
    yield delay(20);
    yield put(onChangeLanguageSuccess(args.payload));
  } catch (err) {
    yield put(onChangeLanguageError(err));
  }
}

export function* updatePreferredLanguage(args: IPayloadAction) {

  const userId = args.payload.custId;
  const language = args.payload.language;

  try {
    const data = yield call(ShopApi.updateUserPreferredLanguage, userId, language);
    yield put(onUpdatePreferredLanguageSuccess(data.user));
  } catch (err) {
    yield put(onUpdatePreferredLanguageError(err));
  }
}

export function* updateReferral(args: IPayloadAction) {

  const userId = args.payload.custId;
  const referrer = args.payload.referrer;

  try {
    const data = yield call(ShopApi.updateUserReferral, userId, referrer);
    yield put(onUpdateReferralSuccess(data));
  } catch (err) {
    yield put(onUpdateReferralError(err));
  }
}

// SELECTORS
const getThisUser = (state: any) => state.user;
export const makeSelectUser = createSelector([getThisUser], (user) => {
  return user;
});
