/* configure some stuff */
import LogRocket from 'logrocket';
import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "../redux/rootReducer";
import rootSaga from "../redux/rootSaga";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

//  Returns the store instance
// It can  also take initialState argument when provided
const configureStore = persistentState => {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  return {
    ...createStore(
      rootReducer,
      persistentState,
      composeEnhancers(applyMiddleware(
        sagaMiddleware
      ))
      //composeEnhancers(applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware()))
    ),
    runSaga: sagaMiddleware.run(rootSaga)
  };
};

export default configureStore;
