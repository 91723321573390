import { call, put, takeLatest, takeEvery, throttle } from "redux-saga/effects";
import { createSelector } from "reselect";
import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";
import { updateObj } from "../../helpers/gen";
import { ApiErrorResponse, ShopApi } from "./api";
import { getThisSiteChannel } from "./Site";
import { REDEEM_EGGCARD_SUCCESS } from "./EggCard";
import { DataStatus } from "src/interfaces/iRedux";
import { gtmTrackGiftOption } from '../../GoogleDataLayer/GoogleDataLayer';


const initialState = fromJS({});

// user login
// create cart
export const CREATE_CART = "app/App/CREATE_CART";
export const CREATE_CART_SUCCESS = "app/App/CREATE_CART_SUCCESS";
export const CREATE_CART_ERROR = "app/App/CREATE_CART_ERROR";

// clear cart
export const CLEAR_CART = "app/App/CLEAR_CART";
export const CLEAR_CART_SUCCESS = "app/App/CLEAR_CART_SUCCESS";
export const CLEAR_CART_ERROR = "app/App/CLEAR_CART_ERROR";

// get cart
export const GET_CART = "app/App/GET_CART";
export const GET_CART_SUCCESS = "app/App/GET_CART_SUCCESS";
export const GET_CART_ERROR = "app/App/GET_CART_ERROR";

// get cart
export const GET_SUBSCRIPTION_CART = "app/App/GET_SUBSCRIPTION_CART";
export const GET_SUBSCRIPTION_CART_SUCCESS = "app/App/GET_SUBSCRIPTION_CART_SUCCESS";
export const GET_SUBSCRIPTION_CART_ERROR = "app/App/GET_SUBSCRIPTION_CART_ERROR";

// update cart
export const UPDATE_CART = "app/App/UPDATE_CART";
export const UPDATE_CART_SUCCESS = "app/App/UPDATE_CART_SUCCESS";
export const UPDATE_CART_ERROR = "app/App/UPDATE_CART_ERROR";

// add to cart
export const ADD_TO_CART = "app/App/ADD_TO_CART";
export const ADD_TO_CART_SUCCESS = "app/App/ADD_TO_CART_SUCCESS";
export const ADD_TO_CART_ERROR = "app/App/ADD_TO_CART_ERROR";

// remove from cart
export const REMOVE_FROM_CART = "app/App/REMOVE_FROM_CART";
export const REMOVE_FROM_CART_SUCCESS = "app/App/REMOVE_FROM_CART_SUCCESS";
export const REMOVE_FROM_CART_ERROR = "app/App/REMOVE_FROM_CART_ERROR";

// update cart quantity
export const UPDATE_CART_QUANTITY = "app/App/UPDATE_CART_QUANTITY";
export const UPDATE_CART_QUANTITY_SUCCESS = "app/App/UPDATE_CART_QUANTITY_SUCCESS";
export const UPDATE_CART_QUANTITY_ERROR = "app/App/UPDATE_CART_QUANTITY_ERROR";

// add checkout address to cart
export const ADD_CHECKOUT_ADDRESS = "app/App/ADD_CHECKOUT_ADDRESS";
export const ADD_CHECKOUT_ADDRESS_SUCCESS = "app/App/ADD_CHECKOUT_ADDRESS_SUCCESS";
export const ADD_CHECKOUT_ADDRESS_ERROR = "app/App/ADD_CHECKOUT_ADDRESS_ERROR";

// add shipping method
export const ADD_SHIPPING_METHOD = "app/App/ADD_SHIPPING_METHOD";
export const ADD_SHIPPING_METHOD_SUCCESS = "app/App/ADD_SHIPPING_METHOD_SUCCESS";
export const ADD_SHIPPING_METHOD_ERROR = "app/App/ADD_SHIPPING_METHOD_ERROR";

// add payment method
export const ADD_PAYMENT_METHOD = "app/App/ADD_PAYMENT_METHOD";
export const ADD_PAYMENT_METHOD_SUCCESS = "app/App/ADD_PAYMENT_METHOD_SUCCESS";
export const ADD_PAYMENT_METHOD_ERROR = "app/App/ADD_PAYMENT_METHOD_ERROR";

// add payment method
export const SET_FOR_COLLECTION = "app/App/SET_FOR_COLLECTION";
export const SET_FOR_COLLECTION_SUCCESS = "app/App/SET_FOR_COLLECTION_SUCCESS";
export const SET_FOR_COLLECTION_ERROR = "app/App/SET_FOR_COLLECTION_ERROR";

// user updates
const USER_LOGOUT = "app/App/USER_LOGOUT";
const USER_VERIFY_ERROR = "app/App/USER_VERIFY_ERROR";

const PAYMENT_PROCESS = "app/App/PAYMENT_PROCESS";
const PAYMENT_PROCESS_SUCCESS = "app/App/PAYMENT_PROCESS_SUCCESS";
const PAYMENT_PROCESS_ERROR = "app/App/PAYMENT_PROCESS_ERROR";

const PAYMENT_RESET = "app/App/PAYMENT_RESET";

// user campaign login
const USER_CAMPAIGN_LOGIN = "app/App/USER_CAMPAIGN_LOGIN";
const USER_CAMPAIGN_LOGIN_SUCCESS = "app/App/USER_CAMPAIGN_LOGIN_SUCCESS";
const USER_CAMPAIGN_LOGIN_ERROR = "app/App/USER_CAMPAIGN_LOGIN_ERROR";

const USER_CAMPAIGN_VERIFY = "app/App/USER_CAMPAIGN_VERIFY";
const USER_CAMPAIGN_VERIFY_SUCCESS = "app/App/USER_CAMPAIGN_VERIFY_SUCCESS";
const USER_CAMPAIGN_VERIFY_ERROR = "app/App/USER_CAMPAIGN_VERIFY_ERROR";

const REDEEM_VOUCHER = "app/App/REDEEM_VOUCHER";
const REDEEM_VOUCHER_SUCCESS = "app/App/REDEEM_VOUCHER_SUCCESS";
const REDEEM_VOUCHER_ERROR = "app/App/REDEEM_VOUCHER_ERROR";

// link referrer
export const LINK_REFERRER = "app/App/LINK_REFERRER";
export const LINK_REFERRER_SUCCESS = "app/App/LINK_REFERRER_SUCCESS";
export const LINK_REFERRER_ERROR = "app/App/LINK_REFERRER_ERROR";

// re-order
export const RE_ORDER = "app/App/RE_ORDER";
export const RE_ORDER_SUCCESS = "app/App/RE_ORDER_SUCCESS";
export const RE_ORDER_ERROR = "app/App/RE_ORDER_ERROR";

// Delivery dates
const GET_DELIVERY_DATES = "app/App/GET_DELIVERY_DATES";
const GET_DELIVERY_DATES_SUCCESS = "app/App/GET_DELIVERY_DATES_SUCCESS";
const GET_DELIVERY_DATES_ERROR = "app/App/GET_DELIVERY_DATES_ERROR";

// Set Delivery date
const SET_DELIVERY_DATE = "app/App/SET_DELIVERY_DATE";
const SET_DELIVERY_DATE_SUCCESS = "app/App/SET_DELIVERY_DATE_SUCCESS";
const SET_DELIVERY_DATE_ERROR = "app/App/SET_DELIVERY_DATE_ERROR";

// Enquiry Checkout
export const ENQUIRY_CHECKOUT = "app/App/ENQUIRY_CHECKOUT";
export const ENQUIRY_CHECKOUT_SUCCESS = "app/App/ENQUIRY_CHECKOUT_SUCCESS";
export const ENQUIRY_CHECKOUT_ERROR = "app/App/ENQUIRY_CHECKOUT_ERROR";

// Promo Checkout
const PROMO_CHECKOUT = "app/App/PROMO_CHECKOUT";
const PROMO_CHECKOUT_SUCCESS = "app/App/PROMO_CHECKOUT_SUCCESS";
const PROMO_CHECKOUT_ERROR = "app/App/PROMO_CHECKOUT_ERROR";

// Set Tax Field date
const SET_TAX_FIELD = "app/App/SET_TAX_FIELD";
const SET_TAX_FIELD_SUCCESS = "app/App/SET_TAX_FIELD_SUCCESS";
const SET_TAX_FIELD_ERROR = "app/App/SET_TAX_FIELD_ERROR";

const CONFIRM_AGE = "app/App/CONFIRM_AGE";
const CONFIRM_AGE_SUCCESS = "app/App/CONFIRM_AGE_SUCCESS";
const CONFIRM_AGE_ERROR = "app/App/CONFIRM_AGE_ERROR";

export const SET_CART_DELIVERY_AREA = "app/App/SET_CART_DELIVERY_AREA";
export const SET_CART_DELIVERY_AREA_SUCCESS = "app/App/SET_CART_DELIVERY_AREA_SUCCESS";
export const SET_CART_DELIVERY_AREA_ERROR = "app/App/SET_CART_DELIVERY_AREA_ERROR";

// actions
// CREATE CART (sylius)
export function onCreateCart(payload) {
  return {
    type: CREATE_CART,
    payload
  };
}

export function onCreateCartSuccess(cart) {
  return {
    type: CREATE_CART_SUCCESS,
    cart
  };
}

export function onCreateCartError(error) {
  return {
    type: CREATE_CART_ERROR,
    error
  };
}

// CLEAR CART
export function onClearCart(payload) {
  return {
    type: CLEAR_CART,
    payload
  };
}

export function onClearCartSuccess(cart) {
  return {
    type: CLEAR_CART_SUCCESS,
    cart
  };
}

export function onClearCartError(error) {
  return {
    type: CLEAR_CART_ERROR,
    error
  };
}

// GET CART
export function onGetCart(payload) {
  return {
    type: GET_CART,
    payload
  };
}

export function onGetCartSuccess(cart) {
  return {
    type: GET_CART_SUCCESS,
    cart
  };
}

export function onGetCartError(error) {
  return {
    type: GET_CART_ERROR,
    error
  };
}

// GET SUBSCRIPTION CART
export function onGetSubscriptionCart(payload) {
  return {
    type: GET_SUBSCRIPTION_CART,
    payload
  };
}

export function onGetSubscriptionCartSuccess(cart) {
  return {
    type: GET_SUBSCRIPTION_CART_SUCCESS,
    cart
  };
}

export function onGetSubscriptionCartError(error) {
  return {
    type: GET_SUBSCRIPTION_CART_ERROR,
    error
  };
}

// UPDATE CART
export function onUpdateCart(payload) {
  return {
    type: UPDATE_CART,
    payload
  };
}

export function onUpdateCartSuccess(cart) {
  return {
    type: UPDATE_CART_SUCCESS,
    cart
  };
}

export function onUpdateCartError(error) {
  return {
    type: UPDATE_CART_ERROR,
    error
  };
}

// ADD TO CART
export function onAddToCart(payload) {
  return {
    type: ADD_TO_CART,
    payload
  };
}

export function onAddToCartSuccess(payload) {
  return {
    type: ADD_TO_CART_SUCCESS,
    payload
  };
}

export function onAddToCartError(error) {
  return {
    type: ADD_TO_CART_ERROR,
    error
  };
}

// REMOVE FROM CART
export function onRemoveFromCart(payload) {
  return {
    type: REMOVE_FROM_CART,
    payload
  };
}

export function onRemoveFromCartSuccess(payload) {
  return {
    type: REMOVE_FROM_CART_SUCCESS,
    payload
  };
}

export function onRemoveFromCartError(error) {
  return {
    type: REMOVE_FROM_CART_ERROR,
    error
  };
}

// onUpdateQuantity
export function onUpdateQuantity(payload) {
  return {
    type: UPDATE_CART_QUANTITY,
    payload
  };
}

export function onUpdateQuantitySuccess(payload) {
  return {
    type: UPDATE_CART_QUANTITY_SUCCESS,
    payload
  };
}

export function onUpdateQuantityError(error) {
  return {
    type: UPDATE_CART_QUANTITY_ERROR,
    error
  };
}

// ADD_CHECKOUT_ADDRESS
export function onAddCheckoutAddress(payload) {
  return {
    type: ADD_CHECKOUT_ADDRESS,
    payload
  };
}

export function onAddCheckoutAddressSuccess(payload) {
  return {
    type: ADD_CHECKOUT_ADDRESS_SUCCESS,
    payload
  };
}

export function onAddCheckoutAddressError(error) {
  return {
    type: ADD_CHECKOUT_ADDRESS_ERROR,
    error
  };
}

// ADD_SHIPPING_METHOD
export function onAddShippingMethod(payload) {
  return {
    type: ADD_SHIPPING_METHOD,
    payload
  };
}

export function onAddShippingMethodSuccess(payload) {
  return {
    type: ADD_SHIPPING_METHOD_SUCCESS,
    payload
  };
}

export function onAddShippingMethodError(error) {
  return {
    type: ADD_SHIPPING_METHOD_ERROR,
    error
  };
}

// ADD_PAYMENT_METHOD
export function onAddPaymentMethod(payload) {
  return {
    type: ADD_PAYMENT_METHOD,
    payload
  };
}

export function onAddPaymentMethodSuccess(payload) {
  return {
    type: ADD_PAYMENT_METHOD_SUCCESS,
    payload
  };
}

export function onAddPaymentMethodError(error) {
  return {
    type: ADD_PAYMENT_METHOD_ERROR,
    error
  };
}

// SET_FOR_COLLECTION
export function onSetForCollection(payload) {
  return {
    type: SET_FOR_COLLECTION,
    payload
  };
}

export function onSetForCollectionSuccess(payload) {
  return {
    type: SET_FOR_COLLECTION_SUCCESS,
    payload
  };
}

export function onSetForCollectionError(error) {
  return {
    type: SET_FOR_COLLECTION_ERROR,
    error
  };
}

// REDEEM_VOUCHER
export function onRedeemVoucher(payload) {
  return {
    type: REDEEM_VOUCHER,
    payload
  };
}

export function onRedeemVoucherSuccess(cart) {
  return {
    type: REDEEM_VOUCHER_SUCCESS,
    cart
  };
}

export function onRedeemVoucherError(error) {
  return {
    type: REDEEM_VOUCHER_ERROR,
    error
  };
}

// LINK_REFERRER
export function onLinkReferrer(payload) {
  return {
    type: LINK_REFERRER,
    payload
  };
}

export function onLinkReferrerSuccess(cart) {
  return {
    type: LINK_REFERRER_SUCCESS,
    cart
  };
}

export function onLinkReferrerError(error) {
  return {
    type: LINK_REFERRER_ERROR,
    error
  };
}

// RE_ORDER
export function onReOrder(payload) {
  return {
    type: RE_ORDER,
    payload
  };
}

export function onReOrderSuccess(cart) {
  return {
    type: RE_ORDER_SUCCESS,
    cart
  };
}

export function onReOrderError(error) {
  return {
    type: RE_ORDER_ERROR,
    error
  };
}

// GET_DELIVERY_DATES
export function onGetDeliveryDates(payload) {
  return {
    type: GET_DELIVERY_DATES,
    payload
  };
}

export function onGetDeliveryDatesSuccess(cart) {
  return {
    type: GET_DELIVERY_DATES_SUCCESS,
    cart
  };
}

export function onGetDeliveryDatesError(error) {
  return {
    type: GET_DELIVERY_DATES_ERROR,
    error
  };
}

// SET_DELIVERY_DATE
export function onSetDeliveryDate(payload) {
  return {
    type: SET_DELIVERY_DATE,
    payload
  };
}

export function onSetDeliveryDateSuccess(cart) {
  return {
    type: SET_DELIVERY_DATE_SUCCESS,
    cart
  };
}

export function onSetDeliveryDateError(error) {
  return {
    type: SET_DELIVERY_DATE_ERROR,
    error
  };
}

// SET_TAX_FIELD
export function onSetTaxField(payload) {
  return {
    type: SET_TAX_FIELD,
    payload
  };
}

export function onSetTaxFieldSuccess(cart) {
  return {
    type: SET_TAX_FIELD_SUCCESS,
    cart
  };
}

export function onSetTaxFieldError(error) {
  return {
    type: SET_TAX_FIELD_ERROR,
    error
  };
}

// ENQUIRY_CHECKOUT
export function onEnquiryCheckout(payload) {
  return {
    type: ENQUIRY_CHECKOUT,
    payload
  };
}

export function onEnquiryCheckoutSuccess(payload) {
  return {
    type: ENQUIRY_CHECKOUT_SUCCESS,
    payload
  };
}

export function onEnquiryCheckoutError(payload) {
  return {
    type: ENQUIRY_CHECKOUT_ERROR,
    payload
  };
}

// PROMO_CHECKOUT
export function onPromoCheckout(payload) {
  return {
    type: PROMO_CHECKOUT,
    payload
  };
}

export function onPromoCheckoutSuccess(payload) {
  return {
    type: PROMO_CHECKOUT_SUCCESS,
    payload
  };
}

export function onPromoCheckoutError(payload) {
  return {
    type: PROMO_CHECKOUT_ERROR,
    payload
  };
}

// CONFIRM_AGE
export function onConfirmAge(payload) {
  return {
    type: CONFIRM_AGE,
    payload
  };
}

export function onConfirmAgeSuccess(payload) {
  return {
    type: CONFIRM_AGE_SUCCESS,
    payload
  };
}

export function onConfirmAgeError(payload) {
  return {
    type: CONFIRM_AGE_ERROR,
    payload
  };
}

// SET_CART_DELIVERY_AREA
export function onSetCartDeliveryArea(payload) {
  return {
    type: SET_CART_DELIVERY_AREA,
    payload
  };
}

export function onSetCartDeliveryAreaSuccess(payload) {
  return {
    type: SET_CART_DELIVERY_AREA_SUCCESS,
    payload
  };
}

export function onSetCartDeliveryAreaError(payload) {
  return {
    type: SET_CART_DELIVERY_AREA_ERROR,
    payload
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    // create cart
    case CREATE_CART:

      return {...state,...{
        status:DataStatus.LOADING,
        message:'Creating cart',
        query:action.payload
      }};

      const creating_cart = state ? state : {};
      creating_cart.is_updating = true;
      creating_cart.notice_message = "Creating a new cart";
      creating_cart.notice_payload = action.payload;

      return creating_cart;
      return state;
    case CREATE_CART_SUCCESS:
      //update user.cartId
      return {...action.cart,...{
        status:DataStatus.OK,
        message:''
      }};

      return action.cart;
    case CREATE_CART_ERROR:
      return {...state,...{
        status:DataStatus.ERROR,
        error:action.error
      }};

    // clear cart
    case CLEAR_CART:
      return {};
    case CLEAR_CART_SUCCESS:
      return state;
    case CLEAR_CART_ERROR:
      return state;

    // get cart
    case GET_CART:
      return {...state, ...{
        status:DataStatus.LOADING,
        message:'Loading cart',
        query:action.payload
      }};

    case GET_CART_SUCCESS:
      return {...action.cart,...{
        status:DataStatus.OK
      }};
    case GET_CART_ERROR:
      return {
        status:DataStatus.ERROR,
        error:action.error
      };
    case GET_SUBSCRIPTION_CART:
      return {
        status:DataStatus.LOADING,
        message:'Loading Subscription Cart',
        query:action.payload
      };
    case GET_SUBSCRIPTION_CART_SUCCESS:
      return {...action.cart, ...{
        status:DataStatus.OK
      }};
    case GET_SUBSCRIPTION_CART_ERROR:
      return {...state,...{
        status:DataStatus.ERROR,
        error:action.cart.error
      }};

    // update cart
    case UPDATE_CART:
      return state;
    case UPDATE_CART_SUCCESS:
      // link anonymous cart to user
      return action.cart;
    case UPDATE_CART_ERROR:
      return state;

    // add to cart
    case ADD_TO_CART:
      const adding_to_cart = state ? state : {};
      adding_to_cart.is_updating = true;
      adding_to_cart.notice_message = "Adding item to cart";
      adding_to_cart.notice_payload = action.payload.product;
      return JSON.parse(JSON.stringify(adding_to_cart));

    case ADD_TO_CART_SUCCESS:
      return action.payload;
    case ADD_TO_CART_ERROR:
      const newAddState = updateObj(state, { is_updating: false });
      return newAddState;

    // remove from cart
    case REMOVE_FROM_CART:
      const removing_from_cart = state ? state : {};
      removing_from_cart.is_updating = true;
      removing_from_cart.notice_message = "Removing item from cart";
      removing_from_cart.notice_payload = action.payload.item;
      return JSON.parse(JSON.stringify(removing_from_cart));

    case REMOVE_FROM_CART_SUCCESS:
      return action.payload;
    case REMOVE_FROM_CART_ERROR:
      return state;

    // update cart quantity
    case UPDATE_CART_QUANTITY:
      const updating_cart = state ? state : {};
      updating_cart.is_updating = true;
      updating_cart.notice_message = "Updating item in cart";
      updating_cart.notice_payload = action.payload.product;
      return {...updating_cart};
    case UPDATE_CART_QUANTITY_SUCCESS:
      return action.payload;
    case UPDATE_CART_QUANTITY_ERROR:
      const newState = updateObj(state, { is_updating: false });
      return newState;

    // add delivery address to cart
    case ADD_CHECKOUT_ADDRESS:
      return state;
    case ADD_CHECKOUT_ADDRESS_SUCCESS:
      return action.payload;
    case ADD_CHECKOUT_ADDRESS_ERROR:
      return state;
    // add delivery address to cart
    case ADD_SHIPPING_METHOD:
      return state;
    case ADD_SHIPPING_METHOD_SUCCESS:
      return action.payload;
    case ADD_SHIPPING_METHOD_ERROR:
      return state;

    // add payment address to cart
    case ADD_PAYMENT_METHOD:
      return state;
    case ADD_PAYMENT_METHOD_SUCCESS:
      return action.payload;
    case ADD_PAYMENT_METHOD_ERROR:
      return state;

    //SET_FOR_COLLECTION
    case SET_FOR_COLLECTION:
      return state;
    case SET_FOR_COLLECTION_SUCCESS:
      return action.payload;
    case SET_FOR_COLLECTION_ERROR:
      return state;

    //REDEEM_VOUCHER
    case REDEEM_VOUCHER:
      return state;
    case REDEEM_VOUCHER_SUCCESS:
      return action.cart;
    case REDEEM_VOUCHER_ERROR:
      return state;

    //LINK_REFERRER
    case LINK_REFERRER:
      return state;
    case LINK_REFERRER_SUCCESS:
      return action.cart;
    case LINK_REFERRER_ERROR:
      return state;

    //RE_ORDER
    case RE_ORDER:
      return state;
    case RE_ORDER_SUCCESS:
      return action.cart;
    case RE_ORDER_ERROR:
      return state;

    //GET_DELIVERY_DATES
    case GET_DELIVERY_DATES:
      return state;
    case GET_DELIVERY_DATES_SUCCESS:
      return updateObj(state, { deliveryDates: action.cart });
    case GET_DELIVERY_DATES_ERROR:
      return state;

    //SET_DELIVERY_DATE
    case SET_DELIVERY_DATE:
      return state;
    case SET_DELIVERY_DATE_SUCCESS:
      return { ...state, ...action.cart };
    case SET_DELIVERY_DATE_ERROR:
      const err = {
        deliveryDateError: action.error
      };
      return { ...state, ...err };

      //SET_TAX_FIELD
    case SET_TAX_FIELD:
      return state;
    case SET_TAX_FIELD_SUCCESS:
      return { ...state, ...action.cart };
    case SET_TAX_FIELD_ERROR:
      const errTaxField = {
        taxFieldError: action.error
      };
      return { ...state, ...errTaxField };

    //ENQUIRY_CHECKOUT
    case ENQUIRY_CHECKOUT:
      return state;
    case ENQUIRY_CHECKOUT_SUCCESS:
      return { ...state, ...action.payload };
    case ENQUIRY_CHECKOUT_ERROR:
      return state;

    //PROMO_CHECKOUT
    case PROMO_CHECKOUT:
      return state;
    case PROMO_CHECKOUT_SUCCESS:
      return { ...state, ...action.payload };
    case PROMO_CHECKOUT_ERROR:
      return state;

    //CONFIRM_AGE
    case CONFIRM_AGE:
      return {...state, ...{error:{}}};
    case CONFIRM_AGE_SUCCESS:
      return action.payload ;
    case CONFIRM_AGE_ERROR:
      return {...state, ...{error:action.payload}};

    //SET_CART_DELIVERY_AREA
    case SET_CART_DELIVERY_AREA:
      return { ...state, ...{query:action.payload }};
    case SET_CART_DELIVERY_AREA_SUCCESS:
      return { ...state, ...action.payload };
    case SET_CART_DELIVERY_AREA_ERROR:
      return { ...state, ...{error:action.payload} };

    case USER_LOGOUT:
      return {};

    case USER_VERIFY_ERROR:
      return {};

    case USER_CAMPAIGN_LOGIN:
      return {};

    case USER_CAMPAIGN_LOGIN_ERROR:
      return {};

    case USER_CAMPAIGN_VERIFY_ERROR:
      return {};

    // default
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(CREATE_CART, createCart);
  yield takeLatest(GET_CART, getCart);
  yield takeLatest(GET_SUBSCRIPTION_CART, getSubscriptionCart);
  yield throttle(1500, UPDATE_CART, updateCart);
  yield throttle(500, ADD_TO_CART, addToCart);
  yield throttle(500, REMOVE_FROM_CART, removeFromCart);
  yield throttle(500, UPDATE_CART_QUANTITY, updateCartQuantity);
  //yield throttle(500, ADD_CHECKOUT_ADDRESS, addCheckoutAddress);
  yield throttle(500, ADD_CHECKOUT_ADDRESS, addCheckoutAddress);
  yield takeLatest(ADD_SHIPPING_METHOD, addShippingMethod);
  //yield takeEvery(ADD_SHIPPING_METHOD, addShippingMethod);
  yield takeLatest(ADD_PAYMENT_METHOD, addPaymentMethod);
  //yield takeEvery(ADD_PAYMENT_METHOD, addPaymentMethod);
  yield takeLatest(SET_FOR_COLLECTION, setForCollection);
  yield takeLatest(REDEEM_VOUCHER, redeemVoucher);
  yield takeLatest(LINK_REFERRER, linkReferrer);
  yield takeLatest(RE_ORDER, reOrder);
  yield takeLatest(GET_DELIVERY_DATES, getDeliveryDates);
  yield takeLatest(SET_DELIVERY_DATE, setDeliveryDate);
  yield takeEvery(SET_TAX_FIELD, setTaxField);
  yield takeLatest(ENQUIRY_CHECKOUT, enquiryCheckout);
  yield takeLatest(PROMO_CHECKOUT, promoCheckout);
  yield takeLatest(CONFIRM_AGE, confirmAge);
  yield takeLatest(SET_CART_DELIVERY_AREA, setDeliveryArea);
}

export function* createCart(args) {

  let body = {
    userId: args.payload.user?.id || undefined,
    channel: args.payload.channel,
    referrer: args.payload.referrer,
    shippingAddress:null
  };

  try {
    const data = yield call(ShopApi.createCart, body);
    const cart = data.cart;

    yield put(onCreateCartSuccess(cart));
  } catch (err) {
    yield put(onCreateCartError(err));
  }

}

export function* getCart(args) {

  try {

    const id = args.payload.cartId;

    if(typeof id !== 'number'){
      //throw Error("Attempting to fetch cart without specifying an ID");
    }

    // Call our request helper (see 'utils/request')
    if(id && id > 0){
      const data = yield call(ShopApi.getCart, id);
      if(data && data.cart && data.cart.id && data.cart.id > 0){
        yield put(onGetCartSuccess(data.cart));

        setTimeout(() => {
          EventEmitter.dispatch("DATA_SUCCESS_getCart", data.cart);
        }, 10);
      } else {

        yield put(onGetCartError({}));
      }
    } else {
      yield put(onGetCartError({}));
    }

  } catch (err) {
    yield put(onGetCartError(err));
  }
}

export function* getSubscriptionCart(args) {

  try {
    const data = yield call(
      ShopApi.getSubscriptionCart,
      Number(args.payload.custId),
      Number(args.payload.subId),
    );

    yield put(onGetSubscriptionCartSuccess(data.cart));
  } catch (err) {
    yield put(onGetSubscriptionCartError(err));
  }
}

export function* updateCart(args) {
  const { cart, user } = args.payload;

  try {
    const data = yield call(ShopApi.linkCartToCustomer, cart.id, user.id);

    EventEmitter.dispatch("DATA_SUCCESS_updateCart", {
      cart: data.cart,
      user
    });

    yield put(onUpdateCartSuccess(data.cart));
  } catch (err) {
    yield put(onUpdateCartError(err));
  }
}

export function* addToCart(args) {

  const { product, qty, cart } = args.payload;

  try {

    if (!cart || !cart.id) {

      if(args.payload.channel){
        let createCartBody = {
          userId: args.payload.user?.id || undefined,
          channel: args.payload.channel,
          referrer: args.payload.referrer ? args.payload.referrer : "",
          shippingAddress:null
        };

        const cartResult = yield call(ShopApi.createCart, createCartBody);

        const body = {
          productId: product.id,
          amount: product.amount ? product.amount * 100 : null,
          quantity: qty
        };

        const data = yield call(ShopApi.createCartItem, cartResult.cart.id, body);
        yield put(onAddToCartSuccess(data.cart));
      }

    } else {
      const body = {
        productId: product.id,
        amount: product.amount ? product.amount * 100 : null,
        quantity: qty
      };
      const data = yield call(ShopApi.createCartItem, cart.id, body);
      yield put(onAddToCartSuccess(data.cart));
    }

  } catch (err) {
    if (err instanceof ApiErrorResponse) {
      EventEmitter.dispatch("SHOW_NOTIFICATION", {
        title: "Please note",
        desc: err.getMessage() ?? 'Something went wrong while adding product to cart.'
      });
    }

    yield put(onAddToCartError(err));
  }

}

export function* removeFromCart(args) {
  const { item, cart, user } = args.payload;

  EventEmitter.dispatch("DATA_removeFromCart", {
    cartId: cart.id,
    itemId: item.id
  });

  try {
    const data = yield call(ShopApi.deleteCartItem, cart.id, item.id);
    const cartRes = data.cart;

    if (!cartRes || !cartRes.items || cartRes.items.length === 0) {
      // close cart if empty
      EventEmitter.dispatch("TOGGLE_MINI_CART", false);
    }

    EventEmitter.dispatch(
      "DATA_SUCCESS_removeFromCart",
      updateObj(cartRes, { itemId: item.id })
    );

    yield put(onRemoveFromCartSuccess(cartRes));

  } catch (err) {
    yield put(onRemoveFromCartError(err));
  }
}

export function* updateCartQuantity(args) {
  const { product, qty, user, cart } = args.payload;
  const cart_id = user && user.cartId ? user.cartId : cart.id;

  const body = {
    quantity: qty
  };

  try {
    // Call our request helper (see 'utils/request')
    const data = yield call(ShopApi.updateCartItem, cart_id, product.id, body);
    yield put(onUpdateQuantitySuccess(data.cart));

  } catch (err) {

    if(err instanceof ApiErrorResponse){
      EventEmitter.dispatch("SHOW_NOTIFICATION", {
        title: "Limited Stock Available",
        desc: err.getMessage(),
      });
    }

    yield put(onUpdateQuantityError(err));
  }
}

// ADD CHECKOUT ADDRESS
export function* addCheckoutAddress(args) {

  const {
    cart,
    shippingAddress,
    differentBillingAddress,
    billingAddress,
    note,
    isGift,
    recipientName,
    recipientInstructions,
    recipientNumber
  } = args.payload;


  const cartId = cart.id;

  const body = {
    shippingAddress,
    differentBillingAddress,
    billingAddress,
    note,
    isGift,
    recipientName,
    recipientInstructions,
    recipientNumber
  };

  EventEmitter.dispatch("DATA_addCheckoutAddress", args);

  try {
    const data = yield call(ShopApi.updateCartAddress, cartId, body);

    yield put(onAddCheckoutAddressSuccess(data.cart));
    EventEmitter.dispatch("DATA_SUCCESS_addCheckoutAddress", data.cart);

    if(data.cart?.gift){
      gtmTrackGiftOption()
    }

  } catch (err) {
    // error
    yield put(onAddCheckoutAddressError(err));
  }
}

// ADD SHIPPING METHOD
export function* addShippingMethod(args) {

}

// ADD PAYMENT METHOD
export function* addPaymentMethod(args) {

  const { cart, paymentMethod } = args.payload;
  const cartId = cart.id;

  try {
    const data = yield call(ShopApi.updateCartPaymentMethod, cartId, paymentMethod);

    data.payment_method_timestamp = `${Math.floor(Date.now() / 1000)}`;

    yield put(onAddPaymentMethodSuccess(data.cart));
    EventEmitter.dispatch("DATA_SUCCESS_addPaymentMethod", data);
  } catch (err) {
    // error
    yield put(onAddPaymentMethodError(err));
    EventEmitter.dispatch("DATA_ERROR_addPaymentMethod", err);
  }
}

// SET FOR COLLECTION
export function* setForCollection(args) {

  const {
    cart,
    note,
    isGift,
    recipientName,
    recipientNumber,
    recipientInstructions
  } = args.payload;

  const cartId = cart.id;
  const body = {
    isGift,
    note,
    recipientName,
    recipientNumber,
    recipientInstructions
  };

  try {
    const data = yield call(ShopApi.updateCartForCollection, cartId, body);
    yield put(onSetForCollectionSuccess(data.cart));
  } catch (err) {
    // error
    yield put(onSetForCollectionError(err));
  }
}

// REDEEM VOUCHER
export function* redeemVoucher(args) {
  const {
    cart,
    cardNumber
  } = args.payload;

  const cartId = cart.id;

  try {
    const data = yield call(ShopApi.updateCartRedeemVoucher, cartId, cardNumber);
    yield put(onRedeemVoucherSuccess(data.cart));
  } catch (err) {
    // error
    yield put(onRedeemVoucherError(err));
  }
}

// LINK REFERRER
export function* linkReferrer(args) {
  const {
    cartId,
    referrer
  } = args.payload;

  try {
    const data = yield call(ShopApi.updateCartLinkReferrer, cartId, referrer);
    yield put(onLinkReferrerSuccess(data.cart));
  } catch (err) {
    // error
    yield put(onLinkReferrerError(err));
  }
}

// RE ORDER
export function* reOrder(args) {
  const {
    cartID,
    orderID
  } = args.payload;

  try {

    // TODO: create cart if no cartId
    if(!cartID || cartID === 0){
      let body = {
        userId: args.payload.user?.id || undefined,
        channel: args.payload.channel,
        referrer: "",
        shippingAddress:null
      };

      const cartResult = yield call(ShopApi.createCart, body);
      const data = yield call(ShopApi.updateCartReorder, cartResult.cart.id, orderID);
      yield put(onReOrderSuccess(data));
    } else {
      const data = yield call(ShopApi.updateCartReorder, cartID, orderID);
      yield put(onReOrderSuccess(data));
    }

  } catch (err) {
    yield put(onReOrderError(err));
  }
}

// GET DELIVERY DATES
export function* getDeliveryDates(args) {
  const { cartId } = args.payload;

  try {
    const data = yield call(ShopApi.getCartDeliveryDates, cartId);
    yield put(onGetDeliveryDatesSuccess(data.delivery_dates));
  } catch (err) {
    // error
    yield put(onGetDeliveryDatesError(err));
  }
}

// SET DELIVERY DATE
export function* setDeliveryDate(args) {
  const { cartId, requestedDate } = args.payload;

  try {
    const data = yield call(ShopApi.updateCartDeliveryDate, cartId, requestedDate ?? null);

    if (data.cart.requestedDeliveryDate?.length > 0) {
      yield put(onSetDeliveryDateSuccess(data.cart));
    } else {
      yield put(onSetDeliveryDateError(data.cart));
    }

  } catch (err) {
    yield put(onSetDeliveryDateError(err));
  }
}

// SET TAX
export function* setTaxField(args) {
  const {
    cartId,
    taxNumber
  } = args.payload;

  try {
    const data = yield call(ShopApi.updateCartSetTaxNumber, cartId, taxNumber);

    if (data.cart.taxNumber && data.cart.taxNumber.length > 0) {
      yield put(onSetTaxFieldSuccess(data.cart));
    } else {
      yield put(onSetTaxFieldError(data.cart));
    }


  } catch (err) {
    yield put(onSetTaxFieldError(err));
  }
}

// ENQUIRY_CHECKOUT
export function* enquiryCheckout(args) {

  const {
    cartId
  } = args.payload;

  try {
    const data = yield call(ShopApi.updateCartSetEnquired, cartId);
    yield put(onEnquiryCheckoutSuccess(data.cart));
  } catch (err) {
    yield put(onEnquiryCheckoutError(err));
  }

}

// PROMO_CHECKOUT
export function* promoCheckout(args) {

  const {
    cartId
  } = args.payload;

  try {
    const data = yield call(ShopApi.updateCartCheckPromotion, cartId);
    yield put(onPromoCheckoutSuccess(data));
  } catch (err) {
    yield put(onPromoCheckoutError(err));
  }

}

// CONFIRM_AGE
export function* confirmAge(args) {

  const {
    cartId,
    birthday
  } = args.payload;

  try {
    const data = yield call(ShopApi.confirmAge, cartId, {cartId, birthDay:birthday});

    if(data && data.cart){
      yield put(onConfirmAgeSuccess(data.cart));
    } else {
      yield put(onConfirmAgeError(data));
    }
  } catch (err) {
    yield put(onConfirmAgeError(err));
  }

}

// SET_CART_DELIVERY_AREA
export function* setDeliveryArea(args) {

  const {
    cartId,
    deliveryAreaCode
  } = args.payload;

  if(cartId && cartId > 0){
    try {
      const data = yield call(ShopApi.setCartDeliveryArea, cartId, deliveryAreaCode);
      if(data && data.cart){
        yield put(onSetCartDeliveryAreaSuccess(data.cart));
      } else {
        yield put(onSetCartDeliveryAreaError(data));
      }
    } catch (err) {
      yield put(onSetCartDeliveryAreaError(err));
    }
  }

}

// SELECTORS
const getThisCart = state => state.cart;
export const makeSelectCart = createSelector(
  [getThisSiteChannel, getThisCart],
  (channelCode, cart) => {
    if(cart && cart.channel && channelCode && channelCode === cart.channel && cart.checkoutState && cart.checkoutState !== 'completed'){

      if(cart.isSubscriptionOrder && cart.isSubscriptionOrder === true){
        if(window && window.location && window.location.pathname && `${window.location.pathname}`.indexOf('/wine-club/campaign') > -1){
          return cart;
        } else {
          return {};
        }
      } else {
        return cart;
      }

    }
    return {};
  }
);
