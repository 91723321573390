import { breakpoints } from "../../../helpers/breakpoints";

const imgWidth = `${Math.ceil(window.innerWidth / 200) * 200}`;

export const FacebookIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832757/bt-shop/svg/facebook_x0dt6h.svg`;
export const InstagramIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832754/bt-shop/svg/instagram_roy6kq.svg`;
export const TwitterIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832762/bt-shop/svg/twitter_cefpwk.svg`;
export const YoutubeIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832761/bt-shop/svg/youtube_o95pqk.svg`;

export const videoSplash = "https://res.cloudinary.com/babylon/image/upload/v1592035624/newt-shop/calendarvideo.jpg";

export const intHomeHighlights = "https://res.cloudinary.com/babylon/image/upload/v1592035624/newt-shop/featured_march_2020.jpg";
export const notFoundNewt = "https://res.cloudinary.com/babylon/image/upload/v1592035624/newt-shop/404.jpg";
export const VignamaggioBio = 'https://res.cloudinary.com/babylon/image/upload/v1592035624/newt-shop/VignamaggioBio.jpg';
export const TheNewtBio = 'https://res.cloudinary.com/babylon/image/upload/v1592035624/newt-shop/TheNewtBio.jpg';
export const BTBio = 'https://res.cloudinary.com/babylon/image/upload/v1592035624/newt-shop/BTBio.jpg';
export const visit_garden = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${imgWidth},q_auto:good,c_lfill/v1588832383/bt-shop/homePage/while_you_are_here/za-garden_jgg3ne.jpg`;

export const SiteLogo = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_300,q_auto:good,c_lfill/v1592035624/newt-shop/homePage/MOBILENEWTLOGO_yvvkhx.png`;
export const mobile_newt_banner = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints(1200)},q_auto:good,c_lfill/v1594125645/newt-shop/video/video-splash_ejzebh.jpg`;
export const ukHomeBanner = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints(1200)},q_auto:good,c_lfill/v1595947861/newt-shop/homePage/summer_2400x1200__newt_xart6b.jpg`;
export const intHomeBanner = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints(1200)},q_auto:good,c_lfill/v1595947861/newt-shop/homePage/summer_2400x1200__newt_xart6b.jpg`;
export const ukHomeHighlights = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints(1200)},q_auto:good,c_lfill/v1591178198/bt-shop/homePage/Join_our_cyder_club_bkc1ed.jpg`;
export const mobileNewtLogo = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_300,q_auto:good,c_lfill/v1592206815/newt-shop/homePage/MOBILENEWTLOGOsmall_padyxe.png`;
