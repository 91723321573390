import React, { FC, useEffect, useRef } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom';
import { environment } from "../config/environment";
import { pageView } from './pageView';
import { eeImpression } from './eeImpression';
import { eeClick } from "./eeClick";
import { eeDetail } from "./eeDetail";
import { IProduct, IProductItemView } from 'src/interfaces/iProduct';
import { makeProductItemView } from 'src/helpers/productHelper';
import { pageType } from './pageTypes';
import { eeAddToBasket } from './eeAddToBasket';
import { eeRemoveFromBasket } from './eeRemoveFromBasket';
import { eeCheckoutUserDetails } from './eeCheckoutUserDetails';
import { eeViewCheckoutBasket } from './eeViewCheckoutBasket';
import { eeCheckoutDelivery } from './eeCheckoutDelivery';
import { eeCheckoutPayment } from './eeCheckoutPayment';
import { eePurchase } from './eePurchase';
import { Clear } from './clear';
import { eventType } from './eventTypes';
import { eeCheckoutGift } from './eeCheckoutGift';
import { searchResult } from './searchResult';
import { trackingCartItemsInterface, trackingSubscriptionItem, transactionArgs } from './interfaces';
import { eeSubscriptionPurchase } from './eeSubscriptionPurchase';
import { eeSubscriptionPaymentFail } from './eeSubscriptionPaymentFail';

const dataLayerName = 'PageDataLayer';

const createDatalayerObj = (obj:any) => {
  return {
    dataLayer:obj,
    dataLayerName
  }
}

export const initializeTagManager = () =>{
  const { GTM_ID } = environment;
  const {ORIGINAL_LOCATION} = window as any;
  console.log('initializeTagManager', GTM_ID, ORIGINAL_LOCATION);

  const tagManagerArgs = {
      gtmId: GTM_ID,
      dataLayerName
  }

  TagManager.initialize(tagManagerArgs);

  if(ORIGINAL_LOCATION){
    TagManager.dataLayer(createDatalayerObj({
      'event': 'originalLocation',
      'originalLocation': ORIGINAL_LOCATION
    }));
  }

}

export const gtmHandleProductClick = (product:IProductItemView, products:any = []) => {
  TagManager.dataLayer(createDatalayerObj(Clear()));
  TagManager.dataLayer(createDatalayerObj(eeClick(product,products)));
}

export const gtmTrackNewsletterSignup = () => {
  TagManager.dataLayer(createDatalayerObj(Clear()));
  TagManager.dataLayer(createDatalayerObj({
    event:eventType.NewsletterSignUp
  }));
}

export const gtmTrackGiftOption = () => {
  TagManager.dataLayer(createDatalayerObj(Clear()));
  TagManager.dataLayer(createDatalayerObj(eeCheckoutGift()));
}

const GoogleDataLayer: FC = () => {
  const cart: any = useSelector((state: any) => state.cart);
  const user:any = useSelector((state:any) => state.user)
  const site: any = useSelector((state: any) => state.site);
  const products: any = useSelector((state: any) => state.products);
  const product: any = useSelector((state: any) => state.product);
  const stripe: any = useSelector((state:any) => state.stripe);
  const peachPayment: any = useSelector((state:any) => state.peachPayment);
  const updated:string = useSelector((state:any) => state.updated);
  const productSearch:any = useSelector((state:any) => state.productSearch);
  const subscriptions:any = useSelector((state:any) => state.subscriptions);
  const state:any = useSelector((state:any) => state);
  const location = useLocation();
  const prevCartItems = useRef(cart?.items && Array.isArray(cart?.items) && cart?.items.slice());
  const prevCart = useRef(cart);

  useEffect(() => {
    TagManager.dataLayer(createDatalayerObj(pageView({ location, user, site })));
    const cartItems = cart?.items && Array.isArray(cart.items) ? cart.items.filter((item:any) => item?.product?.isPromotionProduct !== true) : [];

    if(cartItems){
      const formattedCartProducts:trackingCartItemsInterface[] = cartItems.map((cartItem:any) => ({...makeProductItemView(cartItem.product,site,cart),quantity:cartItem.quantity}));

      if(location.pathname.indexOf('/checkout/shopping-bag') > -1){
        if(!user.id){
          TagManager.dataLayer(createDatalayerObj(eeCheckoutUserDetails(formattedCartProducts)))
        } else {
          TagManager.dataLayer(createDatalayerObj(eeViewCheckoutBasket(formattedCartProducts)))
        }
      }

      if(location.pathname.indexOf('/checkout/shipping') > -1){
        TagManager.dataLayer(createDatalayerObj(eeCheckoutDelivery(formattedCartProducts)))
      }

      if(location.pathname.indexOf('/checkout/payment') > -1){
        const paymentMethod = cart?.payments && Array.isArray(cart?.payments) && cart?.payments[0].method && cart?.payments[0].method.code && cart?.payments[0].method.name ? `${cart?.payments[0].method.code} - ${cart?.payments[0].method.name}` : 'undefined';

        TagManager.dataLayer(createDatalayerObj(eeCheckoutPayment(formattedCartProducts, `${paymentMethod}`)))
      }

    }
  },[location]);

  useEffect(() => {
    if(updated === 'app/App/USER_REGISTER_SUCCESS'){
      TagManager.dataLayer(createDatalayerObj({
        event:'NewAccoutSignUp',
        userID:`${user.id}`
      }));
    }

    if(updated === 'app/App/USER_LOGOUT'){
      TagManager.dataLayer(createDatalayerObj({
        event:'Logout',
        userID:`${user.id}`
      }));
    }

    if(updated === 'app/App/USER_LOGIN_SUCCESS'){
      TagManager.dataLayer(createDatalayerObj(pageView({ location, user, site })));
      TagManager.dataLayer(createDatalayerObj({
        event:'Login',
        userID:`${user.id}`
      }));
    }

  },[updated]);

  useEffect(() => {
    if(cart && cart.id){
      prevCart.current = cart;
    }
  },[cart]);

  useEffect(() => {
    if(productSearch.data && Array.isArray(productSearch.data)){
      const productsViewItems = productSearch.data.map((item:IProduct) => makeProductItemView(item,site,cart));

      TagManager.dataLayer(createDatalayerObj(eeImpression(productsViewItems, null, pageType.SEARCH_PAGE )));

      const searchText = productSearch.query?.searchText ?? '';
      if(searchText) {
        const resultsCount = productSearch.data.length;
        TagManager.dataLayer(createDatalayerObj(searchResult(searchText, resultsCount)));
      }

    }
  },[productSearch.data]);

  useEffect(() => {
    try {

      const {payment_intent, payment_intent_result} = stripe;
      if(!payment_intent && !payment_intent_result) return;

      const {id, amount, currency,} = stripe.payment_intent;
      const success = stripe.payment_intent_result?.result === 'success';
      const subscriptionPaths = ['/cyder-club/signup']
      const onSubscription = subscriptionPaths.find((path) => location.pathname.includes(path));

      // cyder club subscription
      if(onSubscription){

        if(!success) {
          TagManager.dataLayer(createDatalayerObj(eeSubscriptionPaymentFail('Stripe')));
          return;
        }

        const subscription = subscriptions.find((sub: {slug: string}) => sub.slug === 'cyder-club');
        const subscriptionProduct = subscription?.fields?.find((type: any) => type.product?.price === amount);


        if(subscription && subscriptionProduct){
          const subscriptionItem: trackingSubscriptionItem = {
            name: `${subscription.name} - ${subscriptionProduct.name}`,
            id: `${subscriptionProduct.product.id}`,
            price: `${(subscriptionProduct.product.price/100).toFixed(2)}`,
            brand: environment.APP_FLAVOR,
            category: 'subscription',
            quantity: 1

          }
          const subscriptionTransaction: transactionArgs = {
            id: `subscribe-${Date.now()}-${subscriptionProduct.product.id}`,
            revenue: amount,
            tax: 0,
            shipping: 0,
            currency
          }

          TagManager.dataLayer(createDatalayerObj(
              eeSubscriptionPurchase(
                  {...subscriptionTransaction},
                  `${subscription.name}`,
                  location.pathname,
                  { ...subscriptionItem }
              )
          ));
        }

        return;
      }

      // all other purchases
      if(success){
        const cartItems = prevCart.current?.items && Array.isArray(prevCart.current.items) ? prevCart.current.items.filter((item:any) => item?.product?.isPromotionProduct !== true) : [];

        if(cartItems){
          const formattedCartProducts:trackingCartItemsInterface[] = cartItems.map((cartItem:any) => ({...makeProductItemView(cartItem.product,site,cart),quantity:cartItem.quantity}));

          const location = { pathname:'/checkout/payment/result' };

          TagManager.dataLayer(createDatalayerObj(pageView({ location, user, site })));

          if(prevCart.current.totals){
            const transaction: transactionArgs = {
              id,
              revenue: amount,
              tax: prevCart?.current?.totals?.taxes,
              shipping: prevCart?.current?.totals?.shipping,
              coupon: prevCart?.current?.totals?.promotion,
              currency
            }
            TagManager.dataLayer(createDatalayerObj(eePurchase(formattedCartProducts, {...transaction}, prevCart?.current?.isSubscriptionOrder)));
          }

        }
      }

    } catch (e) {
      console.error(e);
      console.log('Could not respond to Stripe payments');
    }

  },[stripe.payment_intent_result]);

  useEffect(() => {
    try{
      const { payment_status } = peachPayment;

      if(!payment_status) return;

      const peachPaymentSuccess = payment_status.result === 'success';
      const subscriptionPaths = ['/subscriptions/babylonstoren-seasons/signup', '/wine-club/recurring']
      const babylonstorenSubscription = subscriptionPaths.find((path) => location.pathname.includes(path));


      if(babylonstorenSubscription){
        if(!peachPaymentSuccess){
          TagManager.dataLayer(createDatalayerObj(eeSubscriptionPaymentFail('PeachPayment')));
          return;
        }

        const subscription = subscriptions.find((sub: any) => sub.id === peachPayment.purchasedItemCtx?.subscriptionId);
        const subscriptionProduct = subscription?.fields?.find((type: any) => type.product?.id === peachPayment.purchasedItemCtx?.productId);

        if(subscription && subscriptionProduct){
          const subscriptionItem: trackingSubscriptionItem = {
            name: `${subscription.name} - ${subscriptionProduct.name}`,
            id: `${subscriptionProduct.product.id}`,
            price: `${(subscriptionProduct.product.price/100).toFixed(2)}`,
            brand: environment.APP_FLAVOR,
            category: 'subscription',
            quantity: 1
          }

          const subscriptionTransaction: transactionArgs = {
            id: `subscribe-${Date.now()}-${subscriptionProduct.product.id}`,
            revenue: subscriptionProduct.product.price,
            tax: 0,
            shipping: 0,
            currency: subscriptionProduct.product.currency
          }

          TagManager.dataLayer(createDatalayerObj(eeSubscriptionPurchase(
              {...subscriptionTransaction},
              `${subscription.name}`,
              location.pathname,
              { ...subscriptionItem })
          ));
        }

        return;

      }


      //track all other peachPayment result
      if(peachPaymentSuccess){
        const cartItems = prevCart.current?.items && Array.isArray(prevCart.current.items) ? prevCart.current.items.filter((item:any) => item?.product?.isPromotionProduct !== true) : [];

        if(!cartItems) return;

        const formattedCartProducts:trackingCartItemsInterface[] = cartItems.map((cartItem:any) => ({...makeProductItemView(cartItem.product,site,cart),quantity:cartItem.quantity}));
        const location = { pathname:'/checkout/payment/result' };

        TagManager.dataLayer(createDatalayerObj(pageView({ location, user, site })));

        if(prevCart.current.totals){
          const transactionId = peachPayment.payment_status?.orderNumber;
          const transactionRevenue = prevCart?.current?.totals?.total;
          const transactionTax = prevCart?.current?.totals?.taxes;
          const transactionShipping = prevCart?.current?.totals?.shipping;
          const transactionPromotion = prevCart?.current?.totals?.promotion;
          const transactionCurrency = prevCart?.current?.currency;

          TagManager.dataLayer(createDatalayerObj(eePurchase(formattedCartProducts, {
            id:transactionId,
            revenue:transactionRevenue,
            tax:transactionTax,
            shipping:transactionShipping,
            coupon:transactionPromotion,
            currency:transactionCurrency
          })));
        }

        return;
      }


    } catch (e) {
      console.log(e);
      console.log('Could not respond to subscription payments');
    }


  },[peachPayment.payment_status]);

  useEffect(() => {
    const cartItems = cart?.items && Array.isArray(cart.items) ? cart.items.filter((item:any) => item?.product?.isPromotionProduct !== true) : [];

    if(prevCartItems.current !== undefined){
      if(cart?.items && Array.isArray(cart.items)){

        let currentItems = 0;
        let itemAdded = null;
        let itemRemoved = null;

        cartItems.forEach((item:any) => {
          currentItems = currentItems + parseInt(item.quantity);
          let didFind = false;
          prevCartItems.current.forEach((pitem:any) => {
            if(pitem.id === item.id){
              if(pitem.quantity === item.quantity){
                didFind = true;
              }
            }
          });
          if(didFind === false){
            itemAdded = item;
          }
        });

        let prevItems = 0;
        prevCartItems.current.forEach((item:any) => {
          prevItems = prevItems + parseInt(item.quantity)
        });

        if(currentItems > prevItems && itemAdded !== null){
          // item added
          const [updatedCartItem] = prevCartItems.current.filter((pItem:any) => pItem.id === itemAdded.id);

          TagManager.dataLayer(createDatalayerObj(Clear()));
          TagManager.dataLayer(createDatalayerObj(eeAddToBasket(makeProductItemView(itemAdded.product,site,cart), updatedCartItem ? itemAdded.quantity - updatedCartItem.quantity : itemAdded.quantity)));
        }

        if(currentItems < prevItems){
          // item removed
          prevCartItems.current.forEach((item:any) => {
            const didFind = !!cartItems.find((searchItem: any) => {
              return searchItem.product.id === item.product.id && searchItem.quantity === item.quantity;
            });
            if(didFind === false){
              itemRemoved = item;
            }
          });

          if(itemRemoved !== null){
            const [updatedCartItem] = cartItems.filter((pItem:any) => pItem.id === itemRemoved.id);

            TagManager.dataLayer(createDatalayerObj(Clear()));
            TagManager.dataLayer(createDatalayerObj(eeRemoveFromBasket(makeProductItemView(itemRemoved.product,site,cart), updatedCartItem ? itemRemoved.quantity - updatedCartItem.quantity : itemRemoved.quantity)));
          }

        }
      }
    }

    prevCartItems.current = cartItems;
    return
  },[cart.items])

  useEffect(() => {
    if(products?.data && products.data.length > 0){
      const productsViewItems = products.data.map((item:IProduct) => makeProductItemView(item,site,cart));
      TagManager.dataLayer(createDatalayerObj(eeImpression(productsViewItems)));
    }
  },[products.data]);

  useEffect(() => {
    const productView = makeProductItemView(product, site, cart);
    const associations = product.associations && Array.isArray(product.associations) && product.associations[0] && product.associations[0].products ? product.associations[0].products.map((item:any) => makeProductItemView(item, site, cart)) : [];

    if(productView !== null){
      TagManager.dataLayer(createDatalayerObj(eeDetail(productView)));
      if(associations && Array.isArray(associations) && associations.length > 0){
        TagManager.dataLayer(createDatalayerObj(eeImpression(associations, pageType.RELATED_PRODUCTS, pageType.RELATED_PRODUCTS)));
      }
    }

  },[product]);

  return (
      <div id="GoogleDataLayer"></div>
  );
};

export default GoogleDataLayer;
