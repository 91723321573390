import { environment } from "../../../config/environment";
import { doApiCall } from "./ApiCall";
import { ShopApiTokens } from "./ShopApiTokens";

const API_URL = `${
  environment.API_URL.endsWith("/")
    ? environment.API_URL
    : `${environment.API_URL}/`
}`;

interface ShopApiCallOptions {
  body?: any;
  query?: any;
  headers?: any;
  authCartId?: number;
}

const shopApiTokens = new ShopApiTokens();
const CACHE: any = [];

function getAuthHeaders(authCartId: number = null) {
  const headers: any = {};

  if (authCartId && shopApiTokens.hasCartToken(authCartId)) {
    headers["X-Cart-Authorization"] =
      "Token " + shopApiTokens.getCartToken(authCartId);
  }

  if (shopApiTokens.hasUserToken()) {
    headers["X-User-Authorization"] = "Token " + shopApiTokens.getUserToken();
  }

  return headers;
}

function shopApiGet<R = any>(
  path: string,
  options: Omit<ShopApiCallOptions, "body"> = {}
): Promise<R> {
  return shopApiCall("GET", path, options);
}

function shopApiPost<R = any>(
  path: string,
  options: ShopApiCallOptions = {}
): Promise<R> {
  return shopApiCall("POST", path, options);
}

function shopApiCall<R = any>(
  method: "GET" | "POST",
  path: string,
  { body, query, headers, authCartId }: ShopApiCallOptions = {}
): Promise<R> {
  if (!API_URL) throw new Error("API_URL not set, cannot make api call");

  let queryString: string | null = null;

  if (query) {
    queryString = Object.keys(query)
      .map((k) => {
        return `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`;
      })
      .join("&");
  }

  const requestURL =
    `${API_URL}${path.startsWith("/") ? path.substr(1) : path}` +
    (queryString ? `?${queryString}` : "");

  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      ...getAuthHeaders(authCartId),
      ...headers,
    },
    body: body ? JSON.stringify(body) : undefined,
  };

  return doApiCall(requestURL, options);
}

export const ShopApi = {
  // config
  getCountries: () => shopApiGet("shop/config/countries/fetch"),
  getChannels: () => shopApiGet("shop/config/channel/fetch"),
  getChannelConfig: (channelCode: string) =>
    shopApiGet(`shop/config/${channelCode}`),
  getLocales: () => shopApiGet("shop/config/client/country"),
  checkPostcode: (postcode: string) =>
    shopApiPost("shop/config/fresh/postcode", { body: { postcode } }),

  // products

  getProductByCode: (code: string, channelCode: string) =>
    shopApiGet(`shop/product/${code}`, { query: { channelCode } }),

  getProductBySlug: (slug: string, channelCode: string) =>
    shopApiGet(`shop/product/${slug}/slug`, { query: { channelCode } }),

  getProductsByTaxonSlug: (
    slug: string,
    channelCode: string,
    deliveryAreaCode: string
  ) =>
    shopApiGet("shop/products/listing/taxon", {
      query: { channelCode, slug, deliveryAreaCode },
    }),

  getAllProducts: (
    channelCode: string,
    deliveryAreaCode: string = "",
    sort = "CREATED_AT"
  ) =>
    shopApiGet("shop/products/listing", {
      query: { channelCode, deliveryAreaCode },
    }),

  productSearch: (
    searchText: string,
    channelCode: string,
    deliveryAreaCode: string
  ) =>
    shopApiGet("shop/products/listing/search", {
      query: { searchText, channelCode, deliveryAreaCode },
    }),

  // taxons
  getTaxonNavListing: (channelCode: string, deliveryAreaCode: string = "") => {

    const id = `getTaxonNavListing(${channelCode},${deliveryAreaCode})`;

    if(CACHE[id]) return CACHE[id];

    CACHE[id] = new Promise((resolve, reject) => {

      shopApiGet("shop/taxon/listing/nav", {
        query: { channelCode, deliveryAreaCode },
      }).then((d) => {
        resolve(d);
      }, (e) => {
        CACHE[id] = null;
        reject(e);
      });

    });

    return CACHE[id];

  },

  // cart
  createCart: async (body: {
    channel: string;
    userId?: number;
    referrer?: string;
  }) => {
    let res = await shopApiPost("shop/cart/create", { body });
    if (res.token) {
      shopApiTokens.setCartToken(res.cart.id, res.token);
    }
    return res;
  },

  getCart: async (cartId: number) => {
    let res = await shopApiGet(`shop/cart/${cartId}`, { authCartId: cartId });
    if (res.token) {
      shopApiTokens.setCartToken(res.cart.id, res.token);
    }
    return res;
  },

  linkCartToCustomer: (cartId: number, customerId: number) =>
    shopApiPost(`shop/cart/${cartId}/link-customer`, {
      body: { customerId },
      authCartId: cartId,
    }),

  createCartItem: (
    cartId: number,
    body: { amount?: number; productId: number; quantity: number }
  ) => shopApiPost(`shop/cart/${cartId}/item`, { body, authCartId: cartId }),

  updateCartItem: (
    cartId: number,
    itemId: number,
    body: { amount?: number; quantity: number }
  ) =>
    shopApiPost(`shop/cart/${cartId}/item/${itemId}/update`, {
      body,
      authCartId: cartId,
    }),

  deleteCartItem: (cartId: number, itemId: number) =>
    shopApiPost(`shop/cart/${cartId}/item/${itemId}/delete`, {
      authCartId: cartId,
    }),

  getCartDeliveryDates: (cartId: number) =>
    shopApiGet(`shop/cart/${cartId}/delivery/dates`, { authCartId: cartId }),

  updateCartDeliveryDate: (cartId: number, requestedDate: string) =>
    shopApiPost(`shop/cart/${cartId}/delivery/date/assignment`, {
      body: { requestedDate },
      authCartId: cartId,
    }),

  updateCartLinkReferrer: (cartId: number, referrer: string) =>
    shopApiPost(`shop/cart/${cartId}/link-referrer`, {
      body: { referrer },
      authCartId: cartId,
    }),

  updateCartReorder: (cartId: number, orderId: number) =>
    shopApiPost(`shop/cart/${cartId}/reorder`, {
      body: { orderId },
      authCartId: cartId,
    }),

  updateCartSetTaxNumber: (cartId: number, taxNumber: string) =>
    shopApiPost(`shop/cart/${cartId}/reorder`, {
      body: { taxNumber },
      authCartId: cartId,
    }),

  updateCartRemoveVoucher: (cartId: number) =>
    shopApiPost(`shop/cart/${cartId}/voucher/remove`, {
      authCartId: cartId,
    }),

  // checkout
  getCartShippingMethods: (cartId: number) =>
    shopApiGet(`shop/checkout/${cartId}/shipments`, { authCartId: cartId }),

  updateCartForCollection: (cartId: number, body: any) =>
    shopApiPost(`shop/checkout/${cartId}/addressing/collection`, {
      body,
      authCartId: cartId,
    }),

  confirmAge: (cartId: number, body: any) =>
    shopApiPost(`shop/checkout/give/consent`, {
      body,
      authCartId: cartId,
    }),

  getCartPaymentMethods: (cartId: number) =>
    shopApiGet(`shop/checkout/${cartId}/payments`, { authCartId: cartId }),

  updateCartPaymentMethod: (cartId: number, paymentMethod: string) =>
    shopApiPost(`shop/checkout/${cartId}/payment`, {
      body: { paymentMethod },
      authCartId: cartId,
    }),

  updateCartAddress: (cartId: number, body: any) =>
    shopApiPost(`shop/checkout/${cartId}/addressing`, {
      body,
      authCartId: cartId,
    }),

  updateCartRedeemVoucher: (cartId: number, voucherCode: string) =>
    shopApiPost(`shop/checkout/${cartId}/redeem-voucher`, {
      body: { account: voucherCode },
      authCartId: cartId,
    }),

  updateCartSetEnquired: (cartId: number) =>
    shopApiPost(`shop/checkout/${cartId}/enquired`, { authCartId: cartId }),

  updateCartCheckPromotion: (cartId: number) =>
    shopApiPost(`shop/checkout/${cartId}/promotion/check`, {
      authCartId: cartId,
    }),

  // payment
  updateCartProcessPayment: (cartId: number, body: any) =>
    shopApiPost(`shop/checkout/${cartId}/payment/process`, {
      body,
      authCartId: cartId,
    }),

  updateCartCheckPeachTransaction: (cartId: number) =>
    shopApiPost(`shop/checkout/${cartId}/payment/peach/transaction/check`, {
      authCartId: cartId,
    }),

  updateCartCheckPeachEFTTransaction: (cartId: number) =>
    shopApiPost(`shop/checkout/${cartId}/payment/peach/eft/transaction/check`, {
      authCartId: cartId,
    }),

  updateCartCheckStripeIntent: (cartId: number, intentId: string) =>
    shopApiPost(`shop/checkout/${cartId}/payment/stripe/intent/check`, {
      body: { intentId },
      authCartId: cartId,
    }),

  // auth

  login: async (body: {
    email: string;
    password: string;
    channelCode: string;
  }) => {
    let res = await shopApiPost(`shop/auth/login`, { body }).then(
      handleUserTokenResponse
    );
    if (res.cartToken && res.user.cartId) {
      shopApiTokens.setCartToken(res.user.cartId, res.cartToken);
    }
    return res;
  },

  logout: async () => {
    shopApiTokens.clearAllTokens();
  },

  register: async (body: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    gender: string;
    phoneNumber: string;
    newsletterSignup: string;
    channelCode: string;
    newsletter: string;
  }) =>
    shopApiPost(`shop/auth/register`, { body }).then(handleUserTokenResponse),

  requestForgotPassword: (body: { email: string }) =>
    shopApiPost("shop/auth/password/forgot", { body }),

  resetPassword: (body: { email: string; token: string; password: string }) =>
    shopApiPost(`shop/auth/password/reset/token`, { body }).then(
      handleUserTokenResponse
    ),

  // user

  getUser: async (userId: number, channelCode: string) => {
    let res = await shopApiGet(`shop/user/${userId}`, {
      query: { channelCode },
    });
    if (res.cartToken && res.user.cartId) {
      shopApiTokens.setCartToken(res.user.cartId, res.cartToken);
    }
    handleUserTokenResponse(res);
    return res;
  },

  hydrateUser: async () => {
    let res = await shopApiGet(`shop/user`);
    handleUserTokenResponse(res);
    return res;
  },

  updateUser: (
    userId: number,
    body: {
      firstName: string;
      lastName: string;
      email: string;
      birthDay: string;
      gender: string;
      phoneNumber: string;
      subscribedToNewsletter: boolean;
    }
  ) => shopApiPost(`shop/user/${userId}/update`, { body }),

  updateUserPassword: (
    userId: number,
    body: {
      password: string;
      newPassword: string;
      channelCode: string;
    }
  ) =>
    shopApiPost(`shop/user/${userId}/password/reset`, { body }).then(
      handleUserTokenResponse
    ),

  getUserAddresses: (userId: number) =>
    shopApiGet(`shop/user/${userId}/addresses`),

  getAllUserAddresses: (userId: number) =>
    shopApiGet(`shop/user/${userId}/all/addresses`),

  getUserOrderHistory: (userId: number) =>
    shopApiGet(`shop/user/${userId}/order/history`),

  updateUserNewsletterSubscription: (userId: number, status: boolean) =>
    shopApiPost(`shop/user/${userId}/update/newsletter`, { body: { status } }),

  updateUserPreferredLanguage: (userId: number, language: string) =>
    shopApiPost(`shop/user/${userId}/update/preferred/language`, {
      body: { language },
    }),

  updateUserReferral: (userId: number, referrer: string) =>
    shopApiPost(`shop/user/${userId}/referral/referrence/update`, {
      body: { referrer },
    }),

  createUserAddress: (userId: number, body: any) =>
    shopApiPost(`shop/user/${userId}/address/create`, { body }),

  updateUserAddress: (userId: number, addressId: number, body: any) =>
    shopApiPost(`shop/user/${userId}/address/${addressId}/update`, { body }),

  deleteUserAddress: (userId: number, addressId: number) =>
    shopApiPost(`shop/user/${userId}/address/${addressId}/delete`),

  getUserCreditCards: (userId: number) =>
    shopApiGet(`shop/user/${userId}/creditcards/list`),

  getUserCreditCardsByPaymentMethod: (
    userId: number,
    paymentMethodCode: string
  ) =>
    shopApiGet(`shop/user/${userId}/creditcards/method/${paymentMethodCode}`),

  getUserCreditCardsByChannel: (userId: number, channelCode: string) =>
    shopApiGet(`shop/user/${userId}/creditcards/channel/${channelCode}`),

  getUserCreditCardById: (userId: number, cardId: string) =>
    shopApiGet(`shop/user/${userId}/creditcards/${cardId}`),

  deleteUserCreditCard: (userId: number, cardId: string) =>
    shopApiPost(`shop/user/${userId}/creditcards/${cardId}/remove`),

  // order
  getOrder: async (userId: number, orderId: number) => {
    let res = await shopApiGet(`shop/user/${userId}/order/${orderId}`);
    if (res.token) {
      shopApiTokens.setCartToken(res.order.id, res.token);
    }
    return res;
  },

  // subscriptions
  getSubscriptions: (channel: string) =>
    shopApiGet(`shop/subscriptions/fetch/${channel}`),

  createSubscriptionLead: (subscriptionId: number, body: any) =>
    shopApiPost(`shop/subscriptions/${subscriptionId}/lead`, { body }),

  getSubscriptionCart: async (userId: number, subscriptionId: number) => {
    let res = await shopApiGet(
      `shop/user/${userId}/subscriptions/${subscriptionId}/fetch/cart`
    );
    if (res.token) {
      shopApiTokens.setCartToken(res.cart.id, res.token);
    }
    return res;
  },

  updateSubscriptionProcessPayment: (
    userId: number,
    subscriptionId: number,
    body: any
  ) =>
    shopApiPost(`shop/user/${userId}/subscriptions/${subscriptionId}/process`, {
      body,
    }),

  updateSubscriptionType: (
    userId: number,
    subscriptionId: number,
    type: string
  ) =>
    shopApiPost(
      `shop/user/${userId}/subscriptions/${subscriptionId}/type/update`,
      { body: { type } }
    ),

  updateSubscriptionStripeIntent: (
    userId: number,
    subscriptionId: number,
    productId: number,
    addressId: number
  ) =>
    shopApiPost(
      `shop/user/${userId}/subscriptions/${subscriptionId}/${productId}/${addressId}/stripe/intent`
    ),

  updateSubscriptionCheckStripeIntent: (userId: number, body: any) =>
    shopApiPost(`shop/user/${userId}/subscriptions/check/stripe/intent`, {
      body,
    }),

  updateSubscriptionPeachIntent: (
    userId: number,
    subscriptionId: number,
    productId: number,
    addressId: number
  ) =>
    shopApiPost(
      `shop/user/${userId}/subscriptions/${subscriptionId}/${productId}/${addressId}/peach/intent`
    ),

  updateSubscriptionCheckPeachIntent: (
    userId: number,
    subscriptionId: number,
    productId: number
  ) =>
    shopApiPost(
      `shop/user/${userId}/subscriptions/${subscriptionId}/${productId}/check/peach/intent`
    ),

  createSubscriptionSignup: (userId: number, subscriptionId: number, typeSlug:string) =>
    shopApiPost(`shop/user/${userId}/subscriptions/${subscriptionId}/signup/${typeSlug}`),

  updateSubscriptionRemoveUser: (userId: number, subscriptionId: number) =>
    shopApiPost(
      `shop/user/${userId}/subscriptions/${subscriptionId}/profile/remove`
    ),

  updateSubscriptionPauseUser: (userId: number, subscriptionId: number) =>
    shopApiPost(
      `shop/user/${userId}/subscriptions/${subscriptionId}/profile/pause`
    ),
  
  setUserSaveCards: (userId: number, saveCardForFutureUse: boolean) =>
    shopApiPost(
      `shop/user/${userId}/set-save-cards`,{
        body:{saveCardForFutureUse},
      }
    ),

  deliveryAreaMatch: (
    postcode: string = "",
    city: string = "",
    suburb: string = "",
    countryCode: string = ""
  ) =>
    shopApiGet("shop/delivery-area/match", {
      query: { postcode, city, suburb, countryCode },
    }),

  setCartDeliveryArea: async (cartId: number, deliveryAreaCode: string) =>
    shopApiPost(`shop/checkout/${cartId}/delivery-area`, {
      body:{deliveryAreaCode},
      authCartId: cartId
    }),

  // candide

  verifyCandideTicket: (customerId: number, checkinToken: string) =>
    shopApiPost(`shop/user/${customerId}/candide/validate-ticket`, {
      body: { checkinToken },
    }),

  activateCandideMembership: (customerId: number, activationCode: string) =>
    shopApiPost(`shop/user/${customerId}/membership/claim-token`, {
      body: { token:activationCode },
    }),

  // membership

  createMembershipZoneToken: (customerId: number, membershipCode: string) =>
    shopApiPost(
      `shop/user/${customerId}/membership/${membershipCode}/zone-token`
    ),

  // icare
  createICareAccount: (customerId: number, account: string) =>
    shopApiPost(`shop/user/${customerId}/icare/register`, {
      body: { account },
    }),

  getICareBalance: (account: string) =>
    shopApiGet(`shop/icare/balance/${account}`),

  getDeliveryZoneLookup: (search: string) =>
    shopApiGet(`shop/checkout/delivery/zone/lookup/${search}`),

  getUPSPostcodeValidation: (postcode: string) =>
    shopApiGet(`shop/config/ups/postcode/validate/${postcode}`),

  /**
   * Token manager, DO NOT ACCESS OUTSIDE THIS MODULE
   */
  // tokens: shopApiTokens,
};

function handleUserTokenResponse(res: any) {
  if (res.token) {
    shopApiTokens.setUserToken(res.token);
    synchroniseWithTicketsMicrosite(res.token)
  } else {
    console.warn("handleUserTokenResponse: token not found in response", res);
  }
  return res;
}


function synchroniseWithTicketsMicrosite(token: string | null) {
  if (environment.TICKETS_SUBDOMAIN_URL != null) {
    // Attempt to log the user into tickets.* microsite.
    const ticketsSubdomainUrl = `${environment.TICKETS_SUBDOMAIN_URL}/membership`
    const ticketsIFrameId = "ticketsIFrame"
    let ticketsIFrame = window.document.querySelector<HTMLIFrameElement>(`#${ticketsIFrameId}`)
    
    if (ticketsIFrame == null) {
      ticketsIFrame = window.document.createElement("iframe")
      ticketsIFrame.setAttribute("src", ticketsSubdomainUrl)
      ticketsIFrame.setAttribute("id", ticketsIFrameId)
      ticketsIFrame.setAttribute("style", "display:none")
      window.document.body.appendChild(ticketsIFrame)
      window.addEventListener("message", (event) => {
        if (event.data === "ticketsSubdomainReady") {
          ticketsIFrame.contentWindow.postMessage({ticketsSubdomainToken: token}, "*")
        }
      })
    }

    ticketsIFrame.contentWindow.postMessage({ticketsSubdomainToken: token}, "*")
  }
}