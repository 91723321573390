import { call, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { DataStatus } from "src/interfaces/iRedux";
import { IDeliveryArea, IDeliveryAreaStore } from "../../interfaces/iDeliveryArea";
import { IAction } from "src/interfaces/iAction";
import { ShopApi } from "./api";
import { lsx as ls } from "../../helpers/localStorage";

const initialState = {
  status:DataStatus.UNINIT,
  data:null,
  message:'',
  error:null
};

export const SET_DELIVERY_AREA = "app/App/SET_DELIVERY_AREA";
export const SET_DELIVERY_AREA_SUCCESS = "app/App/SET_DELIVERY_AREA_SUCCESS";
export const SET_DELIVERY_AREA_ERROR = "app/App/SET_DELIVERY_AREA_ERROR";

export function onSetDeliveryArea(payload:any) {
  return {
    type: SET_DELIVERY_AREA,
    payload
  };
}

export function onSetDeliveryAreaSuccess(payload:any) {
  return {
    type: SET_DELIVERY_AREA_SUCCESS,
    payload
  };
}

export function onSetDeliveryAreaError(payload:any) {
  return {
    type: SET_DELIVERY_AREA_ERROR,
    payload
  };
}


// REDUCERS
export function reducer(state = initialState, action: IAction):IDeliveryAreaStore {
  switch (action.type) {
    case SET_DELIVERY_AREA:
      return {...state,...{
        status:DataStatus.LOADING,
        data:state.data,
        message:'Searching for Delivery Area',
        error:{}
      }};
    case SET_DELIVERY_AREA_SUCCESS:
      return {...state, ...{
        status:DataStatus.OK,
        data:action.payload,
        message:'',
        error:{}
      }};
    case SET_DELIVERY_AREA_ERROR:
      return {...state, ...{
        status:DataStatus.ERROR,
        data:null,
        message:'',
        error:action.payload
      }};
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(SET_DELIVERY_AREA, setDeliveryArea);
}

export function* setDeliveryArea(args) {

    try {
      const { postcode, city, suburb, countryCode } = args.payload;

      const deliveryArea = yield call(ShopApi.deliveryAreaMatch, postcode, city, suburb, countryCode);

      if(deliveryArea && deliveryArea.match && deliveryArea.match.areaMatch && deliveryArea.match.areaMatch.code && deliveryArea.match.areaMatch.name){
        const d_item = {
          code:deliveryArea.match.areaMatch.code,
          name:deliveryArea.match.areaMatch.name
        }
        ls.set("delivery_area", d_item);
        yield put(onSetDeliveryAreaSuccess(d_item));
      } else {
        yield put(onSetDeliveryAreaError(deliveryArea));
      }
    } catch (err) {
      yield put(onSetDeliveryAreaError(err));
    }

}

// SELECTORS
export const getThisDeliveryArea = state => state.deliveryArea;
export const makeSelectDeliveryArea = createSelector(
  [getThisDeliveryArea],
  deliveryArea => {
    return deliveryArea;
  }
);

