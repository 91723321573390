import { fromJS } from "immutable";
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { environment } from "src/config/environment";
import { makeSiteView } from "src/helpers/siteHelper";
import { IAction } from "src/interfaces/iAction";
import { DataStatus } from "src/interfaces/iRedux";
import { ISiteStore } from "src/interfaces/iSite";
import { ShopApi } from "./api";

const initialState = fromJS({});

export const CLEAR_SITE = "app/App/CLEAR_SITE";
export const GET_SITE = "app/App/GET_SITE";
export const GET_SITE_SUCCESS = "app/App/GET_SITE_SUCCESS";
export const GET_SITE_ERROR = "app/App/GET_SITE_ERROR";

// actions
export function onClearSite(payload: any) {
  return {
    type: CLEAR_SITE,
    payload,
  };
}

export function onGetSite(payload: any) {
  return {
    type: GET_SITE,
    payload,
  };
}

export function onGetSiteSuccess(payload: any) {
  return {
    type: GET_SITE_SUCCESS,
    payload,
  };
}

export function onGetSiteError(payload) {
  return {
    type: GET_SITE_ERROR,
    payload,
  };
}

const DEFAULT_SITE_STATE: ISiteStore = {
  status:DataStatus.UNINIT,
  query:{code:''},
  message:'',
  data:{
    id:0,
    code: environment.APP_DEFAULT_CHANNEL,
    name:'',
    hostName:'',
    currency:'',
    currencySymbol:'',
    freshShopEnabled: false,
    locale:{},
    locales:[],
    defaultLocale:{
      code:'',
      name:''
    },
    countries:[],
    channels:[],
    language:'',
    config:{},
    channelCountry:''
  }
};

// REDUCERS
export function reducer(state = DEFAULT_SITE_STATE, action: IAction): ISiteStore {
  switch (action.type) {
    case GET_SITE:
      return {
        ...state,
        ...{
          status: DataStatus.LOADING,
          query: {
            code: action.payload?.code,
          },
          message: "Loading site config",
        },
      };
    case GET_SITE_SUCCESS:
      return {
        ...state,
        ...{
          status: DataStatus.OK,
          data: action.payload,
          message: "",
        },
      };
    case GET_SITE_ERROR:
      return state;
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_SITE, getSite);
}

export function* getSite(args) {
  try {
    const currentState = yield select(getThisSite);
    const code = args.payload?.code;

    if (currentState.data?.id && code) {
      const { channels, locale, countries } = currentState.data;
      const view = makeSiteView({
        channels,
        locale,
        countries,
        selectedChannelCode: code,
      });
      yield put(onGetSiteSuccess(view));
    } else {
      const [channels, locale, countries] = yield all([
        call(ShopApi.getChannels),
        call(ShopApi.getLocales),
        call(ShopApi.getCountries),
      ]);


      const view = makeSiteView({
        channels: channels.stores,
        locale,
        countries: countries.config.countries,
      });
      if (view && view !== null) {
        yield put(onGetSiteSuccess(view));
      } else {
        yield put(onGetSiteError({}));
      }
    }
  } catch (err) {
    yield put(onGetSiteError(err));
  }
}

// SELECTORS
export const getThisSite = state => state.site;
export const getThisSiteChannel = state => state.site?.data?.code;
export const makeSelectSite = createSelector([getThisSite], (site) => {
  return site;
});
