import { fromJS } from "immutable";
import { call, put, throttle } from "redux-saga/effects";
import { createSelector } from "reselect";
import { updateObj } from "../../helpers/gen";
import { ApiErrorResponse, ShopApi } from "./api";



const initialState = fromJS({});

export const UPDATE_EGGCARD = "app/App/UPDATE_EGGCARD";
export const UPDATE_EGGCARD_SUCCESS = "app/App/UPDATE_EGGCARD_SUCCESS";
export const UPDATE_EGGCARD_ERROR = "app/App/UPDATE_EGGCARD_ERROR";

export const REGISTER_EGGCARD = "app/App/REGISTER_EGGCARD";
export const REGISTER_EGGCARD_SUCCESS = "app/App/REGISTER_EGGCARD_SUCCESS";
export const REGISTER_EGGCARD_ERROR = "app/App/REGISTER_EGGCARD_ERROR";

export const GET_EGGCARD_BALANCE = "app/App/GET_EGGCARD_BALANCE";
export const GET_EGGCARD_BALANCE_SUCCESS = "app/App/GET_EGGCARD_BALANCE_SUCCESS";
export const GET_EGGCARD_BALANCE_ERROR = "app/App/GET_EGGCARD_BALANCE_ERROR";

export const REDEEM_EGGCARD = "app/App/REDEEM_EGGCARD";
export const REDEEM_EGGCARD_SUCCESS = "app/App/REDEEM_EGGCARD_SUCCESS";
export const REDEEM_EGGCARD_ERROR = "app/App/REDEEM_EGGCARD_ERROR";

export const UNREDEEM_EGGCARD = "app/App/UNREDEEM_EGGCARD";
export const UNREDEEM_EGGCARD_SUCCESS = "app/App/UNREDEEM_EGGCARD_SUCCESS";
export const UNREDEEM_EGGCARD_ERROR = "app/App/UNREDEEM_EGGCARD_ERROR";


// (1) actions
export function onUpdateEggCard(payload) {
    return {
        type: UPDATE_EGGCARD,
        payload
    };
}

export function onUpdateEggCardSuccess(eggCard) {
    return {
        type: UPDATE_EGGCARD_SUCCESS,
        eggCard
    };
}

export function onUpdateEggCardError(error) {
    return {
        type: UPDATE_EGGCARD_ERROR,
        error
    };
}

// (2) register
export function onRegisterEggCard(payload) {
    return {
        type: REGISTER_EGGCARD,
        payload
    };
}

export function onRegisterEggCardSuccess(eggCard) {
    return {
        type: REGISTER_EGGCARD_SUCCESS,
        eggCard
    };
}

export function onRegisterEggCardError(error) {
    return {
        type: REGISTER_EGGCARD_ERROR,
        error
    };
}

// (3) get balance
export function onGetBalanceEggCard(payload) {
    return {
        type: GET_EGGCARD_BALANCE,
        payload
    };
}

export function onGetBalanceEggCardSuccess(eggCard) {
    return {
        type: GET_EGGCARD_BALANCE_SUCCESS,
        eggCard
    };
}

export function onGetBalanceEggCardError(error) {
    return {
        type: GET_EGGCARD_BALANCE_ERROR,
        error
    };
}

// (4) redeem
export function onRedeemEggCard(payload) {
    return {
        type: REDEEM_EGGCARD,
        payload
    };
}

export function onRedeemEggCardSuccess(eggCard) {
    return {
        type: REDEEM_EGGCARD_SUCCESS,
        eggCard
    };
}

export function onRedeemEggCardError(error) {
    return {
        type: REDEEM_EGGCARD_ERROR,
        error
    };
}

// (4) unredeem
export function onUnRedeemEggCard(payload) {
    return {
        type: UNREDEEM_EGGCARD,
        payload
    };
}

export function onUnRedeemEggCardSuccess(eggCard) {
    return {
        type: UNREDEEM_EGGCARD_SUCCESS,
        eggCard
    };
}

export function onUnRedeemEggCardError(error) {
    return {
        type: UNREDEEM_EGGCARD_ERROR,
        error
    };
}



// REDUCERS
export function reducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case UPDATE_EGGCARD:
            return state;
        case UPDATE_EGGCARD_SUCCESS:
            return action.eggCard;
        case UPDATE_EGGCARD_ERROR:
            return state;

        case REGISTER_EGGCARD:
            return state;
        case REGISTER_EGGCARD_SUCCESS:
            return action.eggCard;
        case REGISTER_EGGCARD_ERROR:
            return state;

        //get balance
        case GET_EGGCARD_BALANCE:
            return state;
        case GET_EGGCARD_BALANCE_SUCCESS:
            return updateObj(state, { balance: action.eggCard });
            return action.eggCard;
        case GET_EGGCARD_BALANCE_ERROR:
            return state;

        // redeem
        case REDEEM_EGGCARD:
            return state;
        case REDEEM_EGGCARD_SUCCESS:
            return updateObj(state, { redeem: action.eggCard });
            return action.eggCard;
        case REDEEM_EGGCARD_ERROR:
            return state;

        // unredeem
        case UNREDEEM_EGGCARD:
            return state;
        case UNREDEEM_EGGCARD_SUCCESS:
            return updateObj(state, { redeem: action.eggCard });
            return action.eggCard;
        case UNREDEEM_EGGCARD_ERROR:
            return state;

        default:
            return state;
    }
}


// SAGA
export function* saga() {
    yield throttle(500, UPDATE_EGGCARD, updateEggCard);
    yield throttle(500, REGISTER_EGGCARD, registerEggCard);
    yield throttle(500, GET_EGGCARD_BALANCE, getBalanceEggCard);
    yield throttle(500, REDEEM_EGGCARD, redeemEggCard);
    yield throttle(500, UNREDEEM_EGGCARD, unRedeemEggCard);
}

const updateEggCardObj = args => {
    return new Promise((resolve, reject) => {
        if (args.payload) {
            // success
            resolve(args.payload);
        } else {
            // error
            reject("could not update");
        }
    });
};

export function* updateEggCard(args) {
    try {
        const data = yield call(updateEggCardObj, args);
        yield put(onUpdateEggCardSuccess(data));
    } catch (err) {
        yield put(onUpdateEggCardError(err));
    }
}

export function* registerEggCard(args) {
    const { cardNumber, id } = args.payload;

    try {
        // Call our request helper (see 'utils/request')
        const data = yield call(ShopApi.createICareAccount, id, cardNumber);

        yield put(onRegisterEggCardSuccess({
            number: cardNumber,
            success: true,
            message: data.message ? data.message : ""
        }));

    } catch (err) {
        // error
        yield put(onRegisterEggCardSuccess({
            number: cardNumber,
            success: false,
            message: err.message ? err.message : "Something went wrong"
        }));

        //yield put(onRegisterEggCardError(err));
    }
}

export function* getBalanceEggCard(args) {
    const { cardNumber } = args.payload;


    try {
        const data = yield call(ShopApi.getICareBalance, cardNumber);

        yield put(onGetBalanceEggCardSuccess({
            number: cardNumber,
            success: true,
            message: data.message ? data.message : "",
            amount: data.amount ? data.amount : 0
        }));

    } catch (err) {
        // error
        yield put(onGetBalanceEggCardSuccess({
            number: cardNumber,
            success: false,
            message: err.message ? err.message : "Something went wrong",
            amount: 0
        }));
    }
}

export function* redeemEggCard(args) {
    const { account, orderId } = args.payload;

    try {
        const data = yield call(ShopApi.updateCartRedeemVoucher, orderId, account);
        yield put(onRedeemEggCardSuccess(data.cart));

    } catch (err) {

        if(err instanceof ApiErrorResponse){
            yield put(onRedeemEggCardSuccess(err.responseBody));
        }else{
            yield put(onRedeemEggCardSuccess(err));
        }

    }
}

export function* unRedeemEggCard(args) {
    const { account, cartId } = args.payload;

    try {
        const data = yield call(ShopApi.updateCartRemoveVoucher, args.payload.cartId);

        yield put(onUnRedeemEggCardSuccess(data.cart));

    } catch (err) {
        // error
        yield put(onUnRedeemEggCardSuccess(err));
    }
}

// SELECTORS
const getThisSite = state => state.eggCard;
export const makeSelectEggCard = createSelector(
    [getThisSite],
    eggCard => {
        return eggCard;
    }
);
