import { call, put, takeLatest } from "redux-saga/effects";
import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";
import { ShopApi } from "./api";
import { createSelector } from "reselect";
import { DataStatus } from "src/interfaces/iRedux";
import { store } from "../../index"

const initialState = fromJS({});

export const GET_PAYMENTS = "app/App/GET_PAYMENTS";
export const GET_PAYMENTS_SUCCESS = "app/App/GET_PAYMENTS_SUCCESS";
export const GET_PAYMENTS_ERROR = "app/App/GET_PAYMENTS_ERROR";

// actions
export function onGetPayments(payload) {
  return {
    type: GET_PAYMENTS,
    payload
  };
}

export function onGetPaymentsSuccess(payments) {
  return {
    type: GET_PAYMENTS_SUCCESS,
    payments
  };
}

export function onGetPaymentsError(error) {
  return {
    type: GET_PAYMENTS_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case GET_PAYMENTS:
      return {
        status:DataStatus.LOADING,
        query:action.payload,
        data:[]
      };
    case GET_PAYMENTS_SUCCESS:
      return {
        status:DataStatus.OK,
        query:state.query,
        data:action.payments
      }
      return action.payments ? action.payments : state;
    case GET_PAYMENTS_ERROR:
      return {
        status:DataStatus.ERROR,
        query:state.query,
        error:action.error,
        data:[]
      };
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_PAYMENTS, getPayments);
}

export function* getPayments(args) {

  //disable this call
  yield put(onGetPaymentsSuccess([]));

}

// SELECTORS
const getThisPayments = state => state.payments;
export const makeSelectPayments = createSelector(
  [getThisPayments],
  payments => {
    return payments;
  }
);
