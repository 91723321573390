import { fromJS } from "immutable";
import { createSelector } from "reselect";
import { call, put, takeLatest } from "redux-saga/effects";
import { ApiErrorResponse, ShopApi } from "./api";
import { CandideAccountActivateStatus ,CandideTicketVerifyStatus, ICandideAccountActivatePayload, ICandideVerifyTicketPayload, IUserMembership } from "./types";

export enum CandideMemberActionType {
  VERIFY_CANDIDE_TICKET_INIT = "app/Candide/VERIFY_CANDIDE_TICKET_INIT",
  VERIFY_CANDIDE_TICKET = "app/Candide/VERIFY_CANDIDE_TICKET",
  VERIFY_CANDIDE_TICKET_SUCCESS = "app/Candide/VERIFY_CANDIDE_TICKET_SUCCESS",
  VERIFY_CANDIDE_TICKET_ERROR = "app/Candide/VERIFY_CANDIDE_TICKET_ERROR",
  ACTIVATE_CANDIDE_MEMBERSHIP_INIT = "app/Candide/ACTIVATE_CANDIDE_MEMBERSHIP_INIT",
  ACTIVATE_CANDIDE_MEMBERSHIP = "app/Candide/ACTIVATE_CANDIDE_MEMBERSHIP",
  ACTIVATE_CANDIDE_MEMBERSHIP_SUCCESS = "app/Candide/ACTIVATE_CANDIDE_MEMBERSHIP_SUCCESS",
  ACTIVATE_CANDIDE_MEMBERSHIP_ERROR = "app/Candide/ACTIVATE_CANDIDE_MEMBERSHIP_ERROR",
  NOOP = "app/Candide/NOOP",
}

export interface ICandideMemberState {
  status: CandideTicketVerifyStatus | CandideAccountActivateStatus;
}

const initialState: ICandideMemberState = fromJS({
  status: CandideTicketVerifyStatus.PENDING,
});

export function onVerifyCandideTicket(payload: ICandideVerifyTicketPayload) {
  return {
    type: CandideMemberActionType.VERIFY_CANDIDE_TICKET,
    payload,
  };
}

export function onVerifyCandideTicketSuccess(membership: IUserMembership) {
  return {
    type: CandideMemberActionType.VERIFY_CANDIDE_TICKET_SUCCESS,
    membership,
  };
}

export function onVerifyCandideTicketError(error: any) {
  return {
    type: CandideMemberActionType.VERIFY_CANDIDE_TICKET_ERROR,
    error,
  };
}

export function onActivateCandideMembership(payload: ICandideAccountActivatePayload) {
  return {
    type: CandideMemberActionType.ACTIVATE_CANDIDE_MEMBERSHIP,
    payload,
  };
}

export function onActivateCandideMembershipSuccess(membership: IUserMembership) {
  return {
    type: CandideMemberActionType.ACTIVATE_CANDIDE_MEMBERSHIP_SUCCESS,
    membership,
  };
}

export function onActivateCandideMembershipError(error: any) {
  return {
    type: CandideMemberActionType.ACTIVATE_CANDIDE_MEMBERSHIP_ERROR,
    error,
  };
}

// REDUCERS
export function reducer(
  state: ICandideMemberState = initialState,
  action: any = { type: CandideMemberActionType.NOOP }
): ICandideMemberState {

  switch (action.type) {

    case CandideMemberActionType.VERIFY_CANDIDE_TICKET:
      return {
        status: CandideTicketVerifyStatus.LOADING,
      };
    case CandideMemberActionType.VERIFY_CANDIDE_TICKET_SUCCESS:
      return {
        status: CandideTicketVerifyStatus.OK,
      };
    case CandideMemberActionType.VERIFY_CANDIDE_TICKET_ERROR:

      let status = CandideTicketVerifyStatus.ERROR;
      const error = action.error;

      if(error instanceof ApiErrorResponse){
        if(error.isError('checkin_token_invalid')) status = CandideTicketVerifyStatus.INVALID;

        if(error.isError('ticket_usage_exceeded')) status = CandideTicketVerifyStatus.USAGE_EXCEEDED;

        if(error.isError('ticket_expired')) status = CandideTicketVerifyStatus.EXPIRED;
      }

      return {
        status
      };

    case CandideMemberActionType.ACTIVATE_CANDIDE_MEMBERSHIP:
      return {
        status: CandideAccountActivateStatus.LOADING,
      };
    case CandideMemberActionType.ACTIVATE_CANDIDE_MEMBERSHIP_SUCCESS:
      return {
        status: CandideAccountActivateStatus.OK,
      };
    case CandideMemberActionType.ACTIVATE_CANDIDE_MEMBERSHIP_ERROR:

      let activateStatus = CandideAccountActivateStatus.MEMBERSHIP_ERROR;
      const activateError = action.error;

      if(activateError instanceof ApiErrorResponse){
        if(activateError.isError('invalid_membership_token')) activateStatus = CandideAccountActivateStatus.INVALID_MEMBERSHIP_TOKEN;

        if(activateError.isError('membership_token_already_claimed')) activateStatus = CandideAccountActivateStatus.TOKEN_CLAIMED;

        if(activateError.isError('membership_already_assigned')) activateStatus = CandideAccountActivateStatus.MEMBERSIP_ALREADY_ASSIGNED;
      }

      return {
        status:activateStatus
      };
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(CandideMemberActionType.VERIFY_CANDIDE_TICKET, verifyCandideTicket);
  yield takeLatest(CandideMemberActionType.ACTIVATE_CANDIDE_MEMBERSHIP, activateMembership);
}

export function* verifyCandideTicket(
  action: ReturnType<typeof onVerifyCandideTicket>
) {

  try {
    const data = yield call(
      ShopApi.verifyCandideTicket,
      action.payload.customerId,
      action.payload.checkinToken
    );

    yield put(onVerifyCandideTicketSuccess(data));
  } catch (err) {
    yield put(onVerifyCandideTicketError(err));
  }
}

export function* activateMembership(
  action: ReturnType<typeof onActivateCandideMembership>
) {

  try {
    const data = yield call(
      ShopApi.activateCandideMembership,
      action.payload.customerId,
      action.payload.activationCode
    );

    yield put(onActivateCandideMembershipSuccess(data));
  } catch (err) {
    console.log('ERR',err)
    yield put(onActivateCandideMembershipError(err));
  }
}

const getThisCandideMember = (state:any) => state.candideMember;
export const selectCandideUserMembership = createSelector(
  [getThisCandideMember],
  (candideMember:any) => {
    return candideMember;
  }
);
