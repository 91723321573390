import { call, put, takeEvery } from "redux-saga/effects";

import { updateObj } from "../../helpers/gen";
import { getLanguageCode } from "../../helpers/languageHelper";
import { createSelector } from "reselect";

import { IAction } from "../../interfaces/iAction";
import { StaticApi } from "..";

const GET_CONTENT: string = "app/App/GET_CONTENT";
const GET_CONTENT_SUCCESS: string = "app/App/GET_CONTENT_SUCCESS";
const GET_CONTENT_ERROR: string = "app/App/GET_CONTENT_ERROR";

// actions
export function onGetContent(payload: any): IAction {
    return {
        type: GET_CONTENT,
        payload
    };
}

export function onGetContentSuccess(payload: any): IAction {
    return {
        type: GET_CONTENT_SUCCESS,
        payload
    };
}

export function onGetContentError(payload: any): IAction {
    return {
        type: GET_CONTENT_ERROR,
        payload
    };
}

// REDUCERS

export function reducer(state: any = {}, action: IAction = { type: "", payload: {} }): any {
    switch (action.type) {
        case GET_CONTENT:
            return action?.payload?.content ? {...state, ...{[action.payload.content]:{}}} : {};
        case GET_CONTENT_SUCCESS:
            return {...state, ...action.payload};
        case GET_CONTENT_ERROR:
            return state;
        default:
            return state;
    }
}

// SAGA
export function* saga() {
    //yield throttle(2000, GET_CONTENT, getContent);
    yield takeEvery(GET_CONTENT, getContent);
}

export function* getContent(args: any) {

    const { siteName, channel, language, content } = args.payload;

    const url = `/content/${siteName}/${channel}/${getLanguageCode()}/${content}.json?c=${channel}-${Date.now()}`;

    try {
        const data = yield call(StaticApi.getJson, url);
        yield put(onGetContentSuccess(data));
    } catch (err) {
        yield put(onGetContentError(err));
    }


}

// SELECTORS
const getThis = (state: any) => state.content;
export const makeSelectContent = createSelector(
    [getThis],
    content => {
        return content;
    }
);
