import { eventType } from './eventTypes';

export const searchResult = (searchText: string, resultsCount: number) => {

    return {
        event:eventType.SearchResults,
        searchQuery:searchText,
        searchResults:resultsCount,
    }
}
