import { fromJS } from "immutable";
import { takeLatest, throttle } from "redux-saga/effects";
import { createSelector } from "reselect";



const initialState = fromJS({});

const GET_PAYU_STATUS = "app/App/GET_PAYU_STATUS";
const GET_PAYU_STATUS_SUCCESS = "app/App/GET_PAYU_STATUS_SUCCESS";
const GET_PAYU_STATUS_ERROR = "app/App/GET_PAYU_STATUS_ERROR";

// wineclub deposit
const GET_WINECLUB_STATUS = "app/App/GET_WINECLUB_STATUS";
const GET_WINECLUB_STATUS_SUCCESS = "app/App/GET_WINECLUB_STATUS_SUCCESS";
const GET_WINECLUB_STATUS_ERROR = "app/App/GET_WINECLUB_STATUS_ERROR";

// subscription
const GET_SUBSCRIPTION_STATUS = "app/App/GET_SUBSCRIPTION_STATUS";
const GET_SUBSCRIPTION_STATUS_SUCCESS =
  "app/App/GET_SUBSCRIPTION_STATUS_SUCCESS";
const GET_SUBSCRIPTION_STATUS_ERROR = "app/App/GET_SUBSCRIPTION_STATUS_ERROR";

// actions
export function onGetPayuStatus(payload) {
  return {
    type: GET_PAYU_STATUS,
    payload
  };
}

export function onGetPayuStatusSuccess(status) {
  return {
    type: GET_PAYU_STATUS_SUCCESS,
    status
  };
}

export function onGetPayuStatusError(error) {
  return {
    type: GET_PAYU_STATUS_ERROR,
    error
  };
}

// wineclub
export function onGetWineClubStatus(payload) {
  return {
    type: GET_WINECLUB_STATUS,
    payload
  };
}

export function onGetWineClubStatusSuccess(status) {
  return {
    type: GET_WINECLUB_STATUS_SUCCESS,
    status
  };
}

export function onGetWineClubStatusError(error) {
  return {
    type: GET_WINECLUB_STATUS_ERROR,
    error
  };
}

// subscription
export function onGetSubscriptionStatus(payload) {
  return {
    type: GET_SUBSCRIPTION_STATUS,
    payload
  };
}

export function onGetSubscriptionStatusSuccess(status) {
  return {
    type: GET_SUBSCRIPTION_STATUS_SUCCESS,
    status
  };
}

export function onGetSubscriptionStatusError(error) {
  return {
    type: GET_SUBSCRIPTION_STATUS_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case GET_PAYU_STATUS:
      return state;
    case GET_PAYU_STATUS_SUCCESS:
      return action.status;
    case GET_PAYU_STATUS_ERROR:
      return state;
    //wineclub
    case GET_WINECLUB_STATUS:
      return state;
    case GET_WINECLUB_STATUS_SUCCESS:
      return action.status;
    case GET_WINECLUB_STATUS_ERROR:
      return state;
    //subscription
    case GET_SUBSCRIPTION_STATUS:
      return state;
    case GET_SUBSCRIPTION_STATUS_SUCCESS:
      return action.status;
    case GET_SUBSCRIPTION_STATUS_ERROR:
      return state;
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield throttle(2000, GET_PAYU_STATUS, getPayuStatus);
  //yield takeLatest(GET_PAYU_STATUS, getPayuStatus);
  yield takeLatest(GET_WINECLUB_STATUS, getWineClubStatus);
  yield takeLatest(GET_SUBSCRIPTION_STATUS, getSubscriptionStatus);
}

export function* getPayuStatus(args) {
  throw Error("PayuStatus: getPayuStatus is deprecated");
}

export function* getWineClubStatus(args) {
  throw Error("PayuStatus: getWineClubStatus is deprecated");
}

export function* getSubscriptionStatus(args) {
  throw Error("PayuStatus: getSubscriptionStatus is deprecated");
}

// SELECTORS
const getThis = state => state.payu_status;
export const makeSelectPayuStatus = createSelector(
  [getThis],
  payu_status => {
    return payu_status;
  }
);
