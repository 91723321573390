import moment from "moment";
import { environment } from "src/config/environment";

interface CartTokenRecord {
  id: number;
  token: string;
  exp: number;
}

export class ShopApiTokens {
  private _userToken: string | null = null;
  private _cartTokens: Record<string, CartTokenRecord> = {};

  constructor() {
    this._userToken = localStorage.getItem("user_token");
    this._cartTokens = {};

    if(localStorage.getItem("cart_tokens") !== null){
      try {
        let cartTokens = JSON.parse(localStorage.getItem("cart_tokens"));

        if (Array.isArray(cartTokens)) {
          cartTokens.forEach((t) => {
            if (this.isCartTokenRecord(t)) {
              if (new Date(t.exp) < new Date())
                this._cartTokens[String(t.id)] = t;
            }
          });
        } else {
          console.warn(cartTokens);
          throw Error("cart_tokens is not an array, got " + typeof cartTokens);
        }

        localStorage.setItem(
          "cart_tokens",
          JSON.stringify(Object.values(this._cartTokens))
        );
      } catch (e) {
        console.warn("ShopApiTokens: error parsing cart tokens", e);
      }
    }

  }

  setUserToken(token: string) {
    this._userToken = token;
    localStorage.setItem("user_token", token);
  }

  setCartToken(id: number, token: string) {
    let exp: number = null;

    try {
      let payload = JSON.parse(atob(token.split(".")[1]));
      exp = new Date(payload.exp).getTime();
    } catch (e) {
      console.error("ShopApiTokens: error parsing token", e);
      exp = moment().add(21, "days").toDate().getTime();
    }

    this._cartTokens[String(id)] = { id, token, exp };
    localStorage.setItem(
      "cart_tokens",
      JSON.stringify(Object.values(this._cartTokens))
    );
  }

  getUserToken() {
    return this._userToken;
  }

  getCartToken(id: number) {
    if (this._cartTokens[String(id)]) {
      return this._cartTokens[String(id)].token;
    }

    return null;
  }

  hasUserToken() {
    return !!this._userToken;
  }

  hasCartToken(id: number) {
    return !!this._cartTokens[String(id)];
  }

  isCartTokenRecord(t: any): t is CartTokenRecord {
    return (
      typeof t === "object" &&
      typeof t.id === "number" &&
      typeof t.token === "string" &&
      (typeof t.exp === "number" || t.exp === null)
    );
  }

  clearAllTokens() {
    this._userToken = null;
    this._cartTokens = {};
    localStorage.removeItem("cart_tokens");
    localStorage.removeItem("user_token");
    if (environment.TICKETS_SUBDOMAIN_URL != null) {
      const ticketsIFrame = window.document.querySelector<HTMLIFrameElement>("#ticketsIFrame")
      
      if (ticketsIFrame != null) {
        ticketsIFrame.contentWindow.postMessage("clearTicketsSubdomainToken", "*")
      }
    }
  }
}