import { environment } from "../config/environment";
import { IProductItemView } from "src/interfaces/iProduct";
import { eventType } from './eventTypes';
import { brandType } from './brandTypes';
import { cartProductImpressions } from './helpers';

interface trackingCartItemsInterface extends IProductItemView {
  quantity:number;
}

export const eeCheckoutPayment = (cartItems:trackingCartItemsInterface[], paymentMethod:string) => {

  const { APP_FLAVOR } = environment;
  const cartItemImpressions = cartProductImpressions(cartItems);

  return {
    event:eventType.EECheckoutPayment,
    pagePath:'/checkout/payment',
    pageTitle:`Payment - ${brandType[APP_FLAVOR] ?? ''}`,
    ecommerce:{
      checkout:{
        actionField:{
          step:3,
          option:`${paymentMethod.toLowerCase()}`
        },
        products:cartItemImpressions
      }
    }
  }
}