import { eventType } from './eventTypes';
import { getPageType } from './helpers';

export const pageView = (data: {location?:any, user?: any, site?: any}) => {

  const {location, user, site} = data;

  try{
    const { id } = user;
    const { pathname } = location;
    const { currency, code, language } = site.data;
    const pathArray = pathname.split('/');
    const lastSlug = pathArray[pathArray.length - 1];
    const pageTitle = `${lastSlug.charAt(0).toUpperCase()}${lastSlug.slice(1)}`;

    return {
      event:eventType.pageView,
      pagePath:pathname,
      pageTitle:`${pageTitle}`,
      pageTemplate:`${getPageType()}`,
      ecommerce:null,
      ... id && { userID: `${id}` },
      ...currency && { currency },
      ...code && { channel: code },
      ...language && { language }
    }
  } catch (e) {
    console.warn(`Could not create a pageView impression.`, e);
  }

}
