import { breakpoints } from "../../../helpers/breakpoints";

const imgWidth = `${Math.ceil(window.innerWidth / 200) * 200}`;

export const SA_Banner = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints(1200)},q_auto:good,c_lfill/v1597825879/bt-shop/homePage/And_We_re_Back_Again_qmwckj.jpg`;

export const INT_Banner = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints(1200)},q_auto:good,c_lfill/v1597825879/bt-shop/homePage/And_We_re_Back_Again_qmwckj.jpg`;

export const UK_Banner = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints()},q_auto:good,c_lfill/v1588831519/bt-shop/homePage/ld_banner_april_xuaqty.jpg`;

export const intHomeHighlights = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints()},q_auto:good,c_lfill/v1588831797/bt-shop/homePage/ld_featured_march_2020_ssqdeq.jpg`;
export const saHomeHighlights = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints()},q_auto:good,c_lfill/v1588831797/bt-shop/homePage/ld_featured_march_2020_ssqdeq.jpg`;
export const ukHomeHighlights = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints()},q_auto:good,c_lfill/v1588831797/bt-shop/homePage/ld_featured_march_2020_ssqdeq.jpg`;

// While you are here
export const visit_spa = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${imgWidth},q_auto:good,c_lfill/v1588832383/bt-shop/homePage/while_you_are_here/za-visit-spa_wabiiy.jpg`;
export const visit_hotel = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${imgWidth},q_auto:good,c_lfill/v1588832383/bt-shop/homePage/while_you_are_here/za-visit-hotel_k6dog2.jpg`;
export const visit_restaurants = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${imgWidth},q_auto:good,c_lfill/v1588832383/bt-shop/homePage/while_you_are_here/za-visit-restaurants_puhrkr.jpg`;
export const visit_garden = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${imgWidth},q_auto:good,c_lfill/v1588832383/bt-shop/homePage/while_you_are_here/za-garden_jgg3ne.jpg`;

export const notFoundBt = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${imgWidth},q_auto:good,c_lfill/v1588834548/bt-shop/homePage/error-page_kcvo2h.jpg`;
export const videoSplash = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${imgWidth},q_auto:good,c_lfill/v1588834671/bt-shop/homePage/rose-splash_leh9ef.jpg`;

export const SiteLogo = `https://res.cloudinary.com/babylon/image/upload/v1588832764/bt-shop/svg/logo_qzkdim.svg`;
//icons
export const FacebookIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832757/bt-shop/svg/facebook_x0dt6h.svg`;
export const InstagramIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832754/bt-shop/svg/instagram_roy6kq.svg`;
export const TwitterIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832762/bt-shop/svg/twitter_cefpwk.svg`;
export const YoutubeIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832761/bt-shop/svg/youtube_o95pqk.svg`;
