import { fromJS } from "immutable";
import { call, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { EventEmitter } from "../../helpers/eventEmitter";
import { ShopApi } from "./api";




const initialState = fromJS({});

const PAYMENT_PROCESS = "app/App/PAYMENT_PROCESS";
const PAYMENT_PROCESS_SUCCESS = "app/App/PAYMENT_PROCESS_SUCCESS";
const PAYMENT_PROCESS_ERROR = "app/App/PAYMENT_PROCESS_ERROR";

const RESERVE_PAYMENT = "app/App/RESERVE_PAYMENT";
const RESERVE_PAYMENT_SUCCESS = "app/App/RESERVE_PAYMENT_SUCCESS";
const RESERVE_PAYMENT_ERROR = "app/App/RESERVE_PAYMENT_ERROR";

const RESERVE_PAYMENT_CANCEL = "app/App/RESERVE_PAYMENT_CANCEL";
const RESERVE_PAYMENT_CANCEL_SUCCESS = "app/App/RESERVE_PAYMENT_CANCEL_SUCCESS";
const RESERVE_PAYMENT_CANCEL_ERROR = "app/App/RESERVE_PAYMENT_CANCEL_ERROR";

const WINECLUB_DEPOSIT = "app/App/WINECLUB_DEPOSIT";
const WINECLUB_DEPOSIT_SUCCESS = "app/App/WINECLUB_DEPOSIT_SUCCESS";
const WINECLUB_DEPOSIT_ERROR = "app/App/WINECLUB_DEPOSIT_ERROR";

const SUBSCRIPTION_PAYMENT = "app/App/SUBSCRIPTION_PAYMENT";
const SUBSCRIPTION_PAYMENT_SUCCESS = "app/App/SUBSCRIPTION_PAYMENT_SUCCESS";
const SUBSCRIPTION_PAYMENT_ERROR = "app/App/SUBSCRIPTION_PAYMENT_ERROR";

const PAYMENT_RESET = "app/App/PAYMENT_RESET";
const GET_SITE = "app/App/GET_SITE";

// user updates
const USER_LOGOUT = "app/App/USER_LOGOUT";
const USER_VERIFY_ERROR = "app/App/USER_VERIFY_ERROR";

// actions
export function onPaymentProcess(payload) {
  return {
    type: PAYMENT_PROCESS,
    payload
  };
}

export function onPaymentProcessSuccess(payment) {
  return {
    type: PAYMENT_PROCESS_SUCCESS,
    payment
  };
}

export function onPaymentProcessError(error) {
  return {
    type: PAYMENT_PROCESS_ERROR,
    error
  };
}

export function onPaymentReset(payload) {
  return {
    type: PAYMENT_RESET,
    payload
  };
}

// reserve payment
export function onPaymentReserve(payload) {
  return {
    type: RESERVE_PAYMENT,
    payload
  };
}

export function onPaymentReserveSuccess(payment) {
  return {
    type: RESERVE_PAYMENT_SUCCESS,
    payment
  };
}

export function onPaymentReserveError(error) {
  return {
    type: RESERVE_PAYMENT_ERROR,
    error
  };
}

// reserve payment
export function onPaymentReserveCancel(payload) {
  return {
    type: RESERVE_PAYMENT_CANCEL,
    payload
  };
}

export function onPaymentReserveCancelSuccess(payment) {
  return {
    type: RESERVE_PAYMENT_CANCEL_SUCCESS,
    payment
  };
}

export function onPaymentReserveCancelError(error) {
  return {
    type: RESERVE_PAYMENT_CANCEL_ERROR,
    error
  };
}

// wineclub deposit
export function onWineclubDeposit(payload) {
  return {
    type: WINECLUB_DEPOSIT,
    payload
  };
}

export function onWineclubDepositSuccess(payment) {
  return {
    type: WINECLUB_DEPOSIT_SUCCESS,
    payment
  };
}

export function onWineclubDepositError(error) {
  return {
    type: WINECLUB_DEPOSIT_ERROR,
    error
  };
}

// subscription payment
export function onSubscriptionPayment(payload) {
  return {
    type: SUBSCRIPTION_PAYMENT,
    payload
  };
}

export function onSubscriptionPaymentSuccess(payment) {
  return {
    type: SUBSCRIPTION_PAYMENT_SUCCESS,
    payment
  };
}

export function onSubscriptionPaymentError(error) {
  return {
    type: SUBSCRIPTION_PAYMENT_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case PAYMENT_PROCESS:
      return { status: "processing" };
    case PAYMENT_PROCESS_SUCCESS:
      return action.payment;
    case PAYMENT_PROCESS_ERROR:
      const errorObj = action.error;
      errorObj.status = "error";
      return errorObj;

    case RESERVE_PAYMENT:
      return { status: "reserving" };
    case RESERVE_PAYMENT_SUCCESS:
      return action.payment;
    case RESERVE_PAYMENT_ERROR:
      return { status: "error" };

    case RESERVE_PAYMENT_CANCEL:
      return { status: "cancel reserve" };
    case RESERVE_PAYMENT_CANCEL_SUCCESS:
      return action.payment;
    case RESERVE_PAYMENT_CANCEL_ERROR:
      return { status: "error" };

    case WINECLUB_DEPOSIT:
      return state;
    case WINECLUB_DEPOSIT_SUCCESS:
      return action.payment;
    case WINECLUB_DEPOSIT_ERROR:
      return { status: "error" };

    case SUBSCRIPTION_PAYMENT:
      return state;
    case SUBSCRIPTION_PAYMENT_SUCCESS:
      return action.payment;
    case SUBSCRIPTION_PAYMENT_ERROR:
      return action.error;

    case PAYMENT_RESET:
      return {};
    case USER_LOGOUT:
      return {};
    case GET_SITE:
      return {};
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(PAYMENT_PROCESS, doPaymentProcess);
  yield takeLatest(WINECLUB_DEPOSIT, doWineClubDeposit);
  yield takeLatest(SUBSCRIPTION_PAYMENT, doSubscriptionPayment);
  yield takeLatest(RESERVE_PAYMENT, doPaymentReserve);
  yield takeLatest(RESERVE_PAYMENT_CANCEL, doPaymentReserveCancel);
}

export function* doPaymentProcess(args) {

  let body = {};
  const cartId = args.payload.orderId;

  if (args.payload.cardId) {
    body = {
      cardId: args.payload.cardId
    };
  }

  try {
    const data = yield call(ShopApi.updateCartProcessPayment, cartId, body);

    if (
      data &&
      data.return &&
      data.return.successful &&
      data.return.successful === true
    ) {

      yield put(onPaymentProcessSuccess(data.return));
      EventEmitter.dispatch("DATA_SUCCESS_doPaymentProcess", data);

    } else if (data && data.action && data.action.length > 0) {

      yield put(onPaymentProcessSuccess(data));
      EventEmitter.dispatch("DATA_SUCCESS_doPaymentProcess", data);

    } else if (data && data.message && data.code && data.code === 404) {

      yield put(onPaymentProcessSuccess({ status: "error", successful: false, message: data.message }));

    } else if (data && data.message && data.hasOwnProperty('successful') && data.successful === false) {

      yield put(onPaymentProcessSuccess({ status: "error", successful: false, message: data.message }));

    } else {
      //yield put(onPaymentProcessSuccess(data.return));
      yield put(onPaymentProcessError(data.return));
      EventEmitter.dispatch("DATA_ERROR_doPaymentProcess", data);
    }

    //yield put(onPaymentProcessSuccess(data.return));
  } catch (err) {
    yield put(onPaymentProcessError(err));
  }

}

export function* doPaymentReserve(args) {
  throw new Error('Payment: doPaymentReserve is deprecated');
}

export function* doPaymentReserveCancel(args) {
  throw new Error('Payment: doPaymentReserveCancel is deprecated');
}

// wine club deposit
export function* doWineClubDeposit(args) {
  throw new Error('Payment: doWineClubDeposit is deprecated');
}

// subscription payment
export function* doSubscriptionPayment(args) {
  throw new Error('Payment: doSubscriptionPayment is deprecated');
}

// SELECTORS
const getThisPayment = state => state.payment;
export const makeSelectPayment = createSelector(
  [getThisPayment],
  payment => {
    return payment;
  }
);
