import { environment } from "../config/environment";
import { IProductItemView } from "src/interfaces/iProduct";
import { getPageType, listProductImpressions } from './helpers';
import { eventType } from './eventTypes';

export const eeClick = (product:IProductItemView, products:IProductItemView[]) => {

  const { APP_FLAVOR } = environment;

  const productImpressions = listProductImpressions(products);

  const productsClicked = productImpressions.filter(impression => impression.id === `${product.id}`).map((impression) => {
    const newImpression = {...impression}
    delete newImpression.list;
    return newImpression;
  });

  return {
    event:eventType.EEClick,
    ecommerce:{
      actionField: {list: getPageType()},
      click:{
        products:productsClicked
      }
    }
  }
}
