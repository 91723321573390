import { environment } from "../environment";
import { BabylonstorenINTConfig } from "./babylonstoren/babylonstoren.int.flavor";
import { BabylonstorenUSConfig } from "./babylonstoren/babylonstoren.us.flavor";
import { BabylonstorenZAConfig } from "./babylonstoren/babylonstoren.za.flavor";
import { IFlavorConfiguration } from "./flavorConfig";
import { NewtINTConfig } from "./newt/newt.int.flavor";
import { NewtUKConfig } from "./newt/newt.uk.flavor";
import { VignamaggioEUConfig } from "./vignamaggio/vignamaggio.eu.flavor";
import { VignamaggioINTConfig } from "./vignamaggio/vignamaggio.int.flavor";
import { VignamaggioUSConfig } from "./vignamaggio/vignamaggio.us.flavor";

export function makeFlavorConfig(
  flavor: string,
  channelCode: string
): IFlavorConfiguration {

  // console.log(`Creating flavor config ${flavor}:${channelCode}`);

  switch (flavor) {
    case "VIGNAMAGGIO":
      return makeVignamaggioConfig(channelCode);
    case "BABYLONSTOREN":
      return makeBabylonstorenConfig(channelCode);
    case "THENEWT":
      return makeNewtConfig(channelCode);
    default:
      throw new Error(`Unknown flavor '${flavor}'`);
  }

}

function makeBabylonstorenConfig(channelCode: string): IFlavorConfiguration {

  switch (channelCode) {
    case "za":
      return BabylonstorenZAConfig(environment);
    case "us":
      return BabylonstorenUSConfig(environment);
    case "int":
      return BabylonstorenINTConfig(environment);
    default:
      throw new Error(
        `Cannot resolve Babylonstoren flavor config for channel ${channelCode}`
      );
  }

}

function makeNewtConfig(channelCode: string): IFlavorConfiguration {

  switch (channelCode) {
    case "uk":
      return NewtUKConfig(environment);
    case "int":
      return NewtINTConfig(environment);
    default:
      return NewtINTConfig(environment);
      throw new Error(
        `Cannot resolve Newt flavor config for channel ${channelCode}`
      );

  }

}

function makeVignamaggioConfig(channelCode: string): IFlavorConfiguration {

  switch (channelCode) {
    case "eu":
      return VignamaggioEUConfig(environment);
    case "us":
      return VignamaggioUSConfig(environment);
    case "int":
      return VignamaggioINTConfig(environment);
    default:
      throw new Error(
        `Cannot resolve Vignamaggio flavor config for channel ${channelCode}`
      );
  }

}
