import { environment } from "./environment";
import { IFlavorConfiguration } from "./flavors/flavorConfig";
import { makeFlavorConfig } from "./flavors/flavorConfigFactory";
import { IsSite } from "./isSite";


export const SITE = (code?: string): IFlavorConfiguration => {

  if(!code){
    code = environment.APP_DEFAULT_CHANNEL;
  }

  const siteConfig = IsSite;
  return makeFlavorConfig(siteConfig.site, code);
};
