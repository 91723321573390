/* eslint-disable */
import React from "react";
import { IFlavorConfigFactory, IFlavorConfiguration } from "../flavorConfig";
import { videoSplash } from "../newt/newt.assets";
import { euHomeBanner, FacebookIcn, featuredVignamaggio, InstagramIcn, notFoundBt, SiteLogoVignamaggio, ukHomeHighlights } from "./vignamaggio.assets";
import i18next from "i18next";

export const VignamaggioEUConfig: IFlavorConfigFactory = () => {
  const siteCode = "eu";

  return {
    siteClass: "VIGNAMAGGIO",
    imgNotFound: notFoundBt,
    features:{
      deliveryAreas:false
    },
    fonts: [
      "https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700",
      "https://fonts.googleapis.com/css2?family=Playfair+Display",
      `${process.env.PUBLIC_URL}/fonts/BauerBodoni-Bold.ttf`,
      `${process.env.PUBLIC_URL}/fonts/BauerBodoni-Bold.woff`,
      `${process.env.PUBLIC_URL}/fonts/BauerBodoni-Bold.woff2`,
    ],
    emails: {
      deliveries: "eshop@vignamaggio.com",

      orders: "eshop@vignamaggio.com",
      orders2: ""
    },
    personalShopper: {
      name: "Janine",
      email: "eshop@vignamaggio.com"
    },
    hubspot: {
      newsletterSignup: {
        id: '6ff6182f-1b11-4bd5-99c7-14c654b2b771'
      }
    },
    Header: {
      siteName: [<span key="shop-title-span">SHOP </span>, `vignamaggio`],
      siteTitle: "VIGNAMAGGIO - Shop",
      phoneNumber: "+39 055 854 661",
      homeLinkLabel: i18next.t("VISIT US"),
      homeLinkUrl: "https://www.vignamaggio.com/",
      title: "Vignamaggio",
      bannerLinkColor: "#fff"
    },
    Footer: {
      copyright: "Vignamaggio",
      logo: SiteLogoVignamaggio,
      socialMedia: [
        {
          network: "facebook",
          url: "https://www.facebook.com/vignamaggio/",
          icn: FacebookIcn
        },
        {
          network: "instagram",
          url: "https://www.instagram.com/vignamaggio/",
          icn: InstagramIcn
        }
      ],
      footerLinks: [
        {
          label: i18next.t("Customer Care"),
          url: "/customer-care",
          sites: []
        },
        {
          label: "Exchange and Returns",
          url: "/exchange-and-returns",
          sites: []
        },
        {
          label: "Delivery",
          url: "/delivery",
          sites: []
        }
      ]
    },
    HomePage: {

      videoSplash: videoSplash,
      homeHighlights: ukHomeHighlights,
      banner: {
        img: euHomeBanner,
        text: (
          <div className="vignamaggio_bottomLeftZa">
            <h1>
              <span>WINE FROM THE HEART OF</span>
              <span>TUSCANY</span>
              <span></span>
              <span className="btn">SHOP NOW</span>
            </h1>
          </div>
        ),
        link: `/${siteCode}/categories/wine`
      },
      video_: {
        splash: "https://i.ytimg.com/vi/xDB_mA4MteM/maxresdefault.jpg",
        id: "F1rPZEcnI7Y",
        title: "VIGNAMAGGIO",
        subTitle: "Greve in Chianti - Firenze"
      },
      sectionFeatured: {
        title: "Carefully selected to spoil someone special",
        subTitle: "DISCOVER OUR GIFT HAMPERS",
        img: featuredVignamaggio,
        productsSlug: "promotions/featured"
      },
      sectionBestSellers: {
        title: "BESTSELLERS",
        subTitle: "",
        productsSlug: "promotions/best-sellers"
      }

    },
    Checkout: {
      needAssistance: <li className="needAssistance_config" dangerouslySetInnerHTML={{ __html: i18next.t("needAssistance.eu") }} />
    }
  };
};
