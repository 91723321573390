import { IProductItemView } from "src/interfaces/iProduct";
import { eventType } from './eventTypes';
import { pageType } from "./pageTypes";
import { listProductImpressions } from "./helpers";

export const eeImpression = (products:IProductItemView[], list?:pageType, listItemId? :string) => {

  const productImpressions = listProductImpressions(products, list, listItemId);

  return {
    event:eventType.EEImpression,
    ecommerce:{
      impressions:productImpressions
    }
  }
}
