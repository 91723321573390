import {environment} from './environment';

const getAppViewFromUrl = () => !!new URLSearchParams(document.location.search).get("appView")

const isAppView = () => {
  if(sessionStorage.getItem("appView")) {
    return true
  }
  if(getAppViewFromUrl()) {
    sessionStorage.setItem("appView", "true")
    return true
  } 

  return false
}

export const IsSite = {
  site: environment.APP_FLAVOR,
  isLive: environment.APP_ENV === 'production',
  appWebView: isAppView()
};
