export interface IEnvironment {
  APP_ENV: "local" | "test" | "development" | "production" | string;
  APP_DEBUG: boolean;
  APP_FLAVOR: string;
  APP_FAVICON_FOLDER: string;
  APP_URL: string;
  API_URL: string;
  GOOGLE_PLACES_API_KEY: string;
  CMS_API: string;
  CMS_PREVIEW: string;
  APP_DEFAULT_CHANNEL: string;
  DEFAULT_DELIVERY_AREA: string;
  BUILD_VERSION: string;
  GEOCODE_API: string;
  GTM_DATA_LAYER: string;
  GTM_ID: string;
  STRIPE_PUBLIC_KEY?: string;
  GA_CODE?: string;
  GTM_CODE?: string;
  GTM_SEND_TO?: string;
  FB_CODES?: string;
  TICKETS_SUBDOMAIN_URL?: string;
  VIDEO_BANNER_URL?: string;
}

let _env: any = {};

if (typeof (<any>window).APP_ENVIRONMENT === "object") {
  _env = (<any>window).APP_ENVIRONMENT;
  //console.debug('environment.ts: App environment inject', _env);
} else {
  _env = process.env;
  //console.warn('environment.ts: App enviroment use process', _env);
}

class GetEnv {
  static str(key: string, def: string) {
    return GetEnv.check(key) ? String(_env[key]) : def;
  }

  static bool(key: string, def: boolean = false) {
    return GetEnv.check(key) && (_env[key] === "true" || _env[key] === "false")
      ? _env[key] === "true"
      : def;
  }

  static num(key: string, def: number) {
    return GetEnv.check(key) ? Number(_env[key]) : def;
  }

  static csv(key: string, def: string[] = []) {
    return GetEnv.check(key) &&
      typeof _env[key] === "string" &&
      _env[key] !== ""
      ? _env[key].split(",")
      : def;
  }

  static check(key: string) {
    if (
      _env[key] === undefined ||
      _env[key] === null ||
      _env[key] === "" ||
      _env[key] === "null"
    ) {
      console.warn("Environment variable %s is unset", key);
      return false;
    }
    return true;
  }
}

export const environment: IEnvironment = {
  APP_ENV: GetEnv.str("REACT_APP_ENV", "local"),
  APP_DEBUG: GetEnv.bool("REACT_APP_DEBUG", false),
  APP_FLAVOR: GetEnv.str("REACT_APP_FLAVOR", "BABYLONSTOREN"),
  APP_URL: GetEnv.str("REACT_APP_URL", "__unset__"),
  APP_FAVICON_FOLDER: GetEnv.str("REACT_APP_FAVICON_FOLDER", "favicon"),
  APP_DEFAULT_CHANNEL: GetEnv.str("REACT_APP_DEFAULT_CHANNEL", "za"),
  API_URL: GetEnv.str("REACT_APP_API_URL", "__unset__"),
  GOOGLE_PLACES_API_KEY: GetEnv.str(
    "REACT_APP_GOOGLE_PLACES_API_KEY",
    "__unset__"
  ),
  CMS_API: GetEnv.str("REACT_APP_CMS_API", "__unset__"),
  CMS_PREVIEW: GetEnv.str("REACT_APP_CMS_PREVIEW", "__unset__"),
  DEFAULT_DELIVERY_AREA: GetEnv.str("REACT_APP_DEFAULT_DELIVERY_AREA", ""),

  GA_CODE: GetEnv.str("REACT_APP_GA_CODE", "__unset__"),
  GTM_CODE: GetEnv.csv("REACT_APP_GTM_CODE", []),
  GTM_SEND_TO: GetEnv.csv("REACT_APP_GTM_SEND_TO", []),

  FB_CODES: GetEnv.csv('REACT_APP_FB_CODES', []),
  BUILD_VERSION: GetEnv.str('REACT_APP_BUILD_VERSION', null),
  GEOCODE_API: GetEnv.str('REACT_APP_GEOCODE_API', ''),
  GTM_DATA_LAYER: GetEnv.str('REACT_APP_GTM_DATA_LAYER', ''),

  GTM_ID: GetEnv.str('REACT_APP_GTM_ID', ''),
  STRIPE_PUBLIC_KEY: GetEnv.str('REACT_APP_STRIPE_PUBLIC_KEY', null),
  TICKETS_SUBDOMAIN_URL: GetEnv.str("REACT_APP_TICKETS_SUBDOMAIN_URL", null),
  VIDEO_BANNER_URL: GetEnv.str("REACT_APP_VIDEO_BANNER_URL", null),
};
