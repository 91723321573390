import { environment } from "../config/environment";
import { eventType } from './eventTypes';
import { brandType } from './brandTypes';
import { cartProductImpressions } from './helpers';
import { trackingCartItemsInterface } from './interfaces';


export const eeViewCheckoutBasket = (cartItems:trackingCartItemsInterface[]) => {

  const { APP_FLAVOR } = environment;

  const cartItemImpressions = cartProductImpressions(cartItems);

  return {
    event:eventType.EEViewCheckoutBasket,
    pagePath:'/checkout/shopping-bag',
    pageTitle:`Shopping Bag - ${brandType[APP_FLAVOR] ?? ''}`,
    ecommerce:{
      checkout:{
        products:cartItemImpressions
      }
    }
  }
}
