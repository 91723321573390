import { combineReducers } from "redux";

import { reducer as auth } from "./Shop/Auth";
import { reducer as channels } from "./Shop/Channels";
import { reducer as site } from "./Shop/Site";
import { reducer as countries } from "./Shop/Countries";
import { reducer as collections } from "./Shop/Collections";
import { reducer as user } from "./Shop/User";
import { reducer as cart } from "./Shop/Cart";
import { reducer as products } from "./Shop/Products";
import { reducer as product } from "./Shop/Product";
import { reducer as allProducts } from "./Shop/AllProducts";
import { reducer as creditCards } from "./Shop/CreditCards";
import { reducer as payment } from "./Shop/Payment";
import { reducer as shipments } from "./Shop/Shipments";
import { reducer as payments } from "./Shop/Payments";
import { reducer as addresses } from "./Shop/Addresses";
import { reducer as allAddresses } from "./Shop/AllAddresses";
import { reducer as orders } from "./Shop/Orders";
import { reducer as locale } from "./Shop/Locale";
import { reducer as payu_status } from "./Shop/PayuStatus";
import { reducer as paypal_status } from "./Shop/PayPalStatus";
import { reducer as checkout } from "./Shop/Checkout";
import { reducer as subscription } from "./Shop/Subscription";
import { reducer as subscriptions } from "./Shop/Subscriptions";
import { reducer as updated } from "./Shop/Updated";
import { reducer as order } from "./Shop/Order";
import { reducer as eggCard } from "./Shop/EggCard";
import { reducer as hubspot } from "./Shop/Hubspot";
import { reducer as search } from "./Shop/Search";
import { reducer as peachPayment } from "./Shop/PeachPayment";
import { reducer as stripe } from "./Shop/Stripe";
import { reducer as content } from "./Shop/Content";
import { reducer as candideMember } from "./Shop/CandideMember";
import { reducer as deliveryArea } from "./Shop/DeliveryArea";
import { reducer as productSearch } from "./Shop/ProductSearch";
import { reducer as route } from "./Shop/Route";

const rootReducer = combineReducers({
  auth,
  payments,
  //channels,
  site,
  //countries,
  collections,
  user,
  cart,
  products,
  allProducts,
  product,
  creditCards,
  payment,
  shipments,
  addresses,
  allAddresses,
  orders,
  //locale,
  payu_status,
  paypal_status,
  checkout,
  subscription,
  subscriptions,
  updated,
  order,
  eggCard,
  hubspot,
  search,
  peachPayment,
  stripe,
  content,
  candideMember,
  deliveryArea,
  productSearch,
  route
});

export default rootReducer;
