import { call, put, takeLatest } from "redux-saga/effects";

import { fromJS } from "immutable";

import { createSelector } from "reselect";
import { doApiCall } from "./api";
import { readCookie } from "../../helpers/gen";

const initialState = fromJS({});

const SUBMIT_FORM_DATA = "app/App/SUBMIT_FORM_DATA";
const SUBMIT_FORM_DATA_SUCCESS = "app/App/SUBMIT_FORM_DATA_SUCCESS";
const SUBMIT_FORM_DATA_ERROR = "app/App/SUBMIT_FORM_DATA_ERROR";

// actions
export function onSubmitFormData(payload) {
    return {
        type: SUBMIT_FORM_DATA,
        payload
    };
}

export function onSubmitFormDataSuccess(result) {
    return {
        type: SUBMIT_FORM_DATA_SUCCESS,
        result
    };
}

export function onSubmitFormDataError(error) {
    return {
        type: SUBMIT_FORM_DATA_ERROR,
        error
    };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
    switch (action.type) {
        case SUBMIT_FORM_DATA:
            return {
                hubspotBusy: true,
                hubspotResult: ``,
                hubspotSuccess: ''
            }
            return state;
        case SUBMIT_FORM_DATA_SUCCESS:
            return action.result;
        case SUBMIT_FORM_DATA_ERROR:
            return action.error;
        default:
            return state;
    }
}

// SAGA
export function* saga() {
    yield takeLatest(SUBMIT_FORM_DATA, submitFormData);
}

export function* submitFormData(args) {

    const timestamp = new Date().getTime();
    const cookie = readCookie('hubspotutk');
    const pageUri = window.location.href;

    const requestURL = `https://api.hsforms.com/submissions/v3/integration/submit/${args.payload.portalId}/${args.payload.formGuid}`

    const API_HEADERS = {
        "Content-Type": "application/json"
    };

    let fields = [
        {
            "name": "email",
            "value": `${args.payload.email}`
        },
        {
            "name": "firstname",
            "value": `${args.payload.firstname}`
        },
        {
            "name": `${args.payload.field_name}`,
            "value": `${args.payload.field_value}`
        },
        {
            "name": `${args.payload.agree_field_name}`,
            "value": `${args.payload.agree_field_value}`
        }
    ];

    if (args.payload.country) {
        fields.push({
            "name": "country",
            "value": `${args.payload.country}`
        });
    }

    if (args.payload.hs_language) {
        fields.push({
            "name": "hs_language",
            "value": `${args.payload.hs_language}`
        });
    }

    const options = {
        method: "POST",
        headers: API_HEADERS,
        body: JSON.stringify({
            "submittedAt": `${timestamp}`,
            "fields": fields,
            "context": {
                "hutk": cookie ? `${cookie}` : null,
                "pageUri": `${pageUri}`,
                "pageName": "Home Page"
            }
        })
    };



    try {
        const data = yield call(doApiCall, requestURL, options);


        if (data && data.status && data.status === "error") {
            yield put(onSubmitFormDataError({
                hubspotBusy: false,
                hubspotResult: `Something went wrong, (${data.errors[0].message})`,
                hubspotSuccess: 'error'
            }));
        } else {
            const str = `${data && data.inlineMessage}`;
            const StrippedString = str.replace(/(<([^>]+)>)/ig, "").replace("&nbsp;", " ");

            yield put(onSubmitFormDataSuccess({
                hubspotBusy: false,
                hubspotResult: `${StrippedString}`,
                hubspotSuccess: 'success'
            }));
        }

    } catch (err) {
        yield put(onSubmitFormDataError({
            hubspotBusy: false,
            hubspotResult: `Something went wrong.`,
            hubspotSuccess: 'error'
        }));
    }


}

// SELECTORS
const getThisSite = state => state.hubspot;
export const makeSelectHubspot = createSelector(
    [getThisSite],
    hubspot => {
        return hubspot;
    }
);
