import { environment } from "../../../config/environment";
import { doApiCall } from "./ApiCall";

const STATIC_API_URL = `${
  environment.APP_URL.endsWith("/")
    ? environment.APP_URL
    : `${environment.APP_URL}/`
}`;


interface StaticApiCallOptions {
  type?: 'json' | 'markdown';
}

const cache = new Map<string, Promise<any>>();

function staticApiCall<R = any>(
  path: string,
  { type = 'json' }: StaticApiCallOptions = {}
): Promise<R> {

  if(cache.has(path)){
    return cache.get(path);
  }

  let url = STATIC_API_URL;

  const requestURL =
    `${url}${path.startsWith("/") ? path.substr(1) : path}`;

  let cType = 'application/json';

  switch(type){
    case 'json':
      cType = 'application/json'
      break;
    case 'markdown':
      cType = 'text/markdown'
      break;
    default:
      throw Error(`Unknown response type: ${type}`);
  }

  const options: RequestInit = {
    method: 'GET',
    headers: {
      "Content-Type": cType,
    },
  };

  const call = doApiCall<R>(requestURL, options);

  cache.set(path, call);

  return call;
}

export const StaticApi = {
  getJson: (path: string) => staticApiCall(path),
  getBuildVersion: () => staticApiCall<{version: string}>('buildVersion.json'),
  getMarkdown: (path: string) => staticApiCall<string>(path, {type: 'markdown'}),
};