import { environment } from "../config/environment";
import { IProductItemView } from "src/interfaces/iProduct";
import { eventType } from './eventTypes';
import { brandType } from './brandTypes';
import { findTaxonAtDeepestLevel } from "./helpers";

export const eeDetail = (product:IProductItemView) => {

  const { APP_FLAVOR } = environment;

  const taxonCode = findTaxonAtDeepestLevel(product.taxonCodes)

  const productObj = {
      name:`${product.name}`,
      id: `${product.id}`,
      price: `${(product.price/100).toFixed(2)}`,
      brand: brandType[APP_FLAVOR] ?? '',
      category: taxonCode.slug.replace('categories',''),
      variant: `${product.name}`
    };

  return {
    event:eventType.EEDetail,
    ecommerce:{
      detail:{
        products:[productObj]
      }
    }
  }
}