import { environment } from "../config/environment";
import { eventType } from './eventTypes';
import { brandType } from './brandTypes';
import { cartProductImpressions } from './helpers';
import { trackingCartItemsInterface, transactionArgs } from './interfaces';


export const eePurchase = (cartItems:trackingCartItemsInterface[], transaction:transactionArgs, subscription: boolean = false) => {

  const { APP_FLAVOR } = environment;
  const cartItemImpressions = cartProductImpressions(cartItems);
  const affiliation = subscription ? 'subscription' : 'shop';

  return {
    event:eventType.EEPurchase,
    pagePath:'/checkout/payment/thank_you',
    pageTitle:`Purchase - ${brandType[APP_FLAVOR] ?? ''}`,
    ecommerce:{
      currencyCode: `${transaction.currency}`,
      purchase:{
        actionField:{
          affiliation: affiliation,
          id:`${affiliation}-${transaction.id}`,
          revenue:`${(transaction.revenue/100).toFixed(2)}`,
          tax:`${(transaction.tax/100).toFixed(2)}`,
          shipping:`${(transaction.shipping/100).toFixed(2)}`,
          coupon:`${(transaction.coupon/100).toFixed(2)}`,
        },
        products:cartItemImpressions
      }
    }
  }
}
