import { environment } from "../../../config/environment";
import { doApiCall } from "./ApiCall";

const CMS_API_URL = `${
  environment.CMS_API.endsWith("/")
    ? environment.CMS_API
    : `${environment.CMS_API}/`
}`;
const CMS_API_PREVIEW_URL = `${
  environment.CMS_PREVIEW.endsWith("/")
    ? environment.CMS_PREVIEW
    : `${environment.CMS_PREVIEW}/`
}`;


interface CmsApiCallOptions {
  body?: any;
  query?: any;
  headers?: any;
  preview?: boolean;
}

function cmsApiGet<R = any>(
  path: string,
  options: CmsApiCallOptions = {}
): Promise<R> {
  return cmsApiCall("GET", path, options);
}

function cmsApiCall<R = any>(
  method: "GET" | "POST",
  path: string,
  { body, query, headers, preview }: CmsApiCallOptions = {}
): Promise<R> {
  let cmsApiUrl = preview === true ? CMS_API_PREVIEW_URL : CMS_API_URL;

  let queryString: string | null = null;

  if (query) {
    queryString = Object.keys(query)
      .map((k) => {
        return `${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`;
      })
      .join("&");
  }

  const requestURL =
    `${cmsApiUrl}${path.startsWith("/") ? path.substr(1) : path}` +
    (queryString ? `?${queryString}` : "");

  const options: RequestInit = {
    method,
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: body ? JSON.stringify(body) : undefined,
  };

  return doApiCall(requestURL, options);
}

export const CmsApi = {
  getResource: (path: string, preview = false) => cmsApiGet(path, { preview }),
};