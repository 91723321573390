import { environment } from "../config/environment";
import { eventType } from './eventTypes';
import { brandType } from './brandTypes';
import { cartProductImpressions } from './helpers';
import { trackingCartItemsInterface } from './interfaces';


export const eeCheckoutDelivery = (cartItems:trackingCartItemsInterface[]) => {

  const { APP_FLAVOR } = environment;
  const cartItemImpressions = cartProductImpressions(cartItems);

  return {
    event:eventType.EECheckoutDelivery,
    pagePath:'/checkout/shipping',
    pageTitle:`Delivery - ${brandType[APP_FLAVOR] ?? ''}`,
    ecommerce:{
      checkout:{
        actionField:{
          step:2
        },
        products:cartItemImpressions
      }
    }
  }
}
