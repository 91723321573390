import { call, put, takeLatest } from "redux-saga/effects";
import { fromJS } from "immutable";
import { createSelector } from "reselect";

const initialState = fromJS({});

const UPDATE_SUBSCRIPTION = "app/App/UPDATE_SUBSCRIPTION";
const UPDATE_SUBSCRIPTION_SUCCESS = "app/App/UPDATE_SUBSCRIPTION_SUCCESS";
const UPDATE_SUBSCRIPTION_ERROR = "app/App/UPDATE_SUBSCRIPTION_ERROR";

const USER_LOGOUT = "app/App/USER_LOGOUT";
const GET_SITE = "app/App/GET_SITE";
const CLEAR_CART = "app/App/CLEAR_CART";

// actions
export function onUpdateSubscription(payload) {
  return {
    type: UPDATE_SUBSCRIPTION,
    payload
  };
}

export function onUpdateSubscriptionSuccess(subscription) {
  return {
    type: UPDATE_SUBSCRIPTION_SUCCESS,
    subscription
  };
}

export function onUpdateSubscriptionError(error) {
  return {
    type: UPDATE_SUBSCRIPTION_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case CLEAR_CART:
      return {};
    case UPDATE_SUBSCRIPTION:
      return state;
    case UPDATE_SUBSCRIPTION_SUCCESS:
      return action.subscription;
    case UPDATE_SUBSCRIPTION_ERROR:
      return state;
    case USER_LOGOUT:
      return {};
    case GET_SITE:
      return state;

    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(UPDATE_SUBSCRIPTION, updateSubscription);
}

const updateSubscriptionObj = args => {
  return new Promise((resolve, reject) => {
    if (args.payload) {
      // success
      resolve(args.payload);
    } else {
      // error
      reject("could not update");
    }
  });
};

export function* updateSubscription(args) {
  try {
    const data = yield call(updateSubscriptionObj, args);
    yield put(onUpdateSubscriptionSuccess(data));
  } catch (err) {
    yield put(onUpdateSubscriptionError(err));
  }
}

// SELECTORS
const getThisSite = state => state.subscription;
export const makeSelectSubscription = createSelector(
  [getThisSite],
  subscription => {
    return subscription;
  }
);
