export enum eventType {
  pageView = 'pageView',
  EEImpression = 'EEImpression',
  EEClick = 'EEClick',
  EEDetail = 'EEDetail',
  EEAddToBasket = 'EEAddToBasket',
  EERemoveFromBasket = 'EERemoveFromBasket',
  EECheckoutUserDetails = 'EECheckoutUserDetails',
  EEViewCheckoutBasket = 'EEViewCheckoutBasket',
  EECheckoutDelivery = 'EECheckoutDelivery',
  EECheckoutPayment = 'EECheckoutPayment',
  EEPurchase = 'EEPurchase',
  EECheckoutGift = 'EECheckoutGift',
  Clear = 'Clear',
  NewsletterSignUp = 'NewsletterSignUp',
  SearchResults = 'SearchResults',
  EESubscriptionPurchase = 'EESubscriptionPurchase',
  EESubscriptionPaymentFail = 'EESubscriptionPaymentFail',
}
