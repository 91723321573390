import { call, put, takeLatest, throttle } from "redux-saga/effects";

import { fromJS } from "immutable";

import { createSelector } from "reselect";
import { ShopApi } from "./api";
import { updateObj } from "../../helpers/gen";
import { lsx as ls } from "../../helpers/localStorage";

const initialState = fromJS({});

export const STRIPE_PAYMENT_INTENT = "app/App/STRIPE_PAYMENT_INTENT";
export const STRIPE_PAYMENT_INTENT_SUCCESS = "app/App/STRIPE_PAYMENT_INTENT_SUCCESS";
export const STRIPE_PAYMENT_INTENT_ERROR = "app/App/STRIPE_PAYMENT_INTENT_ERROR";

export const STRIPE_PAYMENT_CHECK_INTENT = "app/App/STRIPE_PAYMENT_CHECK_INTENT";
export const STRIPE_PAYMENT_CHECK_INTENT_SUCCESS = "app/App/STRIPE_PAYMENT_CHECK_INTENT_SUCCESS";
export const STRIPE_PAYMENT_CHECK_INTENT_ERROR = "app/App/STRIPE_PAYMENT_CHECK_INTENT_ERROR";

export const STRIPE_CREATE_CUSTOMER = "app/App/STRIPE_CREATE_CUSTOMER";
export const STRIPE_CREATE_CUSTOMER_SUCCESS = "app/App/STRIPE_CREATE_CUSTOMER_SUCCESS";
export const STRIPE_CREATE_CUSTOMER_ERROR = "app/App/STRIPE_CREATE_CUSTOMER_ERROR";

export const STRIPE_CHARGE_CUSTOMER = "app/App/STRIPE_CHARGE_CUSTOMER";
export const STRIPE_CHARGE_CUSTOMER_SUCCESS = "app/App/STRIPE_CHARGE_CUSTOMER_SUCCESS";
export const STRIPE_CHARGE_CUSTOMER_ERROR = "app/App/STRIPE_CHARGE_CUSTOMER_ERROR";

export const UPDATE_STRIPE = "app/App/UPDATE_STRIPE";
export const UPDATE_STRIPE_SUCCESS = "app/App/UPDATE_STRIPE_SUCCESS";
export const UPDATE_STRIPE_ERROR = "app/App/UPDATE_STRIPE_ERROR";

export const CLEAR_STRIPE = "app/App/CLEAR_STRIPE";
export const CLEAR_STRIPE_SUCCESS = "app/App/CLEAR_STRIPE_SUCCESS";
export const CLEAR_STRIPE_ERROR = "app/App/CLEAR_STRIPE_ERROR";

const GET_SITE = "app/App/GET_SITE";

// actions
// STRIPE_PAYMENT_INTENT
export function onStripePaymentIntent(payload) {
    return {
        type: STRIPE_PAYMENT_INTENT,
        payload
    };
}

export function onStripePaymentIntentSuccess(result) {
    return {
        type: STRIPE_PAYMENT_INTENT_SUCCESS,
        result
    };
}

export function onStripePaymentIntentError(error) {
    return {
        type: STRIPE_PAYMENT_INTENT_ERROR,
        error
    };
}

// STRIPE_PAYMENT_CHECK_INTENT
export function onStripePaymentCheckIntent(payload) {
    return {
        type: STRIPE_PAYMENT_CHECK_INTENT,
        payload
    };
}

export function onStripePaymentCheckIntentSuccess(result) {
    return {
        type: STRIPE_PAYMENT_CHECK_INTENT_SUCCESS,
        result
    };
}

export function onStripePaymentCheckIntentError(error) {
    return {
        type: STRIPE_PAYMENT_CHECK_INTENT_ERROR,
        error
    };
}

// STRIPE_CREATE_CUSTOMER
export function onStripeCreateCustomer(payload) {
    return {
        type: STRIPE_CREATE_CUSTOMER,
        payload
    };
}

export function onStripeCreateCustomerSuccess(result) {
    return {
        type: STRIPE_CREATE_CUSTOMER_SUCCESS,
        result
    };
}

export function onStripeCreateCustomerError(error) {
    return {
        type: STRIPE_CREATE_CUSTOMER_ERROR,
        error
    };
}

// STRIPE_CHARGE_CUSTOMER
export function onStripeChargeCustomer(payload) {
    return {
        type: STRIPE_CHARGE_CUSTOMER,
        payload
    };
}

export function onStripeChargeCustomerSuccess(result) {
    return {
        type: STRIPE_CHARGE_CUSTOMER_SUCCESS,
        result
    };
}

export function onStripeChargeCustomerError(error) {
    return {
        type: STRIPE_CHARGE_CUSTOMER_ERROR,
        error
    };
}

// UPDATE_STRIPE
export function onUpdateStripe(payload) {
    return {
        type: UPDATE_STRIPE,
        payload
    };
}

export function onUpdateStripeSuccess(result) {
    return {
        type: UPDATE_STRIPE_SUCCESS,
        result
    };
}

export function onUpdateStripeError(error) {
    return {
        type: UPDATE_STRIPE_ERROR,
        error
    };
}

// CLEAR_STRIPE
export function onClearStripe() {
    return {
        type: CLEAR_STRIPE
    };
}

export function onClearStripeSuccess() {
    return {
        type: UPDATE_STRIPE_SUCCESS
    };
}

export function onClearStripeError(error) {
    return {
        type: UPDATE_STRIPE_ERROR,
        error
    };
}



// REDUCERS
export function reducer(state = initialState, action: any = {}) {
    switch (action.type) {
        // PREPARE CHECKOUT FORM
        case STRIPE_PAYMENT_INTENT:
            return {};
        case STRIPE_PAYMENT_INTENT_SUCCESS:
            return updateObj(state, { payment_intent: action.result });
        case STRIPE_PAYMENT_INTENT_ERROR:
            return action.error;

        // CHECK
        case STRIPE_PAYMENT_CHECK_INTENT:
            return state;
        case STRIPE_PAYMENT_CHECK_INTENT_SUCCESS:
            return updateObj(state, { payment_intent_result: action.result });
        case STRIPE_PAYMENT_CHECK_INTENT_ERROR:
            return action.error;

        // STRIPE_CREATE_CUSTOMER
        case STRIPE_CREATE_CUSTOMER:
            return state;
        case STRIPE_CREATE_CUSTOMER_SUCCESS:
            return updateObj(state, { customer: action.result });
        case STRIPE_CREATE_CUSTOMER_ERROR:
            return action.error;

        // STRIPE_CHARGE_CUSTOMER
        case STRIPE_CHARGE_CUSTOMER:
            return state;
        case STRIPE_CHARGE_CUSTOMER_SUCCESS:
            return updateObj(state, { payment_intent: action.result });
        case STRIPE_CHARGE_CUSTOMER_ERROR:
            return updateObj(state, { charge: action.error });

        // STRIPE PAYMENT PROCESS
        case UPDATE_STRIPE:
            return state;
        case UPDATE_STRIPE_SUCCESS:
            return updateObj(state, { payment_process: action.result });
        case UPDATE_STRIPE_ERROR:
            return state;

        // CLEAR PAYMENT PROCESS
        case CLEAR_STRIPE:
            return {};
        case CLEAR_STRIPE_SUCCESS:
            return {};
        case CLEAR_STRIPE_ERROR:
            return {};

        case GET_SITE:
            return state;

        default:
            return state;
    }
}

// SAGA
export function* saga() {
    yield takeLatest(STRIPE_PAYMENT_INTENT, stripePaymentIntent);
    yield takeLatest(STRIPE_PAYMENT_CHECK_INTENT, stripePaymentCheckIntent);
    yield takeLatest(STRIPE_CREATE_CUSTOMER, stripeCreateCustomer);
    yield takeLatest(STRIPE_CHARGE_CUSTOMER, stripeChargeCustomer);
    yield throttle(500, UPDATE_STRIPE, updateStripe);
    yield throttle(500, CLEAR_STRIPE, clearStripe);
}

export function* stripePaymentIntent(args) {


    try {
        let data: any;

        //SUBSCRIPTION INTENT
        if (
            args.payload.subscriptionId &&
            args.payload.userId && args.payload.userId > 0 &&
            args.payload.productId && args.payload.productId > 0 &&
            args.payload.addressId && args.payload.addressId > 0
        ) {
            data = yield call(
                ShopApi.updateSubscriptionStripeIntent,
                args.payload.userId,
                args.payload.subscriptionId,
                args.payload.productId,
                args.payload.addressId
            );
        }else{
            data = yield call(
                ShopApi.updateCartProcessPayment,
                args.payload.cartId,
                {cardId: args.payload.cardId ?? undefined}
            );
        }

        if (data && data.result && data.result.code && data.result.code === "200.300.404") {
            yield put(onStripePaymentIntentError(data));
        } else {
            if (data && data.reference) {
                setTimeout(() => {
                    ls.set("stripe_subscription_ref", data.reference);
                }, 100);

            }

            if (data.stripe) {
                yield put(onStripePaymentIntentSuccess(data.stripe));
            } else {
                yield put(onStripePaymentIntentSuccess(data));
            }


        }

    } catch (err) {
        yield put(onStripePaymentIntentError(err));
    }

}

export function* stripePaymentCheckIntent(args) {


    try {
        let data: any;

        if (args.payload.refId && args.payload.type && args.payload.custId) {
            data = yield call(
                ShopApi.updateSubscriptionCheckStripeIntent,
                args.payload.custId,
                {
                    intentId: args.payload.intentId,
                    refId: args.payload.refId,
                    type: args.payload.type,
                }
            );
        } else {
            data = yield call(
                ShopApi.updateCartCheckStripeIntent,
                args.payload.cartId,
                args.payload.intentId
            );
        }

        if (data && data.result && data.result.code && data.result.code === "200.300.404") {
            yield put(onStripePaymentCheckIntentError(data));
        } else {
            yield put(onStripePaymentCheckIntentSuccess(data));
        }

    } catch (err) {
        yield put(onStripePaymentCheckIntentError(err));
    }

}

export function* stripeCreateCustomer(args) {
    throw Error("Stripe: stripeCreateCustomer is deprecated");
}

export function* stripeChargeCustomer(args) {
    throw Error("Stripe: stripeChargeCustomer is deprecated");
}

const updateStripeObj = args => {
    return new Promise((resolve, reject) => {
        if (args.payload) {
            // success
            resolve(args.payload);
        } else {
            // error
            reject("could not update");
        }
    });
};

export function* updateStripe(args) {
    try {
        const data = yield call(updateStripeObj, args);
        yield put(onUpdateStripeSuccess(data));
    } catch (err) {
        yield put(onUpdateStripeError(err));
    }
}

export function* clearStripe() {
    try {
        yield put(onClearStripeSuccess());
    } catch (err) {
        yield put(onClearStripeError(err));
    }
}


// SELECTORS
const getThisSite = state => state.stripe;
export const makeSelectStripe = createSelector(
    [getThisSite],
    stripe => {
        return stripe;
    }
);
