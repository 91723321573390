import { call, put, takeLatest, throttle } from "redux-saga/effects";
import { fromJS } from "immutable";
import { updateObj } from "../../helpers/gen";
import { createSelector } from "reselect";

const initialState = fromJS({});

const SEARCH = "app/App/SEARCH";
const SEARCH_SUCCESS = "app/App/SEARCH_SUCCESS";
const SEARCH_ERROR = "app/App/SEARCH_ERROR";
const UPDATE_SEARCH = "app/App/UPDATE_SEARCH";
const UPDATE_SEARCH_SUCCESS = "app/App/UPDATE_SEARCH_SUCCESS";
const UPDATE_SEARCH_ERROR = "app/App/UPDATE_SEARCH_ERROR";

// actions
export function onSearch(terms) {
    return {
        type: SEARCH,
        terms
    };
}

export function onSearchSuccess(result) {
    return {
        type: SEARCH_SUCCESS,
        result
    };
}

export function onSearchError(error) {
    return {
        type: SEARCH_ERROR,
        error
    };
}

export function onUpdateSearch(args) {
    return {
        type: UPDATE_SEARCH,
        args
    };
}

export function onUpdateSearchSuccess(search) {
    return {
        type: UPDATE_SEARCH_SUCCESS,
        search
    };
}

export function onUpdateSearchError(error) {
    return {
        type: UPDATE_SEARCH_ERROR,
        error
    };
}

// REDUCERS
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case SEARCH:
            return updateObj(state, { terms: action.terms });
        case SEARCH_SUCCESS:
            return updateObj(state, { result: action.result });
        case SEARCH_ERROR:
            return updateObj(state, { result: action.error });
        case UPDATE_SEARCH:
            return updateObj(state, action.args);
        case UPDATE_SEARCH_SUCCESS:
            return state;
        case UPDATE_SEARCH_ERROR:
            return state;
        default:
            return state;
    }
}

// SAGA
export function* saga() {
    yield takeLatest(SEARCH, search);
    yield throttle(500, UPDATE_SEARCH, updateSearch);
}

const updateSearchObj = args => {
    return new Promise((resolve, reject) => {
        if (args.payload) {
            // success
            resolve(args.payload);
        } else {
            // error
            reject("could not update");
        }
    });
};

export function* search(args) {

    /*
    const requestURL = `${API_URL}shop/config/${args && args.payload && args.payload.code ? args.payload.code : "za"}`;
    const API_HEADERS = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${API_TOKEN()}`
    };
    const options = {
        method: "GET",
        headers: API_HEADERS
    };

    try {
        const data = yield call(request, requestURL, options);
        yield put(onSearchSuccess(data.config));
    } catch (err) {
        yield put(onSearchError(err));
    }
    */
}

export function* updateSearch(args) {
    try {
        const data = yield call(updateSearchObj, args);
        yield put(onUpdateSearchSuccess(data));
    } catch (err) {
        //yield put(onUpdateSearchError(err));
    }
}


// SELECTORS
const getThisSite = state => state.search;
export const makeSelectSearch = createSelector(
    [getThisSite],
    search => {
        return search;
    }
);
