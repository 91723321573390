import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import { createSelector } from "reselect";
import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";
import { ShopApi } from "./api";

import { IAction } from "../../interfaces/iAction";
import { environment } from "../../config/environment";

const initialState = fromJS({});

// get single product
const GET_PRODUCT: string = "app/ProductSingle/GET_PRODUCT";
const GET_PRODUCT_SUCCESS: string = "app/ProductSingle/GET_PRODUCT_SUCCESS";
const GET_PRODUCT_ERROR: string = "app/ProductSingle/GET_PRODUCT_ERROR";

const GET_PRODUCT_IN_CHANNEL: string = "app/ProductSingle/GET_PRODUCT_IN_CHANNEL";
const GET_PRODUCT_IN_CHANNEL_SUCCESS: string = "app/ProductSingle/GET_PRODUCT_IN_CHANNEL_SUCCESS";
const GET_PRODUCT_IN_CHANNEL_ERROR: string = "app/ProductSingle/GET_PRODUCT_IN_CHANNEL_ERROR";

const CLEAR_PRODUCT: string = "app/ProductSingle/CLEAR_PRODUCT";
const CLEAR_PRODUCT_SUCCESS: string = "app/ProductSingle/CLEAR_PRODUCT_SUCCESS";
const CLEAR_PRODUCT_ERROR: string = "app/ProductSingle/CLEAR_PRODUCT_ERROR";

const GET_SITE: string = "app/App/GET_SITE";

// actions
export function onGetProduct(payload: any): IAction {
  return {
    type: GET_PRODUCT,
    payload
  };
}

export function onGetProductSuccess(payload: any): IAction {
  return {
    type: GET_PRODUCT_SUCCESS,
    payload
  };
}

export function onGetProductError(payload: any): IAction {
  return {
    type: GET_PRODUCT_ERROR,
    payload
  };
}

export function onGetProductInChannel(payload: any): IAction {
  return {
    type: GET_PRODUCT_IN_CHANNEL,
    payload
  };
}

export function onGetProductInChannelSuccess(payload: any): IAction {
  return {
    type: GET_PRODUCT_IN_CHANNEL_SUCCESS,
    payload
  };
}

export function onGetProductInChannelError(payload: any): IAction {
  return {
    type: GET_PRODUCT_IN_CHANNEL_ERROR,
    payload
  };
}

export function onClearProduct(payload: any): IAction {
  return {
    type: CLEAR_PRODUCT,
    payload
  };
}

export function onClearProductSuccess(payload: any): IAction {
  return {
    type: CLEAR_PRODUCT_SUCCESS,
    payload
  };
}

export function onClearProductError(payload: any): IAction {
  return {
    type: CLEAR_PRODUCT_ERROR,
    payload
  };
}

// REDUCERS

export function reducer(state: any = {}, action: IAction = { type: "", payload: {} }): any {
  switch (action.type) {
    case GET_PRODUCT:
      return {};
    case GET_PRODUCT_SUCCESS:
      return action.payload;
    case GET_PRODUCT_ERROR:
      return { notFound: true };
    case GET_PRODUCT_IN_CHANNEL:
      return state;
    case GET_PRODUCT_IN_CHANNEL_SUCCESS:
      const channelProducts = state && state.productsInChannels ? [...state.productsInChannels, action.payload] : [action.payload];
      return {...state, productsInChannels: channelProducts};
    case GET_PRODUCT_IN_CHANNEL_ERROR:
      return state;
    case CLEAR_PRODUCT:
      return {};
    case CLEAR_PRODUCT_SUCCESS:
      return {};
    case CLEAR_PRODUCT_ERROR:
      return state;
    case GET_SITE:
      return {};
    // default
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_PRODUCT, getProduct);
  yield takeEvery(GET_PRODUCT_IN_CHANNEL, getProductInChannel);
}

// LIST PRODUCTS

export function* getProduct(args: any) {

  const channelCode = args?.payload?.channelCode ?? environment.APP_DEFAULT_CHANNEL;
  const codeOrSlug = args?.payload?.code ?? args?.payload?.slug;

  EventEmitter.dispatch("LOADING_START", `data:${codeOrSlug}`);

  try {

    if(!codeOrSlug) throw Error("Attempting to load product without supplying code or slug");

    const data = args?.payload?.code
      ? yield call(ShopApi.getProductByCode, args.payload.code, channelCode)
      : yield call(ShopApi.getProductBySlug, args.payload.slug, channelCode);

    yield put(onGetProductSuccess(data));
    EventEmitter.dispatch("LOADING_DONE", `data:${codeOrSlug}`);
  } catch (err) {
    yield put(onGetProductError(err));
  }
}

export function* getProductInChannel(args: any) {

  const channelCode = args.payload.channelCode ?? environment.APP_DEFAULT_CHANNEL;
  const slug = args.payload.slug;

  try {
    const data = yield call(ShopApi.getProductBySlug, slug, channelCode);

    yield put(onGetProductInChannelSuccess(data));

  } catch (err) {
    // error
    yield put(onGetProductInChannelError(err));
  }
}

// SELECTORS
const getThisProduct = (state: any) => state.product;
export const makeSelectProduct = createSelector(
  [getThisProduct],
  product => {
    return product;
  }
);
