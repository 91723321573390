
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import {initializeTagManager} from './GoogleDataLayer/GoogleDataLayer';
import React, {Suspense, lazy} from "react";
import { render } from "react-dom";
import throttle from "lodash/throttle";
import { Provider } from "react-redux";
import { loadState, saveState } from "./helpers/localStorage";
import configureStore from "./store/configureStore";
import './i18n';

initializeTagManager();

const App = lazy(() => import(`./App`));

//var Symbol = require('es6-symbol/polyfill');
require('es6-symbol/implement');

// Initialize store
const persistentState = loadState();
export const store = configureStore(persistentState);

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);



render(
  <Provider store={store}>
    <Suspense fallback={<div style={{
      textAlign:'center',
      fontWeight:'100',
      padding:'40px',
      color:'#ccc',
      position:'fixed',
      top:'50%',
      zIndex:'99999',
      left:'0px',
      right:'0px',
      letterSpacing:'1px',
      fontFamily:'sans-serif',
      fontSize:'15px'
    }}>loading site</div>}>
      <App />
    </Suspense>


  </Provider>,
  document.getElementById("root")
);

navigator && navigator.serviceWorker && navigator.serviceWorker.getRegistrations().then(function (registrations) {
  for (let registration of registrations) {
    registration.unregister()
  }
})