/* eslint-disable */
import React from "react";
import { IFlavorConfigFactory } from "../flavorConfig";
import {
  FacebookIcn,
  InstagramIcn, notFoundBt,
  saHomeHighlights,
  SA_Banner,
  SiteLogo,
  TwitterIcn, videoSplash,
  visit_garden,
  visit_hotel,
  visit_restaurants,
  visit_spa,
  YoutubeIcn
} from "./babylonstoren.assets";


export const BabylonstorenZAConfig: IFlavorConfigFactory = () => {
  const siteCode = "za";

  return {
    siteClass: "BABYLONSTOREN",
    fonts: [],
    imgNotFound: notFoundBt,
    features:{
      deliveryAreas:true
    },
    emails: {
      deliveries: `deliveries@babylonstoren.com`,
      orders: "elsa@babylonstoren.com",
      orders2: "",
    },
    personalShopper: {
      name: "US",
      email: "deliveries@babylonstoren.com",
    },
    hubspot: {
      newsletterSignup: {
        id: "9bec6816-1a63-4943-86ba-931a5275ddef",
      },
    },
    Header: {
      siteName: [<span key="shop-title-span">SHOP </span>, `BABYLONSTOREN`],
      siteTitle: "Shop Babylonstoren",
      phoneNumber: "+27 (0) 21 300 3919",
      homeLinkLabel: "VISIT BABYLONSTOREN",
      homeLinkUrl: "http://www.babylonstoren.com/",
      title: "Babylonstoren",
      bannerLinkColor: "#333",
    },
    Footer: {
      copyright: "Babylonstoren",
      logo: SiteLogo,
      socialMedia: [
        {
          network: "facebook",
          url: "https://www.facebook.com/Babylonstoren",
          icn: FacebookIcn,
        },
        {
          network: "twitter",
          url: "https://twitter.com/babylonstoren",
          icn: TwitterIcn,
        },
        {
          network: "instagram",
          url: "https://instagram.com/babylonstoren",
          icn: InstagramIcn,
        },
        {
          network: "youtube",
          url: "https://www.youtube.com/channel/UCntUzv42vmt7P9Xmcf5a_-w",
          icn: YoutubeIcn,
        },
      ],
      footerLinks: [
        {
          label: "Customer Care",
          url: "/customer-care",
          sites: [],
        },
        {
          label: "Cancellation and Changes",
          url: "/cancellation-and-changes",
          sites: [],
        },
        {
          label: "Exchange and Returns",
          url: "/exchange-and-returns",
          sites: [],
        },
        {
          label: "Delivery",
          url: "/delivery",
          sites: [],
        },
        {
          label: "ABOUT BABYLONSTOREN",
          url: "https://www.babylonstoren.com/",
          sites: [],
        },
        {
          label: "CAREERS",
          url: "https://babylonstoren.com/careers",
          sites: [],
        },
        {
          label: "PAIA Manual",
          url: "https://babylonstoren.com/assets/210707-bt-paia-manual.pdf",
          sites: [],
        }
      ],
    },
    GiftCard__: {
      title: "Not sure what gift they’ll like most? Let them choose.",
      txt: "Whether it’s something from the pantry, cellar or farm shop, our products are a reflection of our contemporary, unhurried farm lifestyle.",
      lbl: "Buy Now",
    },
    NewsletterSignup: {
      ctaTitle: "Looking for something special?",
      ctaText:
        "Let us help you find that one-of-a-kind gift. Email us for assistance with selecting the right product or placing your order.",
      ctaLink: "mailto:deliveries@babylonstoren.com?subject=Personal Shopping",
      ctaLinkLabel: "EMAIL US",
    },
    SubscriptionSignup: {
      title: "Babylonstoren Seasons",
      subTitle: "SUBSCRIPTION",
      txt: "Unbox a handpicked selection of our finest products - seasonal and curated to delight the senses.",
      link: "/subscriptions/babylonstoren-seasons/signup",
      lbl: "READ MORE",
    },
    HomePage: {
      videoSplash: videoSplash,
      homeHighlights: saHomeHighlights,
      banner: {
        img: SA_Banner,
        overlay: false,
        whiteOverlay: false,
        text: (
          <div className="bottomLeftZa">
            <h1>
              <span>AND WE'RE BACK</span>
              <span>(AGAIN)</span>
              <span></span>
              <span className="btn">SHOP NOW</span>
            </h1>
          </div>
        ),
        link: `/${siteCode}/categories/wine`,
        eventName: "wine-aug-2020-za",
      },
      video_: {
        splash: videoSplash,
        id: "QyVNOR_F7aI",
        title: "2019 Mourvèdre Rosé",
        subTitle: "Fill your glass with rosé this spring",
      },
      sectionFeatured: {
        title: "Tea party essentials",
        subTitle: "Babylonstoren",
        img: saHomeHighlights,
        productsSlug: "promote/featured",
        link: `/${siteCode}/categories/pantry#tea`,
      },
      sectionBestSellers: {
        title: "BESTSELLERS",
        subTitle: "THIS SEASON’S",
        productsSlug: "promote/sa-home-main-feature",
      },
      sectionWhileYouAreHere: {
        title: "MORE FROM OUR FARM",
        subTitle: "STAY A LITTLE LONGER AND DISCOVER",
        sections: [
          {
            title: "Visit our Spa",
            link: "https://www.babylonstoren.com/spa",
            img: visit_spa,
          },
          {
            title: "Eat at our Restaurants",
            link: "https://www.babylonstoren.com/babel",
            img: visit_restaurants,
          },
          {
            title: "Stay at our Hotel",
            link: "https://www.babylonstoren.com/hotel",
            img: visit_hotel,
          },
          {
            title: "Visit our Garden",
            link: "https://www.babylonstoren.com/visit-our-garden",
            img: visit_garden,
          },
        ],
      },
    },
    Checkout: {
      needAssistance: (
        <li className="needAssistance_config">
          <p>We offer FREE delivery nationwide.</p>
          <p>Shipping takes 2 – 5 days, depending on the destination.</p>
        </li>
      ),
    },
  };
};
