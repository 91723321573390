import { call, put, takeLatest } from "redux-saga/effects";

import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";

import { createSelector } from "reselect";
import { ShopApi } from "./api";

const initialState = fromJS({});

const GET_CREDIT_CARDS = "app/App/GET_CREDIT_CARDS";
const GET_CREDIT_CARDS_SUCCESS = "app/App/GET_CREDIT_CARDS_SUCCESS";
const GET_CREDIT_CARDS_ERROR = "app/App/GET_CREDIT_CARDS_ERROR";

const GET_ALL_CREDIT_CARDS = "app/App/GET_ALL_CREDIT_CARDS";
const GET_ALL_CREDIT_CARDS_SUCCESS = "app/App/GET_ALL_CREDIT_CARDS_SUCCESS";
const GET_ALL_CREDIT_CARDS_ERROR = "app/App/GET_ALL_CREDIT_CARDS_ERROR";

const REMOVE_CREDIT_CARD = "app/App/REMOVE_CREDIT_CARD";
const REMOVE_CREDIT_CARD_SUCCESS = "app/App/REMOVE_CREDIT_CARD_SUCCESS";
const REMOVE_CREDIT_CARD_ERROR = "app/App/REMOVE_CREDIT_CARD_ERROR";

// user updates
const USER_LOGOUT = "app/App/USER_LOGOUT";

// actions
export function onGetCreditCards(payload) {
  return {
    type: GET_CREDIT_CARDS,
    payload
  };
}

export function onGetCreditCardsSuccess(creditCards) {
  return {
    type: GET_CREDIT_CARDS_SUCCESS,
    creditCards
  };
}

export function onGetCreditCardsError(error) {
  return {
    type: GET_CREDIT_CARDS_ERROR,
    error
  };
}

export function onGetAllCreditCards(payload) {
  return {
    type: GET_ALL_CREDIT_CARDS,
    payload
  };
}

export function onGetAllCreditCardsSuccess(creditCards) {
  return {
    type: GET_ALL_CREDIT_CARDS_SUCCESS,
    creditCards
  };
}

export function onGetAllCreditCardsError(error) {
  return {
    type: GET_ALL_CREDIT_CARDS_ERROR,
    error
  };
}

// remove card
export function onRemoveCreditCard(payload) {
  return {
    type: REMOVE_CREDIT_CARD,
    payload
  };
}

export function onRemoveCreditCardSuccess(creditCards) {
  return {
    type: REMOVE_CREDIT_CARD_SUCCESS,
    creditCards
  };
}

export function onRemoveCreditCardError(error) {
  return {
    type: REMOVE_CREDIT_CARD_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case GET_CREDIT_CARDS:
      return state;
    case GET_CREDIT_CARDS_SUCCESS:
      return action.creditCards ? action.creditCards : {};
    case GET_CREDIT_CARDS_ERROR:
      return state;
    case GET_ALL_CREDIT_CARDS:
      return state;
    case GET_ALL_CREDIT_CARDS_SUCCESS:
      return action.creditCards ? action.creditCards : {};
    case GET_ALL_CREDIT_CARDS_ERROR:
      return state;
    case REMOVE_CREDIT_CARD:
      return state;
    case REMOVE_CREDIT_CARD_SUCCESS:
      return action.creditCards ? action.creditCards : state;
    case REMOVE_CREDIT_CARD_ERROR:
      return state;
    case USER_LOGOUT:
      return {};
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_CREDIT_CARDS, getCreditCards);
  yield takeLatest(GET_ALL_CREDIT_CARDS, getAllCreditCards);
  yield takeLatest(REMOVE_CREDIT_CARD, removeCreditCard);
}

export function* getCreditCards(args) {

  const userId = args.payload.userId;
  const channelCode = args.payload.channelCode;

  try {

    const data = channelCode
      ? yield call(ShopApi.getUserCreditCardsByChannel, userId, channelCode)
      : yield call(ShopApi.getUserCreditCards, userId);


    yield put(
      onGetCreditCardsSuccess(data.creditCards ? data.creditCards : data)
    );
    EventEmitter.dispatch(
      "DATA_SUCCESS_getCreditCards",
      data.creditCards ? data.creditCards : data
    );
  } catch (err) {
    yield put(onGetCreditCardsError(err));
    EventEmitter.dispatch("DATA_ERROR_getCreditCards", {});
  }
}

export function* getAllCreditCards(args) {

  if (args.payload.paymentId && args.payload.userId) {

    const userId = args.payload.userId;
    const paymentMethodCode = args.payload.paymentId;

    try {
      const data = yield call(ShopApi.getUserCreditCardsByPaymentMethod, userId, paymentMethodCode);
      yield put(
        onGetAllCreditCardsSuccess(data.creditCards ? data.creditCards : data)
      );
    } catch (err) {
      yield put(onGetAllCreditCardsError(err));
    }

  }
}

export function* removeCreditCard(args) {

  const userId = args.payload.userId;
  const cardId = args.payload.cardId;

  try {
    const data = yield call(ShopApi.deleteUserCreditCard, userId, cardId);
    yield put(onRemoveCreditCardSuccess(data));
    EventEmitter.dispatch("DATA_SUCCESS_removeCreditCard", data);
  } catch (err) {
    yield put(onRemoveCreditCardError(err));
  }
}

// SELECTORS
const getThisCreditCards = state => state.creditCards;
export const makeSelectCreditCards = createSelector(
  [getThisCreditCards],
  creditCards => {
    return JSON.parse(JSON.stringify(creditCards));
  }
);
