import { call, put, takeLatest } from "redux-saga/effects";

import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";
import { environment } from "../../config/environment";

import { ShopApi } from "./api";
import { createSelector } from "reselect";
import { DataStatus } from "src/interfaces/iRedux";


const initialState = fromJS({});

// Constants
const GET_COLLECTIONS = "app/App/GET_COLLECTIONS";
const GET_COLLECTIONS_SUCCESS = "app/App/GET_COLLECTIONS_SUCCESS";
const GET_COLLECTIONS_ERROR = "app/App/GET_COLLECTIONS_ERROR";

// actions
export function onGetCollections(payload) {
  return {
    type: GET_COLLECTIONS,
    payload
  };
}

export function onGetCollectionsSuccess(collections) {
  return {
    type: GET_COLLECTIONS_SUCCESS,
    collections
  };
}

export function onGetCollectionsError(error) {
  return {
    type: GET_COLLECTIONS_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case GET_COLLECTIONS:
      return {...state, ...{status:DataStatus.LOADING}};
    case GET_COLLECTIONS_SUCCESS:
      return {...action.collections, ...{status:DataStatus.OK}};
    case GET_COLLECTIONS_ERROR:
      return {...state,...{status:DataStatus.ERROR}};
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_COLLECTIONS, getCollections);
}

export function* getCollections(args) {
  const channel = args.payload.code;

  const defaultDeliveryArea = environment.DEFAULT_DELIVERY_AREA && environment.DEFAULT_DELIVERY_AREA !== undefined ? environment.DEFAULT_DELIVERY_AREA : '';

  const deliveryAreaCode = args && args.payload && args.payload.deliveryAreaCode && args.payload.deliveryAreaCode !== undefined && args.payload.deliveryAreaCode !== 'undefined' ? args.payload.deliveryAreaCode : defaultDeliveryArea;

  try {
    const data = yield call(ShopApi.getTaxonNavListing, channel, deliveryAreaCode);

    if(data && data.entries && Array.isArray(data.entries) && data.entries.length > 0){
      yield put(onGetCollectionsSuccess(data));
      EventEmitter.dispatch("DATA_SUCCESS_getCollections", data);
    } else {
      yield put(onGetCollectionsError({error:true}));
    }
  } catch (err) {
    yield put(onGetCollectionsError(err));
  }
}

// SELECTORS
const getThisSite = state => state.collections;
export const makeSelectCollections = createSelector(
  [getThisSite],
  collections => {
    return collections;
  }
);
