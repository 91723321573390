import { environment } from "../config/environment";
import { eventType } from './eventTypes';
import { brandType } from './brandTypes';
import { trackingSubscriptionItem, transactionArgs } from './interfaces';

export const eeSubscriptionPurchase = (
    transaction:transactionArgs,
    pageTitle: string,
    pagePath: string,
    product: trackingSubscriptionItem
) => {

  const { APP_FLAVOR } = environment;

  return {
    event:eventType.EESubscriptionPurchase,
    pagePath,
    pageTitle:`${pageTitle} Purchase - ${brandType[APP_FLAVOR] ?? ''}`,
    ecommerce:{
      currencyCode: `${transaction.currency}`,
      purchase:{
        actionField:{
          affiliation: 'subscribe',
          id:`${transaction.id}`,
          revenue:`${(transaction.revenue/100).toFixed(2)}`,
          tax:`${(transaction.tax/100).toFixed(2)}`,
          shipping:`${(transaction.shipping/100).toFixed(2)}`,
          ...transaction.coupon && {coupon:`${(transaction.coupon/100).toFixed(2)}`},
        },
        products:[product]
      }
    }
  }
}
