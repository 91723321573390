import { fromJS } from "immutable";
import { takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";



const initialState = fromJS({});

const GET_PAYPAL_STATUS = "app/App/GET_PAYPAL_STATUS";
const GET_PAYPAL_STATUS_SUCCESS = "app/App/GET_PAYPAL_STATUS_SUCCESS";
const GET_PAYPAL_STATUS_ERROR = "app/App/GET_PAYPAL_STATUS_ERROR";

// actions
export function onGetPayPalStatus(payload) {
    return {
        type: GET_PAYPAL_STATUS,
        payload
    };
}

export function onGetPayPalStatusSuccess(status) {
    return {
        type: GET_PAYPAL_STATUS_SUCCESS,
        status
    };
}

export function onGetPayPalStatusError(error) {
    return {
        type: GET_PAYPAL_STATUS_ERROR,
        error
    };
}


// REDUCERS
export function reducer(state = initialState, action = {}) {
    switch (action.type) {
        case GET_PAYPAL_STATUS:
            return state;
        case GET_PAYPAL_STATUS_SUCCESS:
            return action.status;
        case GET_PAYPAL_STATUS_ERROR:
            return state;
        default:
            return state;
    }
}

// SAGA
export function* saga() {
    yield takeLatest(GET_PAYPAL_STATUS, getPayPalStatus);
}

export function* getPayPalStatus(args) {
    throw Error("PayPalStatus: getPayPalStatus is deprecated");
}

// SELECTORS
const getThis = state => state.paypal_status;
export const makeSelectPayPalStatus = createSelector(
    [getThis],
    paypal_status => {
        return paypal_status;
    }
);
