import { fromJS } from "immutable";
import { call, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { updateObj } from "../../helpers/gen";
import { ShopApi } from "./api";



const initialState = fromJS({});

export const PEACH_PREPARE_CHECKOUT = "app/App/PEACH_PREPARE_CHECKOUT";
export const PEACH_PREPARE_CHECKOUT_SUCCESS = "app/App/PEACH_PREPARE_CHECKOUT_SUCCESS";
export const PEACH_PREPARE_CHECKOUT_ERROR = "app/App/PEACH_PREPARE_CHECKOUT_ERROR";

export const PEACH_PAYMENT_STATUS = "app/App/PEACH_PAYMENT_STATUS";
export const PEACH_PAYMENT_STATUS_SUCCESS = "app/App/PEACH_PAYMENT_STATUS_SUCCESS";
export const PEACH_PAYMENT_STATUS_ERROR = "app/App/PEACH_PAYMENT_STATUS_ERROR";

export const PEACH_PAYMENT_GET_EFT_KEY = "app/App/PEACH_PAYMENT_GET_EFT_KEY";
export const PEACH_PAYMENT_GET_EFT_KEY_SUCCESS = "app/App/PEACH_PAYMENT_GET_EFT_KEY_SUCCESS";
export const PEACH_PAYMENT_GET_EFT_KEY_ERROR = "app/App/PEACH_PAYMENT_GET_EFT_KEY_ERROR";

export const PEACH_PAYMENT_VERIFY_EFT = "app/App/PEACH_PAYMENT_VERIFY_EFT";
export const PEACH_PAYMENT_VERIFY_EFT_SUCCESS = "app/App/PEACH_PAYMENT_VERIFY_EFT_SUCCESS";
export const PEACH_PAYMENT_VERIFY_EFT_ERROR = "app/App/PEACH_PAYMENT_VERIFY_EFT_ERROR";

export const PEACH_PAYMENT_CLEAR_KEYS = "app/App/PEACH_PAYMENT_CLEAR_KEYS";
export const PEACH_PAYMENT_CLEAR_KEYS_SUCCESS = "app/App/PEACH_PAYMENT_CLEAR_KEYS_SUCCESS";
export const PEACH_PAYMENT_CLEAR_KEYS_ERROR = "app/App/PEACH_PAYMENT_CLEAR_KEYS_ERROR";

// actions
export function onPeachPrepareCheckout(payload) {
    return {
        type: PEACH_PREPARE_CHECKOUT,
        payload
    };
}

export function onPeachPrepareCheckoutSuccess(result) {
    return {
        type: PEACH_PREPARE_CHECKOUT_SUCCESS,
        result
    };
}

export function onPeachPrepareCheckoutError(error) {
    return {
        type: PEACH_PREPARE_CHECKOUT_ERROR,
        error
    };
}

export function onPeachPaymentStatus(payload) {
    return {
        type: PEACH_PAYMENT_STATUS,
        payload
    };
}

export function onPeachPaymentStatusSuccess(result) {
    return {
        type: PEACH_PAYMENT_STATUS_SUCCESS,
        result
    };
}

export function onPeachPaymentStatusError(error) {
    return {
        type: PEACH_PAYMENT_STATUS_ERROR,
        error
    };
}

export function onPeachPaymentGetEftKey(payload) {
    return {
        type: PEACH_PAYMENT_GET_EFT_KEY,
        payload
    };
}

export function onPeachPaymentGetEftKeySuccess(result) {
    return {
        type: PEACH_PAYMENT_GET_EFT_KEY_SUCCESS,
        result
    };
}

export function onPeachPaymentGetEftKeyError(error) {
    return {
        type: PEACH_PAYMENT_GET_EFT_KEY_ERROR,
        error
    };
}

export function onPeachPaymentVerifyEft(payload) {
    return {
        type: PEACH_PAYMENT_VERIFY_EFT,
        payload
    };
}

export function onPeachPaymentVerifyEftSuccess(result) {
    return {
        type: PEACH_PAYMENT_VERIFY_EFT_SUCCESS,
        result
    };
}

export function onPeachPaymentVerifyEftError(error) {
    return {
        type: PEACH_PAYMENT_VERIFY_EFT_ERROR,
        error
    };
}

//PEACH_PAYMENT_CLEAR_KEYS
export function onPeachPaymentClearKeys(payload) {
    return {
        type: PEACH_PAYMENT_CLEAR_KEYS,
        payload
    };
}

export function onPeachPaymentClearKeysSuccess(result) {
    return {
        type: PEACH_PAYMENT_CLEAR_KEYS_SUCCESS,
        result
    };
}

export function onPeachPaymentClearKeysError(error) {
    return {
        type: PEACH_PAYMENT_CLEAR_KEYS_ERROR,
        error
    };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
    switch (action.type) {
        // PREPARE CHECKOUT FORM
        case PEACH_PREPARE_CHECKOUT:
            return state;
        case PEACH_PREPARE_CHECKOUT_SUCCESS:
            return updateObj(state, { prepare_checkout: action.result });
        case PEACH_PREPARE_CHECKOUT_ERROR:
            return action.error;

        // GET PAYMENT STATUS
        case PEACH_PAYMENT_STATUS:
            return updateObj(state, { payment_status: null });
        case PEACH_PAYMENT_STATUS_SUCCESS:
            return updateObj(state, { payment_status: action.result.data, purchasedItemCtx: action.result.purchasedItemCtx });
        case PEACH_PAYMENT_STATUS_ERROR:
            return action.error;

        // GET EFT KEY
        case PEACH_PAYMENT_GET_EFT_KEY:
            //return state;
            return updateObj(state, { eft_key: null });
        case PEACH_PAYMENT_GET_EFT_KEY_SUCCESS:
            return updateObj(state, { eft_key: action.result });
        case PEACH_PAYMENT_GET_EFT_KEY_ERROR:
            return state;

        // VERIFY EFT
        case PEACH_PAYMENT_VERIFY_EFT:
            return updateObj(state, { eft_status: null });
        case PEACH_PAYMENT_VERIFY_EFT_SUCCESS:
            return updateObj(state, { eft_status: action.result });
        case PEACH_PAYMENT_VERIFY_EFT_ERROR:
            return state;

        // PEACH_PAYMENT_CLEAR_KEYS
        case PEACH_PAYMENT_CLEAR_KEYS:
            return updateObj(updateObj(updateObj(state, { payment_status: action.result }), { eft_key: null }), { prepare_checkout: null });
        case PEACH_PAYMENT_CLEAR_KEYS_SUCCESS:
            return state;
        case PEACH_PAYMENT_CLEAR_KEYS_ERROR:
            return state;

        default:
            return state;
    }
}

// SAGA
export function* saga() {
    yield takeLatest(PEACH_PREPARE_CHECKOUT, peachPrepareCheckout);
    yield takeLatest(PEACH_PAYMENT_STATUS, peachPaymentStatus);
    yield takeLatest(PEACH_PAYMENT_GET_EFT_KEY, peachPaymentGetEftKey);
    yield takeLatest(PEACH_PAYMENT_VERIFY_EFT, peachPaymentVerifyEft);
    yield takeLatest(PEACH_PAYMENT_CLEAR_KEYS, peachPaymentClearKeys);
}

export function* peachPaymentClearKeys(args) {
    // clear keys
}

export function* peachPrepareCheckout(args) {

    try {

        let body = {};
        let data: any;
        const cartId = args.payload.cartId;

        if (args.payload.cardId) {
            body = {
                cardId: args.payload.cardId
            };
        }

        if (args.payload.subscriptionId && args.payload.userId && args.payload.productId && args.payload.addressId) {

            data = yield call(
                ShopApi.updateSubscriptionPeachIntent,
                args.payload.userId,
                args.payload.subscriptionId,
                args.payload.productId,
                args.payload.addressId
            );

        }else{
            data = yield call(ShopApi.updateCartProcessPayment, cartId, body);
        }

        if (data && data.result && data.result.code && data.result.code === "200.300.404") {
            yield put(onPeachPrepareCheckoutError(data));
        } else {
            yield put(onPeachPrepareCheckoutSuccess(data));
        }

    } catch (err) {
        yield put(onPeachPrepareCheckoutError(err));

    }


}

export function* peachPaymentStatus(args) {

    try {

        let data: any;
        let purchasedItemCtx: any;

        if (args.payload.userId && args.payload.subscriptionId && args.payload.productId) {

            const {subscriptionId, productId} = args.payload
            purchasedItemCtx = { subscriptionId, productId };

            data = yield call(
                ShopApi.updateSubscriptionCheckPeachIntent,
                args.payload.userId,
                args.payload.subscriptionId,
                args.payload.productId
            );

        }else{
            data = yield call(
                ShopApi.updateCartCheckPeachTransaction,
                args.payload.merchantTransactionId
            );
        }

        if (data) {
            yield put(onPeachPaymentStatusSuccess({ data, purchasedItemCtx}));
        }

    } catch (err) {

            yield put(onPeachPaymentStatusError(err));
            console.error('peachPaymentStatus error = ', err);

    }
}

export function* peachPaymentGetEftKey(args) {


    try {
        const data = yield call(ShopApi.updateCartProcessPayment, args.payload.cartId, {});


        if (data && data.result && data.result.key && data.result.key.length > 0) {
            yield put(onPeachPaymentGetEftKeySuccess(data.result));
        } else {
            yield put(onPeachPaymentGetEftKeyError(data));
        }


    } catch (err) {
        yield put(onPeachPaymentGetEftKeyError(err));
    }
}

export function* peachPaymentVerifyEft(args) {


    try {
        const data = yield call(
            ShopApi.updateCartCheckPeachEFTTransaction,
            args.payload.merchantReference
        );
        yield put(onPeachPaymentVerifyEftSuccess(data));

    } catch (err) {
        yield put(onPeachPaymentVerifyEftError(err));
    }
}

// SELECTORS
const getThisSite = state => state.peachPayment;
export const makeSelectPeachPayment = createSelector(
    [getThisSite],
    peachPayment => {
        return peachPayment;
    }
);
