import { fromJS } from "immutable";
import { call, put, throttle } from "redux-saga/effects";
import { createSelector } from "reselect";
import { IAddressesStore } from "src/interfaces/iAddresses";
import { DataStatus } from "src/interfaces/iRedux";
import { ShopApi } from "./api";
import { store } from "../../index"

import { USER_ADDRESS_CREATE, USER_ADDRESS_CREATE_SUCCESS, USER_ADDRESS_CREATE_ERROR, USER_ADDRESS_DELETE, USER_ADDRESS_DELETE_SUCCESS, USER_ADDRESS_DELETE_ERROR } from "./Addresses";

const initialState = fromJS({});

const GET_ALL_USER_ADDRESSES = "app/App/GET_ALL_USER_ADDRESSES";
const GET_ALL_USER_ADDRESSES_SUCCESS = "app/App/GET_ALL_USER_ADDRESSES_SUCCESS";
const GET_ALL_USER_ADDRESSES_ERROR = "app/App/GET_ALL_USER_ADDRESSES_ERROR";

// actions
// GET USER ADDRESSES
export function onGetAllUserAddresses(payload) {
  return {
    type: GET_ALL_USER_ADDRESSES,
    payload,
  };
}

export function onGetAllUserAddressesSuccess(payload) {
  return {
    type: GET_ALL_USER_ADDRESSES_SUCCESS,
    payload,
  };
}

export function onGetAllUserAddressesError(error) {
  return {
    type: GET_ALL_USER_ADDRESSES_ERROR,
    error,
  };
}

const initAllAddresses:any = {
  status:DataStatus.UNINIT,
  query:{userId:0},
  message:'',
  data:[]
}

// REDUCERS
export function reducer(state:IAddressesStore = initAllAddresses, action: any = {}) {
  switch (action.type) {
    // get user addresses
    case GET_ALL_USER_ADDRESSES:
      return {...state,...{
        status:state.status === DataStatus.LOADING ? DataStatus.BUSY : DataStatus.LOADING,
        query:{
          userId:action.payload?.userId
        },
        message:'Loading all user addresses'
      }};
    case GET_ALL_USER_ADDRESSES_SUCCESS:
      return {...state,...{
        status:DataStatus.OK,
        data:action.payload,
        message:''
      }};
    case GET_ALL_USER_ADDRESSES_ERROR:
      return {...state,...{
        status:DataStatus.ERROR,
        data:state.data,
        message:'An error occurred'
      }};
      case USER_ADDRESS_CREATE:
        return state;
      case USER_ADDRESS_CREATE_SUCCESS:
        console.log('USER_ADDRESS_CREATE_SUCCESS', action);
        return {...state, ...{data:[...state.data,action.addresses]}};
      case USER_ADDRESS_CREATE_ERROR:
        return state;
      case USER_ADDRESS_DELETE:
          if (state && state.data && Array.isArray(state.data)) {
            return {...state, ...{data:state.data.filter((item) => item.id !== action.payload.addressId)}}
          }
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield throttle(2000, GET_ALL_USER_ADDRESSES, getAllUserAddresses);
}

// GET USER ADDRESSES
export function* getAllUserAddresses(args) {
  const userId = args.payload.userId;

  const state = store.getState();
  if(state.allAddresses.status === DataStatus.LOADING){
    try {
      const data = yield call(ShopApi.getAllUserAddresses, userId);
      yield put(onGetAllUserAddressesSuccess(data.addresses));
    } catch (err) {
      // error
      yield put(onGetAllUserAddressesError(err));
    }
  }
}

// SELECTORS
const getThis = (state) => state.allAddresses;
export const makeSelectAllAddresses = createSelector([getThis], (allAddresses) => {
  return allAddresses;
});
