import { call, put, takeLatest } from "redux-saga/effects";

import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";

import { ShopApi } from "./api";
import { createSelector } from "reselect";

const initialState = fromJS({});

const GET_SHIPMENTS = "app/App/GET_SHIPMENTS";
const GET_SHIPMENTS_SUCCESS = "app/App/GET_SHIPMENTS_SUCCESS";
const GET_SHIPMENTS_ERROR = "app/App/GET_SHIPMENTS_ERROR";

// actions
export function onGetShipments(payload) {
  return {
    type: GET_SHIPMENTS,
    payload
  };
}

export function onGetShipmentsSuccess(shipments) {
  return {
    type: GET_SHIPMENTS_SUCCESS,
    shipments
  };
}

export function onGetShipmentsError(error) {
  return {
    type: GET_SHIPMENTS_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case GET_SHIPMENTS:
      return state;
    case GET_SHIPMENTS_SUCCESS:
      return action.shipments;
    case GET_SHIPMENTS_ERROR:
      return state;
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_SHIPMENTS, getShipments);
}

export function* getShipments(args) {

  const cartId = args.payload.cartId;
  if(cartId && cartId > 0){
    try {
      const data = yield call(ShopApi.getCartShippingMethods, cartId);
      yield put(onGetShipmentsSuccess(data.shipments));
    } catch (err) {
      yield put(onGetShipmentsError(err));
    }
  }
}

// SELECTORS
const getThisShipments = state => state.shipments;
export const makeSelectShipments = createSelector(
  [getThisShipments],
  shipments => {
    return shipments;
  }
);
