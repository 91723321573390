import { call, put, takeLatest, takeEvery, throttle } from "redux-saga/effects";

import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";

import { ShopApi } from "./api";
import { createSelector } from "reselect";

const initialState = fromJS({});

const GET_USER_ADDRESSES = "app/App/GET_USER_ADDRESSES";
const GET_USER_ADDRESSES_SUCCESS = "app/App/GET_USER_ADDRESSES_SUCCESS";
const GET_USER_ADDRESSES_ERROR = "app/App/GET_USER_ADDRESSES_ERROR";

// user address create
export const USER_ADDRESS_CREATE = "app/App/USER_ADDRESS_CREATE";
export const USER_ADDRESS_CREATE_SUCCESS = "app/App/USER_ADDRESS_CREATE_SUCCESS";
export const USER_ADDRESS_CREATE_ERROR = "app/App/USER_ADDRESS_CREATE_ERROR";

// user address delete
export const USER_ADDRESS_DELETE = "app/App/USER_ADDRESS_DELETE";
export const USER_ADDRESS_DELETE_SUCCESS = "app/App/USER_ADDRESS_DELETE_SUCCESS";
export const USER_ADDRESS_DELETE_ERROR = "app/App/USER_ADDRESS_DELETE_ERROR";

// update user address
export const UPDATE_USER_ADDRESS = "app/App/UPDATE_USER_ADDRESS";
export const UPDATE_USER_ADDRESS_SUCCESS =
  "app/App/UPDATE_USER_ADDRESS_SUCCESS";
export const UPDATE_USER_ADDRESS_ERROR = "app/App/UPDATE_USER_ADDRESS_ERROR";

const USER_LOGOUT = "app/App/USER_LOGOUT";
const USER_VERIFY_ERROR = "app/App/USER_VERIFY_ERROR";
const GET_SITE = "app/App/GET_SITE";

// actions
// GET USER ADDRESSES
export function onGetUserAddresses(payload) {
  return {
    type: GET_USER_ADDRESSES,
    payload,
  };
}

export function onGetUserAddressesSuccess(addresses) {
  return {
    type: GET_USER_ADDRESSES_SUCCESS,
    addresses,
  };
}

export function onGetUserAddressesError(error) {
  return {
    type: GET_USER_ADDRESSES_ERROR,
    error,
  };
}

// USER_ADDRESS_CREATE
export function onUserAddressCreate(payload) {
  return {
    type: USER_ADDRESS_CREATE,
    payload,
  };
}

export function onUserAddressCreateSuccess(addresses) {
  return {
    type: USER_ADDRESS_CREATE_SUCCESS,
    addresses,
  };
}

export function onUserAddressCreateError(error) {
  return {
    type: USER_ADDRESS_CREATE_ERROR,
    error,
  };
}

// UPDATE USER ADDRESS
export function onUpdateUserAddress(payload) {
  return {
    type: UPDATE_USER_ADDRESS,
    payload,
  };
}

export function onUpdateUserAddressSuccess(address) {
  return {
    type: UPDATE_USER_ADDRESS_SUCCESS,
    address,
  };
}

export function onUpdateUserAddressError(error) {
  return {
    type: UPDATE_USER_ADDRESS_ERROR,
    error,
  };
}

// DELETE USER ADDRESS
export function onUserAddressDelete(payload) {
  return {
    type: USER_ADDRESS_DELETE,
    payload,
  };
}

export function onUserAddressDeleteSuccess(address) {
  return {
    type: USER_ADDRESS_DELETE_SUCCESS,
    address,
  };
}

export function onUserAddressDeleteError(error) {
  return {
    type: USER_ADDRESS_DELETE_ERROR,
    error,
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    // get user addresses
    case GET_USER_ADDRESSES:
      return state;
    case GET_USER_ADDRESSES_SUCCESS:
      return action.addresses;
    case GET_USER_ADDRESSES_ERROR:
      return state;

    // user address create
    case USER_ADDRESS_CREATE:
      return state;
    case USER_ADDRESS_CREATE_SUCCESS:
      return [...state, action.addresses];
    case USER_ADDRESS_CREATE_ERROR:
      return state;

    // update user address
    case UPDATE_USER_ADDRESS:
      return state;
    case UPDATE_USER_ADDRESS_SUCCESS:
      if (state && Array.isArray(state)) {
        return [
          ...state.filter((item) => item.id !== action.address.address.id),
          action.address.address,
        ];
      }

    case UPDATE_USER_ADDRESS_ERROR:
      return state;

    // update user address
    case USER_ADDRESS_DELETE:
      if (state && Array.isArray(state)) {
        return state.filter((item) => item.id !== action.payload.addressId);
      }
    case USER_ADDRESS_DELETE_SUCCESS:
      return state;
    case USER_ADDRESS_DELETE_ERROR:
      return state;

    case USER_LOGOUT:
      return {};
    case USER_VERIFY_ERROR:
      return {};
    case GET_SITE:
    // TESTING: do not clear cart
    //xxxxxxxxxxxxx
    //return {};
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield throttle(2000, GET_USER_ADDRESSES, getUserAddresses);
  yield throttle(500, USER_ADDRESS_CREATE, createUserAddress);
  yield takeEvery(UPDATE_USER_ADDRESS, updateUserAddress);
  yield takeLatest(USER_ADDRESS_DELETE, deleteUserAddress);
}

// GET USER ADDRESSES
export function* getUserAddresses(args) {
  const userId = args.payload.userId;

  EventEmitter.dispatch("DATA_getUserAddresses", args);

  try {
    // Call our request helper (see 'utils/request')
    const data = yield call(ShopApi.getUserAddresses, userId);

    EventEmitter.dispatch("DATA_SUCCESS_getUserAddresses", data.addresses);
    yield put(onGetUserAddressesSuccess(data.addresses));
  } catch (err) {
    // error
    yield put(onGetUserAddressesError(err));
  }
}

export function* createUserAddress(args) {
  const {
    firstName,
    lastName,
    company,
    street,
    city,
    suburb,
    postcode,
    countryCode,
    provinceName,
    provinceCode,
    phoneNumber,
    subscription,
    defaultAddress,
    alias,
    deliveryInstruction,
    userId,
  } = args.payload;

  const body = {
    firstName,
    lastName,
    company,
    street,
    city,
    suburb,
    postcode,
    countryCode,
    provinceName,
    provinceCode,
    phoneNumber,
    subscription,
    defaultAddress,
    alias,
    deliveryInstruction,
  };

  try {
    if (street.indexOf("abylonstoren") > -1) { // Is this correct?
      yield put(onUserAddressCreateError({}));
    } else {
      const data = yield call(ShopApi.createUserAddress, userId, body);
      yield put(onUserAddressCreateSuccess(data.address));
    }
  } catch (err) {
    // error
    setTimeout(()=>{
      EventEmitter.dispatch("DATA_ERROR_onUserAddressCreateError", err);
    },100);
    yield put(onUserAddressCreateError(err));
  }
}

// UPDATE USER ADDRESS
export function* updateUserAddress(args) {

  const addressId = args.payload.addressId;
  const userId = args.payload.userId;
  const body = {
    firstName: args.payload.firstName,
    lastName: args.payload.lastName,
    phoneNumber: args.payload.phoneNumber,
    company: args.payload.company,
    street: args.payload.street,
    countryCode: args.payload.countryCode,
    city: args.payload.city,
    suburb: args.payload.suburb,
    postcode: args.payload.postcode,
    provinceName: args.payload.provinceName,
    provinceCode: args.payload.provinceCode,
    subscription: args.payload.subscription,
    defaultAddress: args.payload.defaultAddress,
    alias: args.payload.alias,
    deliveryInstruction: args.payload.deliveryInstruction,
  };

  EventEmitter.dispatch("DATA_updateUserAddress");

  try {
    const data = yield call(ShopApi.updateUserAddress, userId, addressId, body);
    EventEmitter.dispatch("DATA_SUCCESS_updateUserAddress");
    yield put(onUpdateUserAddressSuccess(data));
  } catch (err) {
    // error
    yield put(onUpdateUserAddressError(err));
  }
}

// DELETE USER ADDRESS
export function* deleteUserAddress(args) {

  const userId = args.payload.userId;
  const addressId = args.payload.addressId;

  EventEmitter.dispatch("DATA_deleteUserAddress");

  try {
    const data = yield call(ShopApi.deleteUserAddress, userId, addressId);
    EventEmitter.dispatch("DATA_SUCCESS_deleteUserAddress");
    yield put(onUserAddressDeleteSuccess(data));
  } catch (err) {
    // error
    yield put(onUserAddressDeleteError(err));
  }
}

// SELECTORS
const getThis = (state) => state.addresses;
export const makeSelectAddresses = createSelector([getThis], (addresses) => {
  return addresses;
});
