import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { EventEmitter } from "../../helpers/eventEmitter";
import { environment } from "../../config/environment";
import { mergeById, stripTrailingSlash } from "../../helpers/gen";
import { Tokenize } from "../../helpers/bm25";
import { ShopApi } from "./api";

import { IAction } from "../../interfaces/iAction";
import { IProduct } from "../../interfaces/iProduct";
import { getThisSiteChannel } from "./Site";

const GET_ALL_PRODUCTS: string = "app/App/GET_ALL_PRODUCTS";
const GET_ALL_PRODUCTS_SUCCESS: string = "app/App/GET_ALL_PRODUCTS_SUCCESS";
const GET_ALL_PRODUCTS_ERROR: string = "app/App/GET_ALL_PRODUCTS_ERROR";

const CLEAR_PRODUCTS: string = "app/App/CLEAR_PRODUCTS";

// actions

export function onGetAllProducts(payload: any): IAction {
  return {
    type: GET_ALL_PRODUCTS,
    payload
  };
}

export function onGetAllProductsSuccess(payload: any): IAction {
  return {
    type: GET_ALL_PRODUCTS_SUCCESS,
    payload
  };
}

export function onGetAllProductsError(payload: any): IAction {
  return {
    type: GET_ALL_PRODUCTS_ERROR,
    payload
  };
}


// REDUCERS

export function reducer(state: {data:IProduct[]} = {data:[]}, action: IAction = { type: "", payload: {} }): {data:any[]} {

  switch (action.type) {
    case GET_ALL_PRODUCTS:
      return {data:[]};
    case GET_ALL_PRODUCTS_SUCCESS:
      const all_existing_data = state;
      const all_data = all_existing_data
        ? mergeById(action.payload || [], all_existing_data)
        : action.payload;
      return {
        data:all_data && Array.isArray(all_data)
        ? all_data.sort((a, b) => (a.id > b.id ? 1 : -1))
        : []};
    case GET_ALL_PRODUCTS_ERROR:
      return state;
    case CLEAR_PRODUCTS:
      return {data:[]};
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_ALL_PRODUCTS, getAllProducts);
}

// GET ALL PRODUCTS
export function* getAllProducts(args: any) {
  const { channel, deliveryAreaCode } = args.payload;

  try {
    // Call our request helper (see 'utils/request')
    //const data = yield call(ShopApi.getAllProducts, channel, deliveryAreaCode);

    const products = [];
    const productsMin = products.map((item: any) => {

      let terms: any = [];
      Object.keys(item.translations).map(s => {
        const pTerms = Tokenize(`${item.translations[s].name} ${item.translations[s].shortDescription}`);
        terms = [...terms, ...pTerms];
        if (item.slug !== "august-2021-box") {
          //item.translations[s].description = "";
        }
      });
      // add search terms =======

      item.terms = terms;

      //item.associations = {};
      delete item.associations;
      //item.attributes = [];
      delete item.attributes;
      //delete item.taxons.main.translations;
      item.taxons.others = item.taxons.others.map((tax: any) => {
        delete tax.translations;
        return tax;
      });

      return item;
    });

    yield put(onGetAllProductsSuccess(productsMin));
  } catch (err) {
    // error
    yield put(onGetAllProductsError(err));
  }
}

// SELECTORS

const getThisAllProducts = (state: any) => state.allProducts;
export const makeSelectProducts = createSelector(
  [getThisAllProducts],
  allProducts => {
    return allProducts;
  }
);
export const makeSelectAllProductsInChannel = createSelector(
  [getThisSiteChannel, getThisAllProducts],
  (channelCode, allProducts) => {

    return Array.isArray(allProducts)
        ? allProducts.filter(item => {
          if (item && item.channels && Array.isArray(item.channels)) {
            for (const channel of item.channels) {
              if(channelCode === channel.code) return true;
            }
          }
          return false;
        })
        : allProducts;
  }
);
