import { call, put, takeLatest } from "redux-saga/effects";
import { createSelector } from "reselect";
import { fromJS } from "immutable";
import { ShopApi } from "./api";

const initialState = fromJS({});

// get single subscriptions
const GET_SUBSCRIPTIONS = "app/Subscriptions/GET_SUBSCRIPTIONS";
const GET_SUBSCRIPTIONS_SUCCESS = "app/Subscriptions/GET_SUBSCRIPTIONS_SUCCESS";
const GET_SUBSCRIPTIONS_ERROR = "app/Subscriptions/GET_SUBSCRIPTIONS_ERROR";

const UPDATE_SUBSCRIPTIONS = "app/Subscriptions/UPDATE_SUBSCRIPTIONS";
const UPDATE_SUBSCRIPTIONS_SUCCESS = "app/Subscriptions/UPDATE_SUBSCRIPTIONS_SUCCESS";
const UPDATE_SUBSCRIPTIONS_ERROR = "app/Subscriptions/UPDATE_SUBSCRIPTIONS_ERROR";

const GET_SITE = "app/App/GET_SITE";

// actions
export function onGetSubscriptions(payload) {
  return {
    type: GET_SUBSCRIPTIONS,
    payload
  };
}

export function onGetSubscriptionsSuccess(payload) {
  return {
    type: GET_SUBSCRIPTIONS_SUCCESS,
    payload
  };
}

export function onGetSubscriptionsError(error) {
  return {
    type: GET_SUBSCRIPTIONS_ERROR,
    error
  };
}

export function onUpdateSubscriptions(payload) {
  return {
    type: UPDATE_SUBSCRIPTIONS,
    payload
  };
}

export function onUpdateSubscriptionsSuccess(payload) {
  return {
    type: UPDATE_SUBSCRIPTIONS_SUCCESS,
    payload
  };
}

export function onUpdateSubscriptionsError(error) {
  return {
    type: UPDATE_SUBSCRIPTIONS_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case GET_SUBSCRIPTIONS:
      return state;
    case GET_SUBSCRIPTIONS_SUCCESS:
      return action.payload;
    case GET_SUBSCRIPTIONS_ERROR:
      return { notFound: true };
    case UPDATE_SUBSCRIPTIONS:
      return state;
    case UPDATE_SUBSCRIPTIONS_SUCCESS:
      const subscriptions = state.map(item => {
        if (item.id === action.payload.subscriptionId) {
          let newItem = item;
          newItem.updated = Date.now();
          return newItem;
        } else {
          return item;
        }
      });
      return subscriptions;
    case UPDATE_SUBSCRIPTIONS_ERROR:
      return state;
    // default
    case GET_SITE:
      return state;
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_SUBSCRIPTIONS, getSubscriptions);
  yield takeLatest(UPDATE_SUBSCRIPTIONS, updateSubscriptions);
}

// LIST PRODUCTS

export function* getSubscriptions(args) {

  const channel = args.payload.channelCode && args.payload.channelCode !== undefined
                  ? args.payload.channelCode
                  : undefined;

  try {

    if (channel === undefined) {
      throw Error("Error loading subscriptions args.payload.channelCode not specified");
    }

    const data = yield call(ShopApi.getSubscriptions, channel);
    if (data.subscriptions) {
      yield put(onGetSubscriptionsSuccess(data.subscriptions));
    } else {
      yield put(onGetSubscriptionsError("error"));
    }
  } catch (err) {
    console.error(err);
    yield put(onGetSubscriptionsError(err));
  }
}

export function* updateSubscriptions(args) {

  try {
    const data = yield call(
      ShopApi.updateSubscriptionType,
        args.payload.userId,
        args.payload.subscriptionId,
        args.payload.type
      );

    if (data.success && data.success === true) {
      yield put(onUpdateSubscriptionsSuccess(args.payload));
    } else {
      yield put(onUpdateSubscriptionsError("error"));
    }
  } catch (err) {
    // error
    yield put(onUpdateSubscriptionsError(err));
  }
}

// SELECTORS
const getThisSubscriptions = state => state.subscriptions;
export const makeSelectSubscriptions = createSelector(
  [getThisSubscriptions],
  subscriptions => {
    return subscriptions;
  }
);
