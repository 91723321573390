import { breakpoints } from "../../../helpers/breakpoints";

const imgWidth = `${Math.ceil(window.innerWidth / 200) * 200}`;

export const videoSplash = "https://res.cloudinary.com/babylon/image/upload/v1592035624/newt-shop/calendarvideo.jpg";
export const ukHomeHighlights = "https://res.cloudinary.com/babylon/image/upload/v1592035624/vignamaggio-shop/gift-sets.jpg";
export const saHomeHighlights = "https://res.cloudinary.com/babylon/image/upload/v1592035624/vignamaggio-shop/gift-sets.jpg";

export const featuredVignamaggio = "https://res.cloudinary.com/babylon/image/upload/v1592035624/vignamaggio-shop/featured-products-vignamaggio.jpg";

export const SiteLogoVignamaggio = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_150,q_auto:good,c_lfill/v1601643544/vignamaggio-shop/logo_g2fcau.png`;
export const euHomeBanner = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints(1200)},q_auto:good,c_lfill/v1596204784/vignamaggio-shop/homePage/vignamaggio_banner_zugc2c.jpg`;
export const intHomeBanner = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${breakpoints(1200)},q_auto:good,c_lfill/v1596204784/vignamaggio-shop/homePage/vignamaggio_banner_zugc2c.jpg`;

export const FacebookIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832757/bt-shop/svg/facebook_x0dt6h.svg`;
export const InstagramIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832754/bt-shop/svg/instagram_roy6kq.svg`;
export const TwitterIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832762/bt-shop/svg/twitter_cefpwk.svg`;
export const YoutubeIcn = `https://res.cloudinary.com/babylon/image/upload/v1588832761/bt-shop/svg/youtube_o95pqk.svg`;

export const notFoundBt = `https://res.cloudinary.com/babylon/image/upload/f_auto,w_${imgWidth},q_auto:good,c_lfill/v1588834548/bt-shop/homePage/error-page_kcvo2h.jpg`;
