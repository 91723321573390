import { call, put, takeEvery } from "redux-saga/effects";
import { createSelector } from "reselect";
import { DataStatus } from "src/interfaces/iRedux";
import { stripTrailingSlash } from "../../helpers/gen";
import { IAction } from "../../interfaces/iAction";
import { IProduct } from "../../interfaces/iProduct";
import { ShopApi } from "./api";
import { SET_DELIVERY_AREA, SET_DELIVERY_AREA_SUCCESS } from "./DeliveryArea";
import { getThisSiteChannel } from "./Site";
import { environment } from "../../config/environment";



export interface IProducts {
  status:DataStatus;
  data:IProduct[];
  message?:string;
  action?:any;
}

const LIST_PRODUCTS: string = "app/App/LIST_PRODUCTS";
const LIST_PRODUCTS_SUCCESS: string = "app/App/LIST_PRODUCTS_SUCCESS";
const LIST_PRODUCTS_ERROR: string = "app/App/LIST_PRODUCTS_ERROR";

const CLEAR_PRODUCTS: string = "app/App/CLEAR_PRODUCTS";
const CLEAR_PRODUCTS_SUCCESS: string = "app/App/CLEAR_PRODUCTS_SUCCESS";
const CLEAR_PRODUCTS_ERROR: string = "app/App/CLEAR_PRODUCTS_ERROR";

const GET_SITE: string = "app/App/GET_SITE";

// actions

export function onListProducts(payload: any): IAction {
  return {
    type: LIST_PRODUCTS,
    payload
  };
}

export function onListProductsSuccess(payload: any): IAction {
  return {
    type: LIST_PRODUCTS_SUCCESS,
    payload
  };
}

export function onListProductsError(payload: any): IAction {
  return {
    type: LIST_PRODUCTS_ERROR,
    payload
  };
}

export function onClearProducts(payload: any): IAction {
  return {
    type: CLEAR_PRODUCTS,
    payload
  };
}

export function onClearProductsSuccess(payload: any): IAction {
  return {
    type: CLEAR_PRODUCTS_SUCCESS,
    payload
  };
}

export function onClearProductsError(payload: any): IAction {
  return {
    type: CLEAR_PRODUCTS_ERROR,
    payload
  };
}

// REDUCERS

export function reducer(state: IProducts = {status:DataStatus.UNINIT,data:[]}, action: IAction = { type: "", payload: {} }): IProducts {

  switch (action.type) {
    case LIST_PRODUCTS:
      return {...state,...{
        status:DataStatus.LOADING,
        data: state.data,
        message:'Loading Products',
        action:action
      }}

    case LIST_PRODUCTS_SUCCESS:
      return {...state,...{
        status:DataStatus.OK,
        data: action.payload,
        message:'Products Loaded'
      }}

    case LIST_PRODUCTS_ERROR:
      return {...state, ...{
        status:DataStatus.ERROR,
        data: [],
        message:'Something went wrong',
      }}

    case CLEAR_PRODUCTS:
      return {
        status:DataStatus.UNINIT,
        data: [],
        message:'Products Cleared'
      }

    case SET_DELIVERY_AREA:
      return state;

    case SET_DELIVERY_AREA_SUCCESS:
      return {...state,...{
        deliveryArea:action.payload
      }};

    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeEvery(LIST_PRODUCTS, getProducts);
}

// LIST PRODUCTS
export function* getProducts(args: any) {
  let { collection, tags, channelCode, deliveryArea } = args.payload;

  const defaultDeliveryArea = environment.DEFAULT_DELIVERY_AREA && environment.DEFAULT_DELIVERY_AREA !== undefined ? environment.DEFAULT_DELIVERY_AREA : '';

  if(deliveryArea === undefined || deliveryArea === 'undefined'){
    deliveryArea = {data:{code:defaultDeliveryArea}};
  }

  const slug = stripTrailingSlash(
    tags && tags.length > 0 ? tags[0] : collection || "categories/wine"
  );

  try {
    // Call our request helper (see 'utils/request')
    const data = yield call(ShopApi.getProductsByTaxonSlug, slug, channelCode, deliveryArea.data.code);

    const products = data.products && Array.isArray(data.products) ? data.products : [];

    yield put(onListProductsSuccess(products));

  } catch (err) {
    // error
    yield put(onListProductsSuccess([]));
  }
}

// SELECTORS

const getThisProducts = (state: any) => state.products;
export const makeSelectProducts = createSelector(
  [getThisProducts],
  products => {
    return products;
  }
);

export const makeSelectProductsInChannel = createSelector(
  [getThisSiteChannel, getThisProducts],
  (channelCode, products) => {

    return Array.isArray(products)
        ? products.filter(item => {
          if (item && item.channels && Array.isArray(item.channels)) {
            for (const channel of item.channels) {
              if(channelCode === channel.code) return true;
            }
          }
          return false;
        })
        : products;
  }
);
