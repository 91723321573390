export const breakpoints = (minWidth = 600) => {
    const w = window.innerWidth;

    const iW = (Math.ceil((window.innerWidth * 2) / 1000)) * 1000;

    return iW < 2000 ? 2000 : iW;

    if (w < minWidth) {
        return minWidth;
    }


    if (w > 1634) {
        return 1700;
    } else if (w > 1024) {
        return 1700;
    } else if (w > 769) {
        return 1024;
    } else if (w > 600) {
        return 769;
    } else if (w > 320) {
        return 600;
    } else {
        return 600;
    }
}

export const videoBreakpoints = (minWidth = 600) => {
    const size = breakpoints(minWidth);
    return Math.floor(size / 3)
}