import { call, put, takeLatest } from "redux-saga/effects";

import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";
import { ShopApi } from "./api";

import { createSelector } from "reselect";

const initialState = fromJS({});

const GET_ORDERS = "app/App/GET_ORDERS";
const GET_ORDERS_SUCCESS = "app/App/GET_ORDERS_SUCCESS";
const GET_ORDERS_ERROR = "app/App/GET_ORDERS_ERROR";

const USER_LOGOUT = "app/App/USER_LOGOUT";
const USER_VERIFY_ERROR = "app/App/USER_VERIFY_ERROR";

// actions
export function onGetOrders(payload) {
  return {
    type: GET_ORDERS,
    payload
  };
}

export function onGetOrdersSuccess(orders) {
  return {
    type: GET_ORDERS_SUCCESS,
    orders
  };
}

export function onGetOrdersError(error) {
  return {
    type: GET_ORDERS_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case GET_ORDERS:
      return state;
    case GET_ORDERS_SUCCESS:
      return action.orders;
    case GET_ORDERS_ERROR:
      return state;
    case USER_LOGOUT:
      return [];
    case USER_VERIFY_ERROR:
      return [];
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_ORDERS, getOrders);
}

export function* getOrders(args) {

  const userId = args.payload.userId;

  EventEmitter.dispatch("DATA_getOrders");

  try {
    const data = yield call(ShopApi.getUserOrderHistory, userId);
    yield put(onGetOrdersSuccess(data));
    EventEmitter.dispatch("DATA_SUCCESS_getOrders");
  } catch (err) {
    yield put(onGetOrdersError(err));
    EventEmitter.dispatch("DATA_ERROR_getOrders");
  }
}

// SELECTORS
const getThis = state => state.orders;
export const makeSelectOrders = createSelector(
  [getThis],
  orders => {
    return JSON.parse(JSON.stringify(orders));
  }
);
