import { ApiErrorResponse } from "./ApiCall";

export const ERROR_MESSAGES = {
  'error': 'Something seems to have gone wrong!',
  'invalid_credentials': "Invalid email address and password combination.",
  'not_found': "Invalid email address and password combination.",
  'invalid_argument': "Invalid request",
  'request_invalid': "Invalid request",
  'product_unavailable': "Product is unavailable",
  'checkin_token_invalid': "Checkin token is invalid",
  'ticket_usage_exceeded': "Membership usage exceeded",
  'ticket_expired': "Ticket Expired",
  'taxon_not_found': "Category not found",
  'channel_not_found': "Channel not found",
  'customer_not_found': "Customer not found",
  'entity_not_found': "Resource not found",
  // status code errors
  '400': '[400] Invalid request',
  '404': '[404] Resource not found',
};

function extractFromStatus(status: number){

  if(ERROR_MESSAGES.hasOwnProperty(String(status))){
    return ERROR_MESSAGES[String(status)];
  }

  return ERROR_MESSAGES['error'];
}

function extractFromUnknown(object: any){

  if(object instanceof ApiErrorResponse){
    return extractFromObject(object.responseBody);
  }

  if(object instanceof Error){
    return ERROR_MESSAGES['error'];
  }

  if(typeof object === 'object'){
    return extractFromObject(object);
  }

  return ERROR_MESSAGES['error'];
}

function extractFromObject(object: any){

  if(object.hasOwnProperty('message') && typeof object.message === 'string' && object.message.length > 0){
    return object.message;
  }

  if(object.hasOwnProperty('msg') && typeof object.msg === 'string' && object.msg.length > 0){
    return object.msg;
  }

  if(object.hasOwnProperty('error') && typeof object.error === 'string' && ERROR_MESSAGES.hasOwnProperty(object.error)){
    return ERROR_MESSAGES[object.error];
  }

  if(object.hasOwnProperty('status')
    && typeof object.status === 'number'
    && object.status >= 400
    && object.status < 500){
    return extractFromStatus(object.status);
  }

  return ERROR_MESSAGES['error'];

}

export const ApiErrorTranslator = {
  extractMessage: extractFromUnknown
}