import { call, put, takeLatest } from "redux-saga/effects";
import { fromJS } from "immutable";
import { EventEmitter } from "../../helpers/eventEmitter";
import { createSelector } from "reselect";

const initialState = fromJS({});

const GET_AUTH = "app/App/GET_AUTH";
const GET_AUTH_SUCCESS = "app/App/GET_AUTH_SUCCESS";
const GET_AUTH_ERROR = "app/App/GET_AUTH_ERROR";

const REFRESH_AUTH = "app/App/REFRESH_AUTH";
const REFRESH_AUTH_SUCCESS = "app/App/REFRESH_AUTH_SUCCESS";
const REFRESH_AUTH_ERROR = "app/App/REFRESH_AUTH_ERROR";

// actions
export function onGetAuth(payload) {
  return {
    type: GET_AUTH,
    payload
  };
}

export function onGetAuthSuccess(auth) {
  return {
    type: GET_AUTH_SUCCESS,
    auth
  };
}

export function onGetAuthError(error) {
  return {
    type: GET_AUTH_ERROR,
    error
  };
}

export function onRefreshAuth(payload) {
  return {
    type: REFRESH_AUTH,
    payload
  };
}

export function onRefreshAuthSuccess(auth) {
  return {
    type: REFRESH_AUTH_SUCCESS,
    auth
  };
}

export function onRefreshAuthError(error) {
  return {
    type: REFRESH_AUTH_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case GET_AUTH:
      return state;
    case GET_AUTH_SUCCESS:
      return action.auth;
    case GET_AUTH_ERROR:
      return state;
    case REFRESH_AUTH:
      return state;
    case REFRESH_AUTH_SUCCESS:
      return action.auth;
    case REFRESH_AUTH_ERROR:
      return state;
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield takeLatest(GET_AUTH, getAuth);
  yield takeLatest(REFRESH_AUTH, refreshAuth);
}

const getDummyAuth = async () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        access_token: "dummy_access_token",
        expires_in: 3600,
        refresh_token: "dummy_refresh_token",
        scope: null,
        token_type: "bearer"
      });
    }, 500);
  });
};

export function* getAuth(args) {

  try {
    const data = yield call(getDummyAuth);
    yield put(onGetAuthSuccess(data));
    EventEmitter.dispatch("DATA_SUCCESS_getAuth", data);

  } catch (err) {
    yield put(onGetAuthError(err));
  }

}

export function* refreshAuth(args) {

  try {
    const data = yield call(getDummyAuth);
    yield put(onGetAuthSuccess(data));
    EventEmitter.dispatch("DATA_SUCCESS_getAuth", data);
  } catch (err) {
    yield put(onGetAuthError(err));
  }

}

// SELECTORS
const getThis = state => state.auth;
export const makeSelectAuth = createSelector(
  [getThis],
  auth => {
    return auth;
  }
);
