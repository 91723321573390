import { put, takeLatest } from "redux-saga/effects";

import { createSelector } from "reselect";

import { IAction } from "../../interfaces/iAction";
import { IChannel } from "../../interfaces/iSite";

const SET_ROUTE: string = "app/App/SET_ROUTE";
const SET_ROUTE_SUCCESS: string = "app/App/SET_ROUTE_SUCCESS";
const SET_ROUTE_ERROR: string = "app/App/SET_ROUTE_ERROR";

// actions
export function onSetRoute(payload: any): IAction {
  return {
    type: SET_ROUTE,
    payload
  };
}

export function onSetRouteSuccess(payload: any): IAction {
  return {
    type: SET_ROUTE_SUCCESS,
    payload
  };
}

export function onSetRouteError(payload: any): IAction {
  return {
    type: SET_ROUTE_ERROR,
    payload
  };
}

// REDUCERS

export function reducer(state: {}, action: IAction = { type: "", payload: {} }): any {
  switch (action.type) {
    case SET_ROUTE:
      return state;
    case SET_ROUTE_SUCCESS:
      return action.payload;
    case SET_ROUTE_ERROR:
      return state;
    default:
      return {};
  }
}

// SAGA
export function* saga() {
  yield takeLatest(SET_ROUTE, setRoute);
}

export function* setRoute(args: any) {
  try {
    yield put(onSetRouteSuccess(args));
  } catch (err) {
    yield put(onSetRouteError(err));
  }

}

// SELECTORS
const getThis = (state: any) => state.route;
export const makeSelectRoute = createSelector(
  [getThis],
  route => {
    return route;
  }
);
