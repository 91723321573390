
export interface ICandideVerifyTicketPayload {
  customerId: number;
  checkinToken: string;
}

export enum CandideTicketVerifyStatus {
  ERROR = 'error',
  INVALID = 'invalid',
  EXPIRED = 'expired',
  LOADING = 'loading',
  PENDING = 'pending',
  OK = 'ok',
  USAGE_EXCEEDED = 'exceeded',
}

export interface ICandideAccountActivatePayload {
  customerId: number;
  activationCode: string;
}

export enum CandideAccountActivateStatus {
  MEMBERSHIP_ERROR = 'membership_error',
  INVALID_MEMBERSHIP_TOKEN = 'invalid_membership_token',
  INVALID_REQUEST = 'invalid_request',
  MEMBERSIP_ALREADY_ASSIGNED = 'membership_already_assigned',
  LOADING = 'loading',
  PENDING = 'pending',
  OK = 'ok',
  TOKEN_CLAIMED = 'membership_token_already_claimed',
}

export interface IMembershipActivationResult {
  id: number;
  code: string;
  name: string;
  expiresAt: string;
  startsAt: string;
  isValid: boolean;
}