import { eventType } from './eventTypes';


export const eeCheckoutGift = () => {
  return {
    event:eventType.EECheckoutGift,
    ecommerce: {
      checkout_option: {
        actionField: {
          step: 2,
          option: 'Gift'
        }
      }
    }
  }
}
