import { environment } from "../config/environment";
import { IProductItemView } from "src/interfaces/iProduct";
import { eventType } from './eventTypes';
import { brandType } from './brandTypes';
import { findTaxonAtDeepestLevel } from "./helpers";

export const eeRemoveFromBasket = (product:IProductItemView, quantity:number = 1) => {

  const { APP_FLAVOR } = environment;
  const productTaxon = findTaxonAtDeepestLevel(product.taxonCodes);

  const productObj = {
      name:`${product.name}`,
      id: `${product.id}`,
      price: `${(product.price/100).toFixed(2)}`,
      brand: brandType[APP_FLAVOR] ?? '',
      category: productTaxon.slug.replace('categories',''),
      variant: `${product.name}`,
      quantity: quantity
    };

  return {
    event:eventType.EERemoveFromBasket,
    ecommerce:{
      remove:{
        products:[productObj]
      }
    }
  }
}
