import { ISiteView } from "src/interfaces/iSite";
import { IChannel, ICountry, ILocale } from "src/interfaces/iSite";
import { getSelectedLanguage } from "src/helpers/languageHelper";
import { SITE } from "src/config/sites";
import { lsx as ls } from './localStorage';

interface SITE_VIEW_ARGS {
  channels:IChannel[],
  countries:ICountry[],
  locale:ILocale,
  selectedChannelCode?:string
}

export const findChannelCodeInUrl = (channels:any):string => {

  const channelCodes = ['int', 'uk', 'za', 'eu', 'us'];
  const pname = window.location.pathname;
  const pnameList = pname.split('/').filter((item:string) => item && item.length > 0);
  const possibleChannel = pnameList[0];
  if(channels && Array.isArray(channels)){
    channels.forEach((item:IChannel) => {
      if(item.code === possibleChannel){
        return item.code;
      }
    })
  }

  if(pnameList && Array.isArray(pnameList) && pnameList.length > 0 && channelCodes.indexOf(pnameList[0]) > -1){
    return pnameList[0];
  }

  return null;
}

export const makeSiteView = (args:SITE_VIEW_ARGS):ISiteView | null => {

  const { channels, countries, locale, selectedChannelCode } = args;

  const countryCode = locale?.countryCode;
  const currentCountry = countries && Array.isArray(countries) && countries.filter((country:ICountry) => country.code === countryCode);

  const channelCode = findChannelCodeInUrl(channels) && selectedChannelCode === undefined ? findChannelCodeInUrl(channels) : selectedChannelCode && selectedChannelCode.length > 0 ? selectedChannelCode : currentCountry && Array.isArray(currentCountry) && currentCountry[0] && currentCountry[0].channelCode ? currentCountry[0].channelCode : 'int';

  if(channelCode !== null){
    ls.set("modal_channel", channelCode);
  }

  const countryChannel = channelCode && channels && channels && Array.isArray(channels) ? channels.filter((channel:IChannel) => channel.code === channelCode) : null;

  const currentCountryCode = countries.filter((item:any) => item.channelCode === channelCode);

  if(countryChannel && Array.isArray(countryChannel) && countryChannel[0]){
    return {
      id: countryChannel[0].id,
      code: countryChannel[0].code,
      name: countryChannel[0].name,
      hostName: countryChannel[0].hostName,
      currency: countryChannel[0].currency,
      currencySymbol: countryChannel[0].currencySymbol,
      freshShopEnabled: countryChannel[0].freshShopEnabled,
      locales:countryChannel[0].locales,
      defaultLocale:countryChannel[0].defaultLocale,
      countries,
      channels:channels,
      locale,
      language:getSelectedLanguage({site:countryChannel[0]}),
      config:SITE(countryChannel[0].code),
      channelCountry:`${currentCountryCode && Array.isArray(currentCountryCode) && currentCountryCode.length === 1 && currentCountryCode[0] &&currentCountryCode[0].code ? currentCountryCode[0].code : ''}`
    }
  } else {
    return null;
  }

}