import { call, put, throttle } from "redux-saga/effects";

import { fromJS } from "immutable";
import { createSelector } from "reselect";

const initialState = fromJS({});

const UPDATE_CHECKOUT = "app/App/UPDATE_CHECKOUT";
const UPDATE_CHECKOUT_SUCCESS = "app/App/UPDATE_CHECKOUT_SUCCESS";
const UPDATE_CHECKOUT_ERROR = "app/App/UPDATE_CHECKOUT_ERROR";

// clear cart
const CLEAR_CART = "app/App/CLEAR_CART";
const CREATE_CART_SUCCESS = "app/App/CREATE_CART_SUCCESS";

// actions
export function onUpdateCheckout(payload) {
  return {
    type: UPDATE_CHECKOUT,
    payload
  };
}

export function onUpdateCheckoutSuccess(checkout) {
  return {
    type: UPDATE_CHECKOUT_SUCCESS,
    checkout
  };
}

export function onUpdateCheckoutError(error) {
  return {
    type: UPDATE_CHECKOUT_ERROR,
    error
  };
}

// REDUCERS
export function reducer(state = initialState, action: any = {}) {
  switch (action.type) {
    case UPDATE_CHECKOUT:
      return state;
    case UPDATE_CHECKOUT_SUCCESS:
      return {...action.checkout};
    case UPDATE_CHECKOUT_ERROR:
      return state;
    // clear checkout on clear cart
    case CLEAR_CART:
      return state;
    case CREATE_CART_SUCCESS:
      return {};
    default:
      return state;
  }
}

// SAGA
export function* saga() {
  yield throttle(100, UPDATE_CHECKOUT, updateCheckout);
}

const updateCheckoutObj = args => {
  return new Promise((resolve, reject) => {
    if (args.payload) {
      // success
      resolve(args.payload);
    } else {
      // error
      reject("could not update");
    }
  });
};

export function* updateCheckout(args) {
  try {
    const data = yield call(updateCheckoutObj, args);
    yield put(onUpdateCheckoutSuccess(data));
  } catch (err) {
    yield put(onUpdateCheckoutError(err));
  }
}

// SELECTORS
const getThisSite = state => state.checkout;
export const makeSelectCheckout = createSelector(
  [getThisSite],
  checkout => {
    return checkout;
  }
);
