import {
  IUser,
  IUserLoginResponse,
} from "./types";

export enum UserActionType {
  // user login
  USER_LOGIN = "app/App/USER_LOGIN",
  USER_LOGIN_SUCCESS = "app/App/USER_LOGIN_SUCCESS",
  USER_LOGIN_ERROR = "app/App/USER_LOGIN_ERROR",

  USER_HYDRATE_INIT = "app/App/USER_HYDRATE_INIT",
  USER_HYDRATE_SUCCESS = "app/App/USER_HYDRATE_SUCCESS",

  // user campaign login
  USER_CAMPAIGN_LOGIN = "app/App/USER_CAMPAIGN_LOGIN",
  USER_CAMPAIGN_LOGIN_SUCCESS = "app/App/USER_CAMPAIGN_LOGIN_SUCCESS",
  USER_CAMPAIGN_LOGIN_ERROR = "app/App/USER_CAMPAIGN_LOGIN_ERROR",

  USER_CAMPAIGN_VERIFY = "app/App/USER_CAMPAIGN_VERIFY",
  USER_CAMPAIGN_VERIFY_SUCCESS = "app/App/USER_CAMPAIGN_VERIFY_SUCCESS",
  USER_CAMPAIGN_VERIFY_ERROR = "app/App/USER_CAMPAIGN_VERIFY_ERROR",

  // user logout
  USER_LOGOUT = "app/App/USER_LOGOUT",
  USER_LOGOUT_SUCCESS = "app/App/USER_LOGOUT_SUCCESS",
  USER_LOGOUT_ERROR = "app/App/USER_LOGOUT_ERROR",

  // user register
  USER_REGISTER = "app/App/USER_REGISTER",
  USER_REGISTER_SUCCESS = "app/App/USER_REGISTER_SUCCESS",
  USER_REGISTER_ERROR = "app/App/USER_REGISTER_ERROR",

  // user verify
  USER_VERIFY = "app/App/USER_VERIFY",
  USER_VERIFY_SUCCESS = "app/App/USER_VERIFY_SUCCESS",
  USER_VERIFY_ERROR = "app/App/USER_VERIFY_ERROR",
  
  // set save user credit cards
  USER_SET_SAVE_CARDS = "app/App/USER_SET_SAVE_CARDS",
  USER_SET_SAVE_CARDS_SUCCESS = "app/App/USER_SET_SAVE_CARDS_SUCCESS",
  USER_SET_SAVE_CARDS_ERROR = "app/App/USER_SET_SAVE_CARDS_ERROR",

  // user update
  USER_UPDATE = "app/App/USER_UPDATE",
  USER_UPDATE_SUCCESS = "app/App/USER_UPDATE_SUCCESS",
  USER_UPDATE_ERROR = "app/App/USER_UPDATE_ERROR",

  // update password
  UPDATE_PASSWORD = "app/App/UPDATE_PASSWORD",
  UPDATE_PASSWORD_SUCCESS = "app/App/UPDATE_PASSWORD_SUCCESS",
  UPDATE_PASSWORD_ERROR = "app/App/UPDATE_PASSWORD_ERROR",


  // password forgot
  PASSWORD_FORGOT = "app/App/PASSWORD_FORGOT",
  PASSWORD_FORGOT_SUCCESS = "app/App/PASSWORD_FORGOT_SUCCESS",
  PASSWORD_FORGOT_ERROR = "app/App/PASSWORD_FORGOT_ERROR",

  // password forgot
  PASSWORD_RESET = "app/App/PASSWORD_RESET",
  PASSWORD_RESET_SUCCESS = "app/App/PASSWORD_RESET_SUCCESS",
  PASSWORD_RESET_ERROR = "app/App/PASSWORD_RESET_ERROR",

  // newsletter signup
  NEWSLETTER_SIGNUP = "app/App/NEWSLETTER_SIGNUP",
  NEWSLETTER_SIGNUP_SUCCESS = "app/App/NEWSLETTER_SIGNUP_SUCCESS",
  NEWSLETTER_SIGNUP_ERROR = "app/App/NEWSLETTER_SIGNUP_ERROR",

  // cart updates
  // the user.cartId needs to be updated when the cart gets updated
  CREATE_CART_SUCCESS = "app/App/CREATE_CART_SUCCESS",
  UPDATE_CART_SUCCESS = "app/App/UPDATE_CART_SUCCESS",

  // clear cart
  CLEAR_CART = "app/App/CLEAR_CART",
  CLEAR_CART_SUCCESS = "app/App/CLEAR_CART_SUCCESS",
  CLEAR_CART_ERROR = "app/App/CLEAR_CART_ERROR",

  //wine club
  GET_WINECLUB_STATUS = "app/App/GET_WINECLUB_STATUS",
  GET_WINECLUB_STATUS_SUCCESS = "app/App/GET_WINECLUB_STATUS_SUCCESS",
  GET_WINECLUB_STATUS_ERROR = "app/App/GET_WINECLUB_STATUS_ERROR",

  // get cart
  HAS_SUBSCRIPTION_CART = "app/App/HAS_SUBSCRIPTION_CART",
  HAS_SUBSCRIPTION_CART_SUCCESS = "app/App/HAS_SUBSCRIPTION_CART_SUCCESS",
  HAS_SUBSCRIPTION_CART_ERROR = "app/App/HAS_SUBSCRIPTION_CART_ERROR",

  SUBSCRIPTION_SIGNUP = "app/App/SUBSCRIPTION_SIGNUP",
  SUBSCRIPTION_SIGNUP_SUCCESS = "app/App/SUBSCRIPTION_SIGNUP_SUCCESS",
  SUBSCRIPTION_SIGNUP_ERROR = "app/App/SUBSCRIPTION_SIGNUP_ERROR",

  SUBSCRIPTION_REMOVE = "app/App/SUBSCRIPTION_REMOVE",
  SUBSCRIPTION_REMOVE_SUCCESS = "app/App/SUBSCRIPTION_REMOVE_SUCCESS",
  SUBSCRIPTION_REMOVE_ERROR = "app/App/SUBSCRIPTION_REMOVE_ERROR",

  SUBSCRIPTION_REMOVE_STATE = "app/App/SUBSCRIPTION_REMOVE_STATE",
  SUBSCRIPTION_REMOVE_STATE_SUCCESS = "app/App/SUBSCRIPTION_REMOVE_STATE_SUCCESS",
  SUBSCRIPTION_REMOVE_STATE_ERROR = "app/App/SUBSCRIPTION_REMOVE_STATE_ERROR",

  SUBSCRIPTION_PAUSE = "app/App/SUBSCRIPTION_PAUSE",
  SUBSCRIPTION_PAUSE_SUCCESS = "app/App/SUBSCRIPTION_PAUSE_SUCCESS",
  SUBSCRIPTION_PAUSE_ERROR = "app/App/SUBSCRIPTION_PAUSE_ERROR",

  CHANGE_LANGUAGE = "app/App/CHANGE_LANGUAGE",
  CHANGE_LANGUAGE_SUCCESS = "app/App/CHANGE_LANGUAGE_SUCCESS",
  CHANGE_LANGUAGE_ERROR = "app/App/CHANGE_LANGUAGE_ERROR",

  UPDATE_PREFERRED_LANGUAGE = "app/App/UPDATE_PREFERRED_LANGUAGE",
  UPDATE_PREFERRED_LANGUAGE_SUCCESS = "app/App/UPDATE_PREFERRED_LANGUAGE_SUCCESS",
  UPDATE_PREFERRED_LANGUAGE_ERROR = "app/App/UPDATE_PREFERRED_LANGUAGE_ERROR",

  UPDATE_REFERRAL = "app/App/UPDATE_REFERRAL",
  UPDATE_REFERRAL_SUCCESS = "app/App/UPDATE_REFERRAL_SUCCESS",
  UPDATE_REFERRAL_ERROR = "app/App/UPDATE_REFERRAL_ERROR",

  NOOP = "app/App/NOOP",
}

// actions
// USER LOGIN
export function onUserLogin(payload: IUserLoginResponse) {
  return {
    type: UserActionType.USER_LOGIN,
    payload,
  };
}

export function onUserLoginSuccess(user: IUser) {
  return {
    type: UserActionType.USER_LOGIN_SUCCESS,
    user,
  };
}

export function onUserHydrateSuccess(user: IUser) {
  return {
    type: UserActionType.USER_LOGIN_SUCCESS,
    user,
  };
}

export function onUserLoginError(error: any) {
  return {
    type: UserActionType.USER_LOGIN_ERROR,
    error,
  };
}

// USER CAMPAIGN LOGIN
export function onUserCampaignLogin(payload: any) {
  return {
    type: UserActionType.USER_CAMPAIGN_LOGIN,
    payload,
  };
}

export function onUserCampaignLoginSuccess(user: any) {
  return {
    type: UserActionType.USER_CAMPAIGN_LOGIN_SUCCESS,
    user,
  };
}

export function onUserCampaignLoginError(error: any) {
  return {
    type: UserActionType.USER_CAMPAIGN_LOGIN_ERROR,
    error,
  };
}

// USER LOGOUT
export function onUserLogout() {
  return {
    type: UserActionType.USER_LOGOUT,
  };
}

export function onUserLogoutSuccess() {
  return {
    type: UserActionType.USER_LOGOUT_SUCCESS,
  };
}

export function onUserLogoutError(error: any) {
  return {
    type: UserActionType.USER_LOGOUT_ERROR,
    error,
  };
}

// USER REGISTER
export function onUserRegister(payload: any) {
  return {
    type: UserActionType.USER_REGISTER,
    payload,
  };
}

export function onUserRegisterSuccess(user: any) {
  return {
    type: UserActionType.USER_REGISTER_SUCCESS,
    user,
  };
}

export function onUserRegisterError(error: any) {
  return {
    type: UserActionType.USER_REGISTER_ERROR,
    error,
  };
}

// USER VERIFY
export function onUserVerify(payload: any) {
  return {
    type: UserActionType.USER_VERIFY,
    payload,
  };
}

export function onUserVerifySuccess(payload: any) {
  return {
    type: UserActionType.USER_VERIFY_SUCCESS,
    payload,
  };
}

export function onUserVerifyError(error: any) {
  return {
    type: UserActionType.USER_VERIFY_ERROR,
    error,
  };
}

// USER CAMPAIGN VERIFY
export function onUserCampaignVerify(payload: any) {
  return {
    type: UserActionType.USER_CAMPAIGN_VERIFY,
    payload,
  };
}

export function onUserCampaignVerifySuccess(verify: any) {
  return {
    type: UserActionType.USER_CAMPAIGN_VERIFY_SUCCESS,
    verify,
  };
}

export function onUserCampaignVerifyError(error: any) {
  return {
    type: UserActionType.USER_CAMPAIGN_VERIFY_ERROR,
    error,
  };
}

// USER UPDATE
export function onUserUpdate(payload: any) {
  return {
    type: UserActionType.USER_UPDATE,
    payload,
  };
}

export function onUserUpdateSuccess(user: any) {
  return {
    type: UserActionType.USER_UPDATE_SUCCESS,
    user,
  };
}

export function onUserUpdateError(error: any) {
  return {
    type: UserActionType.USER_UPDATE_ERROR,
    error,
  };
}

// UPDATE PASSWORD
export function onUpdatePassword(payload: any) {
  return {
    type: UserActionType.UPDATE_PASSWORD,
    payload,
  };
}

export function onUpdatePasswordSuccess(user: any) {
  return {
    type: UserActionType.UPDATE_PASSWORD_SUCCESS,
    user,
  };
}

export function onUpdatePasswordError(error: any) {
  return {
    type: UserActionType.UPDATE_PASSWORD_ERROR,
    error,
  };
}

// PASSWORD FORGOT
export function onPasswordForgot(payload: any) {
  return {
    type: UserActionType.PASSWORD_FORGOT,
    payload,
  };
}

export function onPasswordForgotSuccess(user: any) {
  return {
    type: UserActionType.PASSWORD_FORGOT_SUCCESS,
    user,
  };
}

export function onPasswordForgotError(error: any) {
  return {
    type: UserActionType.PASSWORD_FORGOT_ERROR,
    error,
  };
}

// PASSWORD RESET
export function onPasswordReset(payload: any) {
  return {
    type: UserActionType.PASSWORD_RESET,
    payload,
  };
}

export function onPasswordResetSuccess(user: any) {
  return {
    type: UserActionType.PASSWORD_RESET_SUCCESS,
    user,
  };
}

export function onPasswordResetError(error: any) {
  return {
    type: UserActionType.PASSWORD_RESET_ERROR,
    error,
  };
}

// NEWSLETTER_SIGNUP
export function onNewsletterSignup(payload: any) {
  return {
    type: UserActionType.NEWSLETTER_SIGNUP,
    payload,
  };
}

export function onNewsletterSignupSuccess(user: any) {
  return {
    type: UserActionType.NEWSLETTER_SIGNUP_SUCCESS,
    user,
  };
}

export function onNewsletterSignupError(error: any) {
  return {
    type: UserActionType.NEWSLETTER_SIGNUP_ERROR,
    error,
  };
}

// HAS SUBSCRIPTION CART
export function onHasSubscriptionCart(payload: any) {
  return {
    type: UserActionType.HAS_SUBSCRIPTION_CART,
    payload,
  };
}

export function onHasSubscriptionCartSuccess(cart: any) {
  return {
    type: UserActionType.HAS_SUBSCRIPTION_CART_SUCCESS,
    cart,
  };
}

export function onHasSubscriptionCartError(error: any) {
  return {
    type: UserActionType.HAS_SUBSCRIPTION_CART_ERROR,
    error,
  };
}

// SUBSCRIPTION SIGNUP
export function onSubscriptionSignup(payload: any) {
  return {
    type: UserActionType.SUBSCRIPTION_SIGNUP,
    payload,
  };
}

export function onSubscriptionSignupSuccess(cart: any) {
  return {
    type: UserActionType.SUBSCRIPTION_SIGNUP_SUCCESS,
    cart,
  };
}

export function onSubscriptionSignupError(error: any) {
  return {
    type: UserActionType.SUBSCRIPTION_SIGNUP_ERROR,
    error,
  };
}

// SUBSCRIPTION REMOVE
export function onSubscriptionRemove(payload: any) {
  return {
    type: UserActionType.SUBSCRIPTION_REMOVE,
    payload,
  };
}

export function onSubscriptionRemoveSuccess(payload: any) {
  return {
    type: UserActionType.SUBSCRIPTION_REMOVE_SUCCESS,
    payload,
  };
}

export function onSubscriptionRemoveError(error: any) {
  return {
    type: UserActionType.SUBSCRIPTION_REMOVE_ERROR,
    error,
  };
}

// SUBSCRIPTION REMOVE STATE
export function onSubscriptionRemoveState(payload: any) {
  return {
    type: UserActionType.SUBSCRIPTION_REMOVE_STATE,
    payload,
  };
}

export function onSubscriptionRemoveStateSuccess(payload: any) {
  return {
    type: UserActionType.SUBSCRIPTION_REMOVE_STATE_SUCCESS,
    payload,
  };
}

export function onSubscriptionRemoveStateError(error: any) {
  return {
    type: UserActionType.SUBSCRIPTION_REMOVE_STATE_ERROR,
    error,
  };
}

// SUBSCRIPTION PAUSE
export function onSubscriptionPause(payload: any) {
  return {
    type: UserActionType.SUBSCRIPTION_PAUSE,
    payload,
  };
}

export function onSubscriptionPauseSuccess(payload: any) {
  return {
    type: UserActionType.SUBSCRIPTION_PAUSE_SUCCESS,
    payload,
  };
}

export function onSubscriptionPauseError(error: any) {
  return {
    type: UserActionType.SUBSCRIPTION_PAUSE_ERROR,
    error,
  };
}

// CHANGE LANGUAGE
export function onChangeLanguage(payload: any) {
  return {
    type: UserActionType.CHANGE_LANGUAGE,
    payload,
  };
}

export function onChangeLanguageSuccess(payload: any) {
  return {
    type: UserActionType.CHANGE_LANGUAGE_SUCCESS,
    payload,
  };
}

export function onChangeLanguageError(error: any) {
  return {
    type: UserActionType.CHANGE_LANGUAGE_ERROR,
    error,
  };
}

// UPDATE REFERRAL
export function onUpdateReferral(payload: any) {
  return {
    type: UserActionType.UPDATE_REFERRAL,
    payload,
  };
}

export function onUpdateReferralSuccess(payload: any) {
  return {
    type: UserActionType.UPDATE_REFERRAL_SUCCESS,
    payload,
  };
}

export function onUpdateReferralError(error: any) {
  return {
    type: UserActionType.UPDATE_REFERRAL_ERROR,
    error,
  };
}

// UPDATE PREFERRED LANGUAGE
export function onUpdatePreferredLanguage(payload: any) {
  return {
    type: UserActionType.UPDATE_PREFERRED_LANGUAGE,
    payload,
  };
}

export function onUpdatePreferredLanguageSuccess(payload: any) {
  return {
    type: UserActionType.UPDATE_PREFERRED_LANGUAGE_SUCCESS,
    payload,
  };
}

export function onUpdatePreferredLanguageError(error: any) {
  return {
    type: UserActionType.UPDATE_PREFERRED_LANGUAGE_ERROR,
    error,
  };
}

export function onSetSaveCards(payload: {userId:Number, saveCardForFutureUse:Boolean}) {
  return {
    type: UserActionType.USER_SET_SAVE_CARDS,
    payload,
  };
}

export function onSetSaveCardsSuccess(payload: any) {
  return {
    type: UserActionType.USER_SET_SAVE_CARDS_SUCCESS,
    payload,
  };
}

export function onSetSaveCardsError(error: any) {
  return {
    type: UserActionType.USER_SET_SAVE_CARDS_ERROR,
    error,
  };
}


