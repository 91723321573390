/* eslint-disable */
import React from "react";
import { IFlavorConfigFactory } from "../flavorConfig";
import {
  FacebookIcn,
  InstagramIcn,
  intHomeHighlights,
  INT_Banner,
  notFoundBt,
  SiteLogo,
  TwitterIcn,
  ukHomeHighlights,
  videoSplash,
  visit_garden,
  visit_hotel,
  visit_restaurants,
  visit_spa,
  YoutubeIcn,
} from "./babylonstoren.assets";

export const BabylonstorenINTConfig: IFlavorConfigFactory = () => {
  const siteCode = "za";

  return {
    siteClass: "BABYLONSTOREN",
    fonts: [],
    imgNotFound: notFoundBt,
    features:{
      deliveryAreas:false
    },
    emails: {
      deliveries: `deliveries@babylonstoren.com`,
      orders: "elsa@babylonstoren.com",
      orders2: "",
    },
    personalShopper: {
      name: "US",
      email: "deliveries@babylonstoren.com",
    },
    hubspot: {
      newsletterSignup: {
        id: "9bec6816-1a63-4943-86ba-931a5275ddef",
      },
    },
    Header: {
      siteName: [<span key="shop-title-span">SHOP </span>, `BABYLONSTOREN`],
      siteTitle: "Shop Babylonstoren",
      phoneNumber: "+27 (0) 21 300 3919",
      homeLinkLabel: "VISIT BABYLONSTOREN",
      homeLinkUrl: "http://www.babylonstoren.com/",
      title: "Babylonstoren",
      bannerLinkColor: "#333",
    },
    Footer: {
      copyright: "Babylonstoren",
      logo: SiteLogo,
      socialMedia: [
        {
          network: "facebook",
          url: "https://www.facebook.com/Babylonstoren",
          icn: FacebookIcn,
        },
        {
          network: "twitter",
          url: "https://twitter.com/babylonstoren",
          icn: TwitterIcn,
        },
        {
          network: "instagram",
          url: "https://instagram.com/babylonstoren",
          icn: InstagramIcn,
        },
        {
          network: "youtube",
          url: "https://www.youtube.com/channel/UCntUzv42vmt7P9Xmcf5a_-w",
          icn: YoutubeIcn,
        },
      ],
      footerLinks: [
        {
          label: "Customer Care",
          url: "/customer-care",
          sites: [],
        },
        {
          label: "Exchange and Returns",
          url: "/exchange-and-returns",
          sites: [],
        },
        {
          label: "Delivery",
          url: "/delivery",
          sites: [],
        },
        {
          label: "ABOUT BABYLONSTOREN",
          url: "https://www.babylonstoren.com/",
          sites: [],
        },
      ],
    },
    NewsletterSignup: {
      ctaTitle: "Looking for something special?",
      ctaText:
        "Let us help you find that one-of-a-kind gift. Email us for assistance with selecting the right product or placing your order.",
      ctaLink: "mailto:elsa@babylonstoren.com?subject=Personal Shopping",
      ctaLinkLabel: "EMAIL US",
    },
    HomePage: {
      videoSplash: videoSplash,
      homeHighlights: ukHomeHighlights,
      banner: {
        img: INT_Banner,
        videoId_: "lAEAPkfkAao",
        overlay: false,
        whiteOverlay: false,
        text: (
          <div className="bottomLeftZa">
            <h1>
              <span>AND WE'RE BACK</span>
              <span>(AGAIN)</span>
              <span></span>
              <span className="btn">SHOP NOW</span>
            </h1>
          </div>
        ),
        link: `/${siteCode}/categories/wine`,
        eventName: "wine-aug-2020-int",
      },
      video_: {
        splash: videoSplash,
        id: "QjfVfFot2Xs",
        title: "BABYLONSTOREN 2019 CALENDAR",
        subTitle: "Recipes, wrapping paper & decor",
      },
      sectionFeatured: {
        title: "Tea party essentials",
        subTitle: "Babylonstoren",
        img: intHomeHighlights,
        productsSlug: "promote/int-featured",
        link: `/${siteCode}/categories/pantry#tea`,
      },
      sectionBestSellers: {
        title: "BESTSELLERS",
        subTitle: "THIS SEASON’S",
        productsSlug: "promote/int-home-main-featured",
      },
      sectionWhileYouAreHere: {
        title: "MORE FROM OUR FARM",
        subTitle: "STAY A LITTLE LONGER AND DISCOVER",
        sections: [
          {
            title: "Visit our Spa",
            link: "https://www.babylonstoren.com/spa",
            img: visit_spa,
          },
          {
            title: "Eat at our Restaurants",
            link: "https://www.babylonstoren.com/babel",
            img: visit_restaurants,
          },
          {
            title: "Stay at our Hotel",
            link: "https://www.babylonstoren.com/hotel",
            img: visit_hotel,
          },
          {
            title: "Visit our Garden",
            link: "https://www.babylonstoren.com/visit-our-garden",
            img: visit_garden,
          },
        ],
      },
    },
    Checkout: {
      needAssistance: (
        <li className="needAssistance_config">
          <p>
            Prices are shown in <strong>South African Rand</strong>
          </p>
          <p>
            Shipping costs are based on the size of your order as well as the
            shipping destination.
          </p>
          <p>Products are sent from South Africa with DHL express.</p>
          <p>
            Upon order completion we will send you a final quote for shipping
            and to organize payment.
          </p>
        </li>
      ),
    },
  };
};
